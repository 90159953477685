import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunk } from '@reduxjs/toolkit';
import CepService from '../services/CepService';
import { RootState } from '../store';

//interface of equipments
import { ICepStates } from '../Interfaces/ICepData';

const initialState: ICepStates = {
  cepData: null,
  ceperror: false,
  cepsuccess: false,
  ceploading: false,
  cepmessage: '',
};

//get address data by CEP
export const consultaCEP = createAsyncThunk(
  'cep/getPageFromMap',
  async (cep: string, thunkAPI) => {
    const res = await CepService.consultaCEP(cep);
    //check for errors

    if (res.error) {
      return thunkAPI.rejectWithValue(res.error);
    }

    return res;
  },
);

export const CepSlice = createSlice({
  name: 'locations',
  initialState,
  reducers: {
    resetCep: (state) => {
      state.ceperror = false;
      state.ceploading = false;
      state.cepsuccess = false;
      state.cepmessage = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(consultaCEP.fulfilled, (state, action) => {
        state.ceperror = false;
        state.ceploading = false;
        state.cepsuccess = true;
        state.cepData = action.payload;
      })
      .addCase(consultaCEP.pending, (state) => {
        state.ceperror = false;
        state.ceploading = true;
        state.cepsuccess = false;
      })
      .addCase(consultaCEP.rejected, (state, action) => {
        state.ceperror = true;
        state.ceploading = false;
        state.cepsuccess = false;
        state.cepData = null;
        state.cepmessage =
          typeof action.payload === 'string' ? action.payload : '';
      });
  },
});

export const { resetCep } = CepSlice.actions;
export const cepSelector = (state: RootState) => state.CepReducer;
export default CepSlice.reducer;
