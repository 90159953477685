import { useState, FormEvent } from 'react';
import { Link } from 'react-router-dom';

import useAppDispatch from '../../hooks/useAppDispatch';
import useAppSelector from '../../hooks/useAppSelector';
import { login, loginSelector } from '../../slices/LoginSlice';
import Input from '../../presentational/input/Input';

const Login = () => {
  const dispatch = useAppDispatch();

  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [rememberMe, setRememberMe] = useState<boolean>(false);

  const { loginLoading, loginError, loginMessage } =
    useAppSelector(loginSelector);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const userData = {
      email,
      password,
      rememberMe,
    };
    dispatch(login(userData));
  };

  return (
    <div className="flex flex-col">
      <div>
        <h2 className="text-xl font-bold tracking-tight text-gray-900 sm:text-2xl">
          Já possui acesso?
        </h2>
        <h3 className="text-lg font-bold tracking-tight text-gray-900 sm:text-lg justify-start">
          <span>Faça seu login</span>
        </h3>
      </div>
      <div>
        <form onSubmit={handleSubmit}>
          <div>
            <Input
              name="email"
              value={email}
              type="email"
              title="E-mail"
              placeholder="E-mail"
              onChange={setEmail}
            />
          </div>

          <div className="mt-4" data-te-input-wrapper-init>
            <Input
              name="password"
              value={password}
              type="password"
              title="Senha"
              placeholder="Senha"
              autoComplete="current-password"
              onChange={setPassword}
            />
          </div>

          <div className="mt-4 flex items-center justify-between ">
            <div className="flex items-center">
              <input
                type="checkbox"
                id="rememberMe"
                checked={rememberMe}
                onChange={() => setRememberMe(!rememberMe)}
                className="customInputCheckbox"
              />
              <label
                htmlFor="rememberMe"
                className="ml-1 block text-smp font-normal leading-6 top-digital-content-color"
              >
                Lembrar dados
              </label>
            </div>
            <Link
              to="/forgot"
              className="underline block text-smp font-normal leading-6 top-digital-content-color"
            >
              Esqueceu a senha?
            </Link>
          </div>

          <div className="mt-5 text-center lg:text-left">
            <button
              type="submit"
              className={`customButton w-[150px] font-semibold text-sm ${loginLoading ? 'disabled:opacity-75' : ''}}`}
            >
              {loginLoading ? 'Aguarde...' : 'Acessar'}
            </button>
          </div>
        </form>
        <div className="block text-smp font-semibold leading-6 text-blue-500 h-auto break-words">
          {loginError && loginMessage === 'fistel' && (
            <>
              Aguarde mais alguns instantes. Os seus dados ainda estão sendo
              processados. De qualquer forma avisaremos por email quando estiver
              tudo pronto.
            </>
          )}
        </div>
        <div className="block text-smp font-semibold leading-6 text-red-500 h-auto break-words">
          {loginError && loginMessage !== 'fistel' && (
            <>E-mail ou senha incorretos. Tente novamente.</>
          )}
        </div>
        <div className="mt-6 block text-smp font-normal leading-6 top-digital-content-color h-5">
          <p>
            Ainda não possui o Agiliza?
            <Link className="ml-1 underline" to="/register">
              Cadastre-se
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Login;
