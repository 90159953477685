import { X, FileCog } from 'lucide-react';
import { Fragment, useRef, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import DocumentsReportDocs from '../Document/ReportDocuments/DocumentsReportDocs';
import { TDocumentSmall } from '../../Interfaces/IDocument';
import useAppDispatch from '../../hooks/useAppDispatch';
import { downloadDoc } from '../../slices/DocumentSlice';

type Props = {
  fileName: string;
  stationId: string;
  systemId: string;
  docTypeId: string;
  updateTable: boolean;
  hasExpirationDate: boolean;
  rowId: string;
  documentId: string;
  docExtension: string;
  setUpdateTable: React.Dispatch<React.SetStateAction<boolean>>;
};

const AttachDocument = ({
  fileName,
  stationId,
  systemId,
  docTypeId,
  hasExpirationDate,
  updateTable,
  rowId,
  documentId,
  docExtension,
  setUpdateTable,
}: Props) => {
  const [modalStationId, setModalStationId] = useState<string>('');
  const [modalSystemId, setModalSystemId] = useState<string>('');
  const [modalDocTypeId, setModalDocTypeId] = useState<string>('');
  const [isDownloading, setIsDownloading] = useState(false);
  const inputId: string = `upload-${rowId}`;

  const cancelButtonRef = useRef(null);

  const dispatchDocument = useAppDispatch();

  const setCloseDocumentModal = () => {
    setModalStationId('');
    setModalSystemId('');
    setModalDocTypeId('');
    setUpdateTable(!updateTable);
  };

  const submitDownload = (e: React.MouseEvent<HTMLInputElement>) => {
    e.preventDefault();
    const doc: TDocumentSmall = {
      _id: documentId,
      title: fileName,
      extension: docExtension,
    };
    setIsDownloading(true);
    dispatchDocument(downloadDoc(doc)).then((r) => {
      setIsDownloading(false);
    });
  };

  return (
    <div className={`flex ${fileName === '' && 'items-center'}`}>
      <div
        style={{ display: 'flex', alignItems: 'center', gap: '10px' }}
        key={`${rowId}`}
      >
        <div className="relative group cursor-pointer">
          <FileCog
            key={`${rowId}`}
            className="h-4 w-4 cursor-pointer"
            onClick={() => {
              setModalStationId(stationId);
              setModalSystemId(systemId);
              setModalDocTypeId(docTypeId);
            }}
          />
          <span className="absolute bottom-full mb-2 hidden w-auto p-2 text-sm text-white bg-top-digital-primary-dark rounded-md group-hover:block">
            Gerenciar Documentos
          </span>
        </div>

        {fileName !== '' && (
          <div className="relative group cursor-pointer">
            <button
              className="underline"
              style={{ color: 'blue' }}
              onClick={(e) => {
                e.preventDefault();
                const inputElement = document.getElementById(inputId);
                inputElement && inputElement.click();
              }}
            >
              {fileName}
            </button>

            <span className="absolute bottom-full mb-2 hidden w-auto p-2 text-sm text-white bg-top-digital-primary-dark rounded-md group-hover:block">
              Fazer Download do Documento
            </span>
            <input
              type="hidden"
              id={inputId.toString()}
              onClick={(e) => submitDownload(e)}
            />
            <div
              className="loader"
              style={{ width: '16px', height: '16px', borderWidth: '4px' }}
              hidden={!isDownloading}
            ></div>
          </div>
        )}
      </div>

      {stationId === modalStationId &&
        systemId === modalSystemId &&
        docTypeId === modalDocTypeId && (
          <Transition.Root
            show={
              stationId === modalStationId &&
              systemId === modalSystemId &&
              docTypeId === modalDocTypeId
            }
            as={Fragment}
          >
            <Dialog
              as="div"
              className="relative z-10"
              initialFocus={cancelButtonRef}
              onClose={() => setCloseDocumentModal()}
            >
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>

              <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  >
                    <Dialog.Panel className="relative sm:w-full sm:max-w-screen-xl transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:p-6 h-[800px]">
                      <div
                        className="flex justify-end gap-2 cursor-pointer"
                        onClick={() => {
                          setCloseDocumentModal();
                        }}
                        ref={cancelButtonRef}
                      >
                        <X className="h-6 w-6" />
                      </div>
                      {stationId === modalStationId &&
                        systemId === modalSystemId &&
                        docTypeId === modalDocTypeId && (
                          <DocumentsReportDocs
                            key={`${rowId}`}
                            stationId={modalStationId}
                            systemId={modalSystemId}
                            docTypeId={modalDocTypeId}
                            hasExpirationDate={hasExpirationDate}
                            rowId={rowId}
                          />
                        )}
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition.Root>
        )}
    </div>
  );
};

export default AttachDocument;
