import { configureStore } from '@reduxjs/toolkit';
import LoginReducer from './slices/LoginSlice';
import RegisterReducer from './slices/RegisterSlice';
import ProfileReducer from './slices/ProfileSlice';
import LocationReducer from './slices/LocationSlice';
import ConfigSystemReducer from './slices/ConfigSystemSlice';
import ConfigStationReducer from './slices/ConfigStationSlice';
import DocumentReducer from './slices/DocumentSlice';
import ProjectReducer from './slices/ProjectSlice';
import DocTypeReducer from './slices/DocumentTypeSlice';
import StationReducer from './slices/StationSlice';
import SystemReducer from './slices/SystemSlice';
import LinkSystemDocReducer from './slices/LinkSystemDocSlice';
import EditableFieldStationReducer from './slices/EditableFieldStationSlice';
import CepReducer from './slices/CepSlice';
import AdminReducer from './slices/AdminSlice';
//import logger from "redux-logger";
import DashBoardReducer from './slices/DashBoardSlice';
import BoletoReducer from './slices/BoletoSlice';
import NotificationReducer from './slices/NotificationSlice';
import ForgotPasswordReducer from './slices/ForgotPasswordSlice';
import ChangePasswordReducer from './slices/ChangePasswordSlice';
import FistelReducer from './slices/FistelSlice';
import CNPJReducer from './slices/CNPJSlice';
import LicensingReducer from './slices/LicensingSlice';
import DocumentReportReducer from './slices/ReportDocumentSlice';
import UnsubscribeReducer from './slices/UnsubscribeSlice';
import NetworkFilesReducer from './slices/NetworkFilesSlice'; // Adicionado
import UserPreferenceReducer from './slices/UserPreferenceSlice'; // Adicionado

export const store = configureStore({
  reducer: {
    LoginReducer,
    ForgotPasswordReducer,
    UnsubscribeReducer,
    RegisterReducer,
    ProfileReducer,
    LocationReducer,
    ConfigSystemReducer,
    ConfigStationReducer,
    DocumentReducer,
    ProjectReducer,
    DocTypeReducer,
    StationReducer,
    SystemReducer,
    LinkSystemDocReducer,
    EditableFieldStationReducer,
    CepReducer,
    AdminReducer,
    DashBoardReducer,
    BoletoReducer,
    NotificationReducer,
    ChangePasswordReducer,
    FistelReducer,
    LicensingReducer,
    CNPJReducer,
    DocumentReportReducer,
    NetworkFilesReducer, // Adicionado
    UserPreferenceReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export default store;
