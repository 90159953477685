type Props = {
  hasExpirationDate: boolean;
  expirationDate: Date | null;
};

const DocumentExpirationDate = ({
  hasExpirationDate,
  expirationDate,
}: Props) => {
  const formattedDate = expirationDate
    ? new Date(expirationDate).toLocaleDateString('pt-BR')
    : null;

  return (
    <>
      {hasExpirationDate && expirationDate !== null ? (
        <span>{formattedDate}</span>
      ) : (
        <span>-</span>
      )}
    </>
  );
};

export default DocumentExpirationDate;
