import React from 'react';
import { TConfigStation } from '../../Interfaces/IConfigStation';
import { TConfigSystem } from '../../Interfaces/IConfigSystem';
import FilterValueDisplay from './FilterValueDisplay';

type Props = {
  config: TConfigStation | TConfigSystem;
};

const FilterView = ({ config }: Props) => {
  if (
    !config.filterable ||
    config.campo === 'CodTipoAntena' ||
    config.campo === 'Polarizacao'
  ) {
    return <div className="text-xs">Filtro não habilitado</div>;
  }

  if (config.filtervalue === undefined || config.filtervalue.length === 0) {
    return <div className="text-xs font-semibold">Filtro não definido</div>;
  }
  switch (config.filtertype) {
    case 'selecao': {
      return <FilterValueDisplay config={config} />;
    }
    default:
      return null;
  }
};

export default FilterView;
