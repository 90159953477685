import React, { useEffect } from 'react';
//context global
import { useGlobalContext } from '../../hooks/useGlobalContext';

//redux
import useAppDispatch from '../../hooks/useAppDispatch';
import useAppSelector from '../../hooks/useAppSelector';
import {
  systemSelector,
  setLabelSystem,
  resetSystemSlice,
} from '../../slices/SystemSlice';

type Props = {};

const EditSystemLabel = (props: Props) => {
  //context
  const { globalState, dispatchGlobalState } = useGlobalContext();

  //redux
  const dispatch = useAppDispatch();
  const { success: successSystem, system } = useAppSelector(systemSelector);

  /*Ini Formulário de Label do Sistema*/

  const submitHandleLabelSystem = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const data: any = {
      labelSystem: globalState.labelSystem,
      idSystem: globalState.idSystem,
    };
    dispatch(setLabelSystem(data));
  };

  const submitCloseLabelSystem = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.preventDefault();
    window.scroll({
      top: 0,
      behavior: 'smooth',
    });
    dispatchGlobalState({
      type: 'OPEN_FORM',
      currentForm: 'openedSystemDetails',
      idSystem: system?._id,
    });
  };

  useEffect(() => {
    if (successSystem) {
      window.scroll({
        top: 0,
        behavior: 'smooth',
      });
      dispatchGlobalState({
        type: 'OPEN_FORM',
        currentForm: 'openedSystemDetails',
        idSystem: system?._id,
      });
    }
    dispatch(resetSystemSlice());
  }, [successSystem, dispatch, globalState, dispatchGlobalState, system]);

  /*Fim Formulário de Label do Sistema*/

  return (
    <div className="absolute top-0 right-0 bottom-0 left-0">
      <div className="overflow-x-auto rounded-lg bg-white h-full">
        <div className="p-1">
          <div className=" bg-white">
            <div className="flex flex-col text-top-digital-content-color">
              <div className="mx-auto w-full mb-6">
                <h2 className="text-sm font-top-digital-content font-normal text-top-digital-content-color">
                  Adicione/Edite um apelido para o Sistema
                  <span className="font-bold">
                    {' '}
                    {globalState.labelSystem && globalState.labelSystem !== ''
                      ? globalState.labelSystem
                      : globalState.idSystem}
                  </span>
                </h2>
              </div>
            </div>
            <div className="mx-auto w-full">
              <form onSubmit={submitHandleLabelSystem}>
                <div className="mb-3">
                  <label
                    htmlFor="labelStation"
                    className="block text-smp font-medium text-top-digital-content-color"
                  >
                    Apelido para o Sistema
                  </label>
                  <input
                    type="text"
                    onChange={(e) =>
                      dispatchGlobalState({
                        type: 'SET_LABEL_SYSTEM',
                        labelSystem: e.target.value,
                      })
                    }
                    name="labelSystem"
                    value={globalState.labelSystem}
                    id="labelSystem"
                    className="customInputText w-2/3"
                  />
                </div>
                <div>
                  <div className="flex gap-1">
                    <button type="submit" className="customButton">
                      Enviar
                    </button>
                    <button
                      className="customButton"
                      type="button"
                      onClick={(e) => submitCloseLabelSystem(e)}
                    >
                      Cancelar
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditSystemLabel;
