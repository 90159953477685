import { api, requestConfig } from '../utils/config';

const insertProject = async (formData: FormData, token: string | undefined) => {
  const config = requestConfig('POST', formData, token, true); // true para multipart/form-data

  try {
    const res = await fetch(api + '/projects/uploadfile', config);
    const result = await res.json();
    if (!res.ok) {
      throw new Error(result.errors?.[0] || 'Erro ao adicionar projeto.');
    }
    return result;
  } catch (error: any) {
    throw error;
  }
};

// PUT
const updateProject = async (
  id: string,
  data: any,
  token: string | undefined,
) => {
  const config = requestConfig('PUT', data, token);

  try {
    const res = await fetch(api + `/projects/${id}`, config);
    const result = await res.json();
    if (!res.ok) {
      throw new Error(result.errors?.[0] || 'Erro ao atualizar projeto.');
    }
    return result;
  } catch (error: any) {
    throw error;
  }
};

// DELETE
const deleteProject = async (id: string, token: string | undefined) => {
  const config = requestConfig('DELETE', null, token);

  try {
    const res = await fetch(api + `/projects/${id}`, config);
    const result = await res.json();
    if (!res.ok) {
      throw new Error(result.errors?.[0] || 'Erro ao deletar projeto.');
    }
    return result;
  } catch (error: any) {
    throw error;
  }
};

// GET
const getAllProjects = async (token: string | undefined) => {
  const config = requestConfig('GET', null, token);

  try {
    const res = await fetch(api + '/projects', config);
    const result = await res.json();
    if (!res.ok) {
      throw new Error(result.errors?.[0] || 'Erro ao buscar projetos.');
    }
    return result;
  } catch (error: any) {
    throw error;
  }
};

// GET by ID
const getProjectById = async (id: string, token: string | undefined) => {
  const config = requestConfig('GET', null, token);

  try {
    const res = await fetch(api + `/projects/${id}`, config);
    const result = await res.json();
    if (!res.ok) {
      throw new Error(result.errors?.[0] || 'Erro ao buscar projeto.');
    }
    return result;
  } catch (error: any) {
    throw error;
  }
};

const ProjectService = {
  insertProject,
  updateProject,
  deleteProject,
  getAllProjects,
  getProjectById,
};

export default ProjectService;
