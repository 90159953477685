import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import useAppDispatch from '../../hooks/useAppDispatch';
import useAppSelector from '../../hooks/useAppSelector';
import Input from '../../presentational/input/Input';
import {
  changePasswordSelector,
  requestChangePassword,
} from '../../slices/ChangePasswordSlice';
import Button from '../../presentational/button/Button';
import { validatePassword } from '../../utils/validatePassword';

const ChangePassword = () => {
  const [actualPassword, setActualPassword] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');

  const dispatch = useAppDispatch();

  const {
    changePasswordRequested,
    changePasswordSucceeded,
    changePasswordFailed,
  } = useAppSelector(changePasswordSelector);

  useEffect(() => {
    if (changePasswordSucceeded) {
      toast.success('Senha alterada com sucesso!');
      setActualPassword('');
      setPassword('');
      setConfirmPassword('');
    }

    if (changePasswordFailed) {
      toast.error('Erro ao alterar a senha!');
    }
  }, [changePasswordSucceeded, changePasswordFailed]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    try {
      e.preventDefault();

      validatePassword(password, confirmPassword);

      const user = {
        newPassword: password,
        actualPassword,
      };
      dispatch(requestChangePassword(user));
    } catch (error: any) {
      toast.error(error.message);
    }
  };

  return (
    <div className="flex flex-1 flex-col justify-start px-6 py-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight top-digital-content-color">
          Alteração de Senha
        </h2>
      </div>

      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <form className="space-y-6" onSubmit={handleSubmit}>
          <div className="mt-2">
            <Input
              name="actualPassword"
              value={actualPassword}
              type="password"
              title="Senha Atual"
              placeholder="Senha Atual"
              onChange={setActualPassword}
              required
            />
          </div>

          <div className="mt-2">
            <Input
              name="password"
              value={password}
              type="password"
              title="Nova Senha"
              placeholder="Nova Senha"
              onChange={setPassword}
              required
            />
          </div>

          <div className="mt-2">
            <Input
              name="confirmPassword"
              value={confirmPassword}
              type="password"
              title="Confirme a Nova Senha"
              placeholder="Confirme a Nova Senha"
              onChange={setConfirmPassword}
              required
            />
          </div>

          <div>
            <Button type="submit" disabled={changePasswordRequested}>
              {changePasswordRequested ? 'Aguarde...' : 'Alterar'}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ChangePassword;
