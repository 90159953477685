import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { createAsyncThunk } from '@reduxjs/toolkit';
import StationService from '../services/StationService';
import { RootState } from '../store';

//interface of equipments
import { IStationStates } from '../Interfaces/IStation';

const initialState: IStationStates = {
  stationError: false,
  stationLoading: false,
  stationSuccess: false,
  stationMessage: '',
  faixaFreqLatLangError: false,
  faixaFreqLatLangSuccess: false,
  faixaFreqFilter: '',
  faixaLatitudeFilter: null,
  faixaLongitudeFilter: null,
  faixaRaioFilter: null,
  faixaFreqLatLangMessage: '',
  faixaIncludeFaixaFreq: false,
  faixaIncludeLatLon: false,
  labels: [],
  stations: [],
  station: null,
  totalStations: 0,
  uploadProgress: 0,
  infoWindowPage: 0,
};

//get all equipments by location
export const getStationsByLocation = createAsyncThunk(
  'stations/getStationsByLocation',
  async (id: string, thunkAPI) => {
    const appState = thunkAPI.getState() as RootState;
    const token = appState.LoginReducer.user!;
    const res = await StationService.getStationsByLocation(id, token);
    if (res.errors) {
      return thunkAPI.rejectWithValue(res.errors[0]);
    }

    return res;
  },
);

//get equipment by ID
export const getStationById = createAsyncThunk(
  'stations/getStationById',
  async (id: string | undefined, thunkAPI) => {
    const appState = thunkAPI.getState() as RootState;
    const token = appState.LoginReducer.user!;
    const res = await StationService.getStationById(id, token);
    //check for errors

    if (res.errors) {
      return thunkAPI.rejectWithValue(res.errors[0]);
    }

    return res;
  },
);

//get all equipments of visible map area
export const getVisibleStations = createAsyncThunk(
  'stations/getVisibleStations',
  async (data: any, thunkAPI): Promise<any> => {
    const appState = thunkAPI.getState() as RootState;
    const token = appState.LoginReducer.user!;
    const res = await StationService.getVisibleStations(data, token);
    //check for errors
    if (res.errors) {
      return thunkAPI.rejectWithValue(res.errors[0]);
    }
    return res;
  },
);

//set Label for Station
export const setLabelStation = createAsyncThunk(
  'stations/setLabelStation',
  async (data: any, thunkAPI): Promise<any> => {
    const appState = thunkAPI.getState() as RootState;
    const token = appState.LoginReducer.user!;
    const res = await StationService.updateStationField(data, token);
    //check for errors
    if (res.errors) {
      return thunkAPI.rejectWithValue(res.errors[0]);
    }
    return res;
  },
);

//set Label for Station
export const checkFaixaFreqLatLanFilter = createAsyncThunk(
  'stations/checkFaixaFreqFilter',
  async (data: any, thunkAPI): Promise<any> => {
    const appState = thunkAPI.getState() as RootState;
    const token = appState.LoginReducer.user!;
    const res = await StationService.checkFaixaFreqLatLanFilter(data, token);
    //check for errors
    if (res.errors) {
      return thunkAPI.rejectWithValue(res.errors[0]);
    }
    return res;
  },
);

//
export const uploadStations = createAsyncThunk(
  'station/uploadStations',
  async (csv: FormData, { getState, rejectWithValue, dispatch }) => {
    const appState = getState() as RootState;
    const token = appState.LoginReducer.user!;
    const res: any = await StationService.uploadStations(
      csv,
      token,
      (progressEvent) => {
        const progress = Math.round(
          (progressEvent.loaded / progressEvent.total) * 100,
        );
        dispatch(setUploadProgress(progress));
      },
    );
    // Verifique erros e retorne a resposta ou rejeite com o valor do erro
    if (res.errors) {
      return rejectWithValue(res.errors[0]);
    }
    return res;
  },
);

export const StationSlice = createSlice({
  name: 'station',
  initialState,
  reducers: {
    stationReset: (state) => {
      state.stationError = false;
      state.stationSuccess = false;
      state.stationLoading = false;
      state.stationMessage = '';
      state.uploadProgress = 0;
      state.faixaFreqLatLangError = false;
      state.faixaFreqLatLangSuccess = false;
    },
    stationResetFilters: (state) => {
      state.stationError = false;
      state.stationSuccess = false;
      state.stationLoading = false;
      state.stationMessage = '';
      state.uploadProgress = 0;
      state.faixaFreqLatLangError = false;
      state.faixaFreqLatLangSuccess = false;
      state.faixaFreqFilter = '';
      state.faixaLatitudeFilter = null;
      state.faixaLongitudeFilter = null;
      state.faixaRaioFilter = null;
      state.faixaFreqLatLangMessage = '';
      state.faixaIncludeFaixaFreq = false;
      state.faixaIncludeLatLon = false;
    },
    stationResetLatLang: (state) => {
      state.faixaLatitudeFilter = null;
      state.faixaLongitudeFilter = null;
      state.faixaRaioFilter = null;
    },
    setUploadProgress: (state, action: PayloadAction<number>) => {
      state.uploadProgress = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(getStationsByLocation.fulfilled, (state, action) => {
        state.stationError = false;
        state.stationLoading = false;
        state.stationSuccess = false;
        state.stations = action.payload;
      })
      .addCase(getStationsByLocation.pending, (state) => {
        state.stationError = false;
        state.stationLoading = true;
      })
      .addCase(getStationsByLocation.rejected, (state, action) => {
        state.stationError = false;
        state.stationLoading = false;
        state.stationSuccess = false;
        state.stationMessage =
          typeof action.payload === 'string' ? action.payload : '';
      })
      .addCase(getVisibleStations.fulfilled, (state, action) => {
        state.stationError = false;
        state.stationLoading = false;
        state.stationSuccess = true;
        state.labels = action.payload[0];
        state.stations = action.payload[1];
        state.totalStations = action.payload[2];
        state.infoWindowPage = action.payload[3];
        state.stationMessage =
          typeof action.payload === 'string' ? action.payload : '';
      })
      .addCase(getVisibleStations.pending, (state) => {
        state.stationError = false;
        state.stationLoading = true;
      })
      .addCase(
        getVisibleStations.rejected,
        (state, action: PayloadAction<any>) => {
          state.stationError = true;
          state.stationLoading = false;
          state.stationSuccess = false;
          state.stationMessage =
            typeof action.payload === 'string' ? action.payload : '';
        },
      )
      .addCase(checkFaixaFreqLatLanFilter.fulfilled, (state, action) => {
        //console.log("PAYLOAD", action.payload);
        state.stationLoading = false;
        if (
          action.payload.success !== undefined &&
          typeof action.payload.success === 'boolean' &&
          action.payload.success === false
        ) {
          state.faixaFreqLatLangError = true;
          state.faixaFreqLatLangSuccess = false;
          state.faixaFreqLatLangMessage = action.payload.message
            ? action.payload.message
            : '';
          state.faixaFreqFilter = action.payload.faixaFreqFilter
            ? action.payload.faixaFreqFilter
            : '';
          state.faixaLatitudeFilter = action.payload.faixaLatitudeFilter
            ? action.payload.faixaLatitudeFilter
            : null;
          state.faixaLongitudeFilter = action.payload.faixaLongitudeFilter
            ? action.payload.faixaLongitudeFilter
            : null;
          state.faixaRaioFilter = action.payload.faixaRaioFilter
            ? action.payload.faixaRaioFilter
            : null;

          state.faixaIncludeFaixaFreq = action.payload.faixaIncludeFaixaFreq
            ? action.payload.faixaIncludeFaixaFreq
            : false;
          state.faixaIncludeLatLon = action.payload.faixaIncludeLatLon
            ? action.payload.faixaIncludeLatLon
            : false;
        } else {
          state.faixaFreqLatLangError = false;
          state.faixaFreqLatLangSuccess = true;
          state.faixaFreqLatLangMessage = action.payload.message
            ? action.payload.message
            : '';
          state.faixaFreqFilter = action.payload.faixaFreqFilter
            ? action.payload.faixaFreqFilter
            : '';
          state.faixaLatitudeFilter = action.payload.faixaLatitudeFilter
            ? action.payload.faixaLatitudeFilter
            : null;
          state.faixaLongitudeFilter = action.payload.faixaLongitudeFilter
            ? action.payload.faixaLongitudeFilter
            : null;
          state.faixaRaioFilter = action.payload.faixaRaioFilter
            ? action.payload.faixaRaioFilter
            : null;
          state.faixaIncludeFaixaFreq = action.payload.faixaIncludeFaixaFreq
            ? action.payload.faixaIncludeFaixaFreq
            : false;
          state.faixaIncludeLatLon = action.payload.faixaIncludeLatLon
            ? action.payload.faixaIncludeLatLon
            : false;
        }
      })
      .addCase(checkFaixaFreqLatLanFilter.pending, (state) => {
        state.stationError = false;
        state.stationLoading = true;
      })
      .addCase(
        checkFaixaFreqLatLanFilter.rejected,
        (state, action: PayloadAction<any>) => {
          state.stationError = true;
        },
      )
      //checkLatLngFilter
      .addCase(uploadStations.fulfilled, (state, action) => {
        state.stationError = false;
        state.stationLoading = true;
        state.stationSuccess = true;
      })
      .addCase(uploadStations.pending, (state) => {
        state.stationError = false;
        state.stationLoading = true;
      })
      .addCase(uploadStations.rejected, (state, action) => {
        state.stationError = false;
        state.stationLoading = false;
        state.stationSuccess = false;
        state.stationMessage =
          typeof action.payload === 'string' ? action.payload : '';
      })
      .addCase(getStationById.fulfilled, (state, action) => {
        state.stationError = false;
        state.stationLoading = false;
        state.stationSuccess = false;
        state.station = action.payload;
      })
      .addCase(getStationById.pending, (state) => {
        state.stationError = false;
        state.stationLoading = true;
      })
      .addCase(getStationById.rejected, (state, action) => {
        state.stationError = false;
        state.stationLoading = false;
        state.stationSuccess = false;
        state.stationMessage =
          typeof action.payload === 'string' ? action.payload : '';
      })
      .addCase(setLabelStation.fulfilled, (state, action) => {
        state.stationError = false;
        state.stationLoading = false;
        state.stationSuccess = true;
      })
      .addCase(setLabelStation.pending, (state) => {
        state.stationError = false;
        state.stationLoading = true;
      })
      .addCase(setLabelStation.rejected, (state, action) => {
        state.stationError = false;
        state.stationLoading = false;
        state.stationSuccess = false;
        state.stationMessage =
          typeof action.payload === 'string' ? action.payload : '';
      });
  },
});

export const {
  stationReset,
  stationResetFilters,
  setUploadProgress,
  stationResetLatLang,
} = StationSlice.actions;
export const stationSelector = (state: RootState) => state.StationReducer;
export default StationSlice.reducer;
