import useAppDispatch from '../../hooks/useAppDispatch';
import useAppSelector from '../../hooks/useAppSelector';

import { useEffect, useState } from 'react';
import { X, Plus, Save } from 'lucide-react';

//import { loginSelector } from "../../slices/LoginSlice";

import {
  fistelSelector,
  checkFistel,
  reset,
  removeFistel,
} from '../../slices/FistelSlice';

type Props = {
  cnpj: string;
};

const FistelSelection = ({ cnpj }: Props) => {
  const {
    fistelList,
    fistelToCheck,
    fistelError,
    fistelLoading,
    fistelSuccess,
    fistelMessage,
  } = useAppSelector(fistelSelector);

  const [fistelFormValue, setFistelFormValue] = useState(
    fistelToCheck ? fistelToCheck : '',
  );
  const [openFilstelForm, setOpenFistelForm] = useState(
    fistelToCheck && fistelError ? true : false,
  );
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const dispatch = useAppDispatch();

  const handleAddCode = () => {
    dispatch(reset());
    setOpenFistelForm(true);
  };

  const handleAddCancelCode = () => {
    setOpenFistelForm(false);
    setError(false);
  };

  const handleSave = () => {
    dispatch(reset());
    if (fistelFormValue.length !== 11 || !fistelFormValue.match(/^[0-9]+$/)) {
      setError(true);
      setErrorMessage('Código inválido');
      return;
    }
    if (fistelList.includes(fistelFormValue)) {
      setError(true);
      setErrorMessage('Código já adicionado');
      return;
    }
    dispatch(checkFistel({ numCNPJ: cnpj, numFistel: fistelFormValue }));
  };

  const handleRemoveSavedCode = (index: number) => {
    dispatch(removeFistel(index));
  };

  useEffect(() => {
    if (fistelSuccess) {
      setOpenFistelForm(false);
      setFistelFormValue('');
      setError(false);
      dispatch(reset());
    } else if (fistelError) {
      setError(true);
      setErrorMessage(fistelMessage || 'Erro ao adicionar código de Fistel');
    }
  }, [fistelSuccess, fistelError, fistelMessage, fistelFormValue, dispatch]);

  return (
    <div className="flex-col">
      <div className="flex items-center">
        <label
          htmlFor="cpf"
          className="block text-smp font-semibold leading-6 top-digital-content-color"
        >
          Código(s) de Fistel *
        </label>
        <Plus className="cursor-pointer w-4 h-4" onClick={handleAddCode} />
      </div>
      {openFilstelForm && (
        <div className="mt-2 flex items-center gap-1">
          <input
            type="text"
            value={fistelFormValue}
            onChange={(e) => setFistelFormValue(e.target.value)}
            className="customInputText w-2/3"
          />
          <X className="cursor-pointer w-4 h-4" onClick={handleAddCancelCode} />
          <Save className="cursor-pointer w-4 h-4" onClick={handleSave} />
        </div>
      )}
      {fistelLoading && (
        <div className="block text-smp font-semibold leading-6 top-digital-content-color">
          Carregando...
        </div>
      )}
      {error && (
        <div className="block text-smp font-semibold leading-6 text-red-500">
          {errorMessage}
        </div>
      )}

      {fistelList.length > 0 ? (
        <div>
          <h3 className="block text-smp font-semibold leading-6 top-digital-content-color">
            Códigos Salvos:
          </h3>
          <ul>
            {fistelList.map((fistel, index) => (
              <div className="flex items-center">
                <li
                  className="block text-smp font-normal leading-6 top-digital-content-color"
                  key={index}
                >
                  {fistel}
                </li>
                <X
                  className="cursor-pointer w-4 h-4"
                  onClick={() => handleRemoveSavedCode(index)}
                />
              </div>
            ))}
          </ul>
        </div>
      ) : (
        <p className="block text-smp font-normal leading-6 top-digital-content-color">
          Nenhum código adicionado
        </p>
      )}
    </div>
  );
};

export default FistelSelection;
