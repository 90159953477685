import React, { useEffect, useState } from 'react';
import useAppDispatch from '../../hooks/useAppDispatch';
import useAppSelector from '../../hooks/useAppSelector';

//redux
import {
  cnpjSelector,
  addCNPJ,
  hiddenCNPJ,
  getUserCNPJ,
  reset,
} from '../../slices/CNPJSlice';
import formatCNPJ from '../../utils/formatCNPJ';
import Enabled from '../Utils/Enabled';
import CNPJSelection from '../../pages/Register/CNPJSelection';
import { ICNPJDetails } from '../../Interfaces/ICNPJ'; // Ajuste o caminho conforme necessário

type Props = {};

const CNPJConfig = (props: Props) => {
  const dispatch = useAppDispatch();
  const [cnpjLoading, setCnpjLoading] = useState<boolean>(false);
  const [openedForm, setOpenedForm] = useState<boolean>(false);
  const [cnpjError, setCnpjError] = useState<string>('');
  const [cnpjList, setCnpjList] = useState<string[]>([]);
  const [processingCNPJs, setProcessingCNPJs] = useState<string[]>([]); // Lista de CNPJs em processamento
  const [processedCNPJs, setProcessedCNPJs] = useState<string[]>([]); // Lista de CNPJs que concluíram o processamento

  const validateCnpj = (): boolean => {
    if (cnpjList.length === 0) {
      setCnpjError('Por favor, insira pelo menos um CNPJ.');
      return false;
    }
    setCnpjError('');
    return true;
  };

  const {
    cnpjConfigList,
    cnpjAddSuccess,
    cnpjMessage,
    cnpjError: error,
  } = useAppSelector(cnpjSelector);

  useEffect(() => {
    dispatch(getUserCNPJ());
  }, [dispatch]);

  const unformatCNPJ = (cnpj: string) => cnpj.replace(/[.\-/]/g, '');

  useEffect(() => {
    let interval: NodeJS.Timeout;

    if (processingCNPJs.length > 0) {
      interval = setInterval(() => {
        dispatch(getUserCNPJ()); // Atualiza os CNPJs

        if (cnpjConfigList && cnpjConfigList.length > 0) {
          const remainingCNPJs = processingCNPJs.filter((processingCNPJ) => {
            const cnpjItem = cnpjConfigList.find(
              (c) => c.NumCNPJ === processingCNPJ,
            );

            if (cnpjItem?.isProcessed) {
              // Adiciona o CNPJ na lista de processados para exibir a mensagem temporária
              setProcessedCNPJs((prev) => [...prev, processingCNPJ]);

              // Remove a mensagem após 5 segundos
              setTimeout(() => {
                setProcessedCNPJs((prev) =>
                  prev.filter((cnpj) => cnpj !== processingCNPJ),
                );
              }, 5000);

              return false; // Remove da lista de processamento
            }
            return true; // Mantém na lista de processamento
          });

          if (remainingCNPJs.length !== processingCNPJs.length) {
            setProcessingCNPJs(remainingCNPJs);
          }

          if (remainingCNPJs.length === 0) {
            clearInterval(interval);
          }
        }
      }, 2000);
    }

    return () => clearInterval(interval);
  }, [processingCNPJs, cnpjConfigList, dispatch]);

  const submitHandle = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!validateCnpj()) {
      return;
    }
    const data = { cnpjList };
    dispatch(addCNPJ(data)).then(() => {
      dispatch(reset());
      // Adiciona os CNPJs desformatados ao estado
      setProcessingCNPJs(cnpjList.map((cnpj) => unformatCNPJ(cnpj)));
      setCnpjList([]);
    });

    setOpenedForm(false);
  };

  const handleIsisVisible = (isVisible: boolean, numCNPJ: string) => {
    setProcessingCNPJs((prev) => [...prev, numCNPJ]);
    setCnpjLoading(true);
    const data = { isVisible, numCNPJ };
    dispatch(hiddenCNPJ(data)).then(() => {
      dispatch(reset());
      setCnpjLoading(false);
    });
  };

  const openFormCNPJ = (e: React.FormEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setOpenedForm(true);
    setCnpjError('');
  };

  useEffect(() => {
    if (error) {
      setCnpjError(cnpjMessage as string);
    }
  }, [error, cnpjMessage]);

  return (
    <>
      <div className="px-4 sm:px-6 lg:px-8 w-full mt-10 mx-auto">
        <div className="sm:flex sm:items-start mx-auto">
          <div className="sm:flex-auto">
            <h2 className="text-base font-semibold leading-6 top-digital-content-color">
              Configurações de CNPJ
            </h2>
            <p className="mt-1 mb-2 text-smp text-gray-700">
              Crie ou edite configurações de CNPJ.
            </p>
            {cnpjError && (
              <div className="mt-2 text-red-600 text-smp">{cnpjError}</div>
            )}
            {cnpjAddSuccess &&
              (parseInt(cnpjMessage as string) > 1 ? (
                <div className="mt-2 text-green-600 text-smp">
                  CNPJs adicionados com sucesso.
                </div>
              ) : (
                <div className="mt-2 text-green-600 text-smp">
                  CNPJ adicionado com sucesso.
                </div>
              ))}
          </div>
          {!openedForm && (
            <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
              <button
                onClick={openFormCNPJ}
                type="button"
                className="block rounded-md bg-top-digital hover:text-top-digital-buttom-hover px-3 py-2 text-center text-smp font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
              >
                Adicionar CNPJ
              </button>
            </div>
          )}
        </div>

        {cnpjLoading ? (
          <p>Aguarde....</p>
        ) : (
          <>
            {openedForm && (
              <div className="mt-4">
                <form onSubmit={submitHandle} className="w-[80%] md:w-[50%]">
                  <label
                    htmlFor="numCNPJ"
                    className="block text-smp font-medium leading-6 top-digital-content-color"
                  >
                    Número do CNPJ
                  </label>
                  <div className="mt-2 mb-4">
                    <CNPJSelection
                      cnpjList={cnpjList}
                      setCnpjList={setCnpjList}
                      setCnpjError={setCnpjError}
                    />
                  </div>
                  <div className="mt-4 mb-8">
                    <div className="flex gap-1">
                      <button
                        type="submit"
                        className="block rounded-md bg-top-digital hover:text-top-digital-buttom-hover px-3 py-2 text-center text-smp font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                      >
                        Cadastrar
                      </button>
                      <button
                        className="block rounded-md bg-top-digital hover:text-top-digital-buttom-hover px-3 py-2 text-center text-smp font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                        type="button"
                        onClick={() => setOpenedForm(false)}
                      >
                        Cancelar
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            )}
            {cnpjConfigList && cnpjConfigList.length !== 0 && (
              <div>
                <table className="min-w-full divide-y divide-gray-300 border">
                  <thead>
                    <tr className="bg-top-digital-op-40">
                      <th className="py-3.5 pl-4 pr-3 text-left text-smp font-semibold top-digital-content-color sm:pl-0 whitespace-nowrap">
                        Habilitar/Desabilitar
                      </th>
                      <th className="py-3.5 pl-4 pr-3 text-left text-smp font-semibold top-digital-content-color sm:pl-0 whitespace-nowrap">
                        Número do CNPJ
                      </th>
                      <th className="px-3 py-3.5 text-left text-smp font-semibold top-digital-content-color whitespace-nowrap">
                        Nome Entidade
                      </th>
                      <th className="px-3 py-3.5 text-left text-smp font-semibold top-digital-content-color whitespace-nowrap">
                        Nome Fantasia
                      </th>
                      <th className="px-3 py-3.5 text-left text-smp font-semibold top-digital-content-color whitespace-nowrap">
                        Município
                      </th>
                      <th className="px-3 py-3.5 text-left text-smp font-semibold top-digital-content-color whitespace-nowrap">
                        UF
                      </th>
                      <th className="px-3 py-3.5 text-left text-smp font-semibold top-digital-content-color whitespace-nowrap">
                        Fistel Outorga/Processo SEI Outorga
                      </th>
                      <th className="px-3 py-3.5 text-left text-smp font-semibold top-digital-content-color whitespace-nowrap">
                        Fistel/Processo notificações
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200">
                    {cnpjConfigList.map((cnpj: ICNPJDetails, index: number) => (
                      <tr
                        key={index}
                        className={`bg-${
                          index % 2 === 0 ? 'white' : 'top-digital-op-25'
                        } text-top-digital-content-color p-1`}
                      >
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-smp font-medium top-digital-content-color sm:pl-0">
                          {processingCNPJs.includes(cnpj.NumCNPJ) ? (
                            <p className="text-red-600">
                              Aguarde o processamento...
                            </p>
                          ) : processedCNPJs.includes(cnpj.NumCNPJ) ? (
                            <p className="text-green-600">
                              Processamento concluído
                            </p>
                          ) : (
                            <span
                              title="Habilitar/Desabilitar"
                              onClick={() =>
                                handleIsisVisible(cnpj.isVisible, cnpj.NumCNPJ)
                              }
                            >
                              <Enabled enabled={cnpj.isVisible} />
                            </span>
                          )}
                        </td>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-smp font-medium top-digital-content-color sm:pl-0">
                          {formatCNPJ(cnpj.NumCNPJ)}
                        </td>
                        <td className="px-3 py-4 text-smp text-gray-500">
                          {
                            cnpj.DadosAdicionais[0]
                              ?.Nome_Entidade_Prestadora_Servico
                          }
                        </td>
                        <td className="px-3 py-4 text-smp text-gray-500">
                          {cnpj.DadosAdicionais[0]?.Nome_Fantasia}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-smp text-gray-500">
                          {
                            cnpj.DadosAdicionais[0]
                              ?.Nome_Municipio_Endereco_Sede
                          }
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-smp text-gray-500">
                          {cnpj.DadosAdicionais[0]?.UF_Endereco_Sede}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-smp text-gray-500">
                          <div className="grid grid-cols-2 gap-4 w-4/5">
                            <div className="font-semibold">Fistel</div>
                            <div className="font-semibold">Processo SEI</div>
                            {[
                              ...new Set(
                                cnpj.DadosAdicionais.map(
                                  (outorga) => outorga.Fistel_Outorga,
                                ),
                              ),
                            ].map((fistel, outorgaIndex) => (
                              <React.Fragment key={outorgaIndex}>
                                <div>{fistel}</div>
                                <div>
                                  {
                                    cnpj.DadosAdicionais.find(
                                      (outorga) =>
                                        outorga.Fistel_Outorga === fistel,
                                    )?.Processo_SEI_Outorga
                                  }
                                </div>
                              </React.Fragment>
                            ))}
                          </div>
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-smp text-gray-500">
                          <div className="grid grid-cols-2 gap-4 w-4/5">
                            <div className="font-semibold">Fistel</div>
                            <div className="font-semibold">Processo SEI</div>
                            {[
                              ...new Set(
                                cnpj.DadosAdicionais.map(
                                  (notificacao) =>
                                    notificacao.Fistel_Notificacao,
                                ),
                              ),
                            ].map((fistel, notifIndex) => (
                              <React.Fragment key={notifIndex}>
                                <div>{fistel}</div>
                                <div>
                                  {
                                    cnpj.DadosAdicionais.find(
                                      (notificacao) =>
                                        notificacao.Fistel_Notificacao ===
                                        fistel,
                                    )?.Processo_SEI_Notificacao
                                  }
                                </div>
                              </React.Fragment>
                            ))}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default CNPJConfig;
