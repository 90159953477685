export const validatePassword = (
  password: string,
  confirmPassword: string,
): [boolean, string] => {
  if (password !== confirmPassword) {
    return [false, 'As senhas não conferem'];
  }

  if (password.length < 8) {
    return [false, 'A senha precisa ter pelo menos 8 caracteres'];
  }

  if (!password.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/)) {
    return [
      false,
      'A senha precisa conter ao menos uma letra maiúscula e um número',
    ];
  }

  return [true, ''];
};
