import { api, requestConfig } from '../utils/config';

//get All Equipamentos

const getChildrenUsers = async (token: string | undefined) => {
  const config = requestConfig('GET', null, token);

  try {
    const res = await fetch(api + '/admin/getchildrenusers', config)
      .then((res) => res.json())
      .catch((err) => err);
    return res;
  } catch (error) {
    console.error(error);
  }
};

const profileChild = async (
  data: { id: string },
  token: string | undefined,
) => {
  const config = requestConfig('POST', data, token);

  try {
    const res = await fetch(api + '/admin/profilechild', config)
      .then((res) => res.json())
      .catch((err) => err);

    return res;
  } catch (error) {
    //console.log(error);
  }
};

//resendConfirmationEmail
const resendConfirmationEmail = async (
  data: { id: string },
  token: string | undefined,
) => {
  const config = requestConfig('POST', data, token);

  try {
    const res = await fetch(api + '/admin/resendconfirmationemail', config)
      .then((res) => res.json())
      .catch((err) => err);
    return res;
  } catch (error) {
    //console.log(error);
  }
};

const AdminService = {
  getChildrenUsers,
  profileChild,
  resendConfirmationEmail,
};

export default AdminService;
