import { useParams } from 'react-router-dom';
import React, { useEffect } from 'react';

//redux
import useAppDispatch from '../../hooks/useAppDispatch';
import useAppSelector from '../../hooks/useAppSelector';
import {
  linkSystemDocSelector,
  getAllSystemsByStation,
} from '../../slices/LinkSystemDocSlice';
///home/adriano/dev/agiliza/frontend/src/slices/DocumentTypeSlice.
import {
  docTypeSelector,
  getDocTypeById,
} from '../../slices/DocumentTypeSlice';
import { documentSelector, getDocById } from '../../slices/DocumentSlice';

//icons
import { X } from 'lucide-react';

//type
import { TSystem } from '../../Interfaces/ISystem';

//components
import SystemActionLinkDoc from './SystemActionLinkDoc';

//context
import { useGlobalContext } from '../../hooks/useGlobalContext';

const SystemLinkDocument = () => {
  //context
  const { globalState, dispatchGlobalState } = useGlobalContext();

  //const SystemActionLinkDocMemorized = React.memo(SystemActionLinkDoc);

  const dispatch = useAppDispatch();
  const {
    successLinkSystem,
    systemsToLink,
    labels,
    loading: loadingSystems,
  } = useAppSelector(linkSystemDocSelector);
  const { docType, loading: loadingDocType } = useAppSelector(docTypeSelector);
  const { id } = useParams();

  const { document, loading: loadingDoc } = useAppSelector(documentSelector);

  useEffect(() => {
    if (!document?.typeId) return;
    dispatch(getDocTypeById(document.typeId));
  }, [dispatch, document]);

  useEffect(() => {
    dispatch(getAllSystemsByStation(id));
  }, [globalState.idDocument, dispatch, id, successLinkSystem]);

  useEffect(() => {
    const objData = {
      id: globalState.idDocument,
    };
    dispatch(getDocById(objData));
  }, [globalState.idDocument, dispatch]);

  /**
   * Se o sistema possuir potência menor ou igual a 2W
   * E frequencia maior que 2GHz o laudo radioméftrico é não é necessário
   * Dessa forma esses sistermas não podem ser vinculados a documentos que possuem o tipo de documento laudo radiométrico
   */

  // Função para converter strings para números, retorna NaN se conversão falhar
  const toDouble = (str: string): number => {
    const num = parseFloat(str);
    return isNaN(num) ? NaN : num;
  };

  // Funções para verificar as condições, retornam true ou false
  const filterByFrequency = (system: { FreqTxMHz: string }): boolean => {
    const freq = toDouble(system.FreqTxMHz);
    return !isNaN(freq) && freq > 2000;
  };

  const filterByPower = (system: {
    PotenciaTransmissorWatts: string;
  }): boolean => {
    const power = toDouble(system.PotenciaTransmissorWatts);
    return !isNaN(power) && power <= 2;
  };

  // Suponha que systemsToLink é um array de sistemas com os campos FreqTxMHz e PotenciaTransmissorWatts como strings
  let filteredSistems: TSystem[]; // Substitua SystemType pelo tipo adequado de seus sistemas
  const shouldExcludeSistems = docType?.potFrequency === true;

  if (shouldExcludeSistems) {
    // Filtrar sistemas onde a condição de frequência ou potência é satisfeita
    filteredSistems = systemsToLink.filter(
      (system) => !filterByFrequency(system),
    );
    filteredSistems = filteredSistems.filter(
      (system) => !filterByPower(system),
    );
  } else {
    // Se não há necessidade de excluir sistemas, copie todos eles
    filteredSistems = [...systemsToLink];
  }
  // Agora filteredSistems contém os sistemas filtrados de acordo com as condições

  return (
    <div className="absolute bg-white top-0 left-0 w-full h-full">
      {filteredSistems && filteredSistems.length !== 0 && (
        <>
          <div className=" bg-white top-0 z-50 flex justify-between mb-2">
            <div>
              {loadingSystems || loadingDocType || loadingDoc ? (
                <h2 className="text-base text-top-digital-content-color">
                  Vincular o documento{' '}
                  <span className="text-top-digital-hover hover:text-top-digital-link-hover font-semibold">
                    {document?.title}
                  </span>{' '}
                  aos sistemas. Aguarde...
                </h2>
              ) : (
                <h2 className="text-base text-top-digital-content-color">
                  Vincular o documento{' '}
                  <span className="text-top-digital-hover hover:text-top-digital-link-hover font-semibold">
                    {document?.title}
                  </span>{' '}
                  aos sistemas
                </h2>
              )}
            </div>
            <div>
              <X
                className="cursor-pointer"
                onClick={() => {
                  dispatchGlobalState({
                    type: 'CLOSE_SYSTEM_LINK_FORM',
                    idDocument: '',
                  });
                }}
              />
            </div>
          </div>
          <div className="z-0 static">
            <table className="text-left text-smp font-light border-2">
              <thead className="border-b bg-top-digital-op-40 dark:border-neutral-500">
                <tr key="loc0">
                  <th
                    key="vincular"
                    scope="col"
                    className="px-6 py-4 whitespace-nowrap font-top-digital-content text-smp text-black font-medium"
                  >
                    Vincular
                  </th>
                  {labels && labels.length !== 0 && (
                    <>
                      {labels.map(
                        (label, index) =>
                          (label[0] === 'idAnatel' ||
                            label[0] === 'Label' ||
                            label[0] === 'FreqRxMHz' ||
                            label[0] === 'FreqTxMHz') && (
                            <th
                              key={index}
                              scope="col"
                              className="px-6 py-4 whitespace-nowrap font-top-digital-content text-smp text-black font-medium"
                            >
                              {label[1]}
                            </th>
                          ),
                      )}
                    </>
                  )}
                </tr>
              </thead>
              <tbody>
                {filteredSistems &&
                  filteredSistems.map((system, index) => (
                    <tr
                      key={system._id}
                      className={`border-b dark:border-neutral-500 ${
                        index % 2
                          ? ' bg-top-digital-secondary-light'
                          : 'bg-white'
                      }`}
                    >
                      <td
                        key="vincular"
                        className="px-6 py-4 whitespace-nowrap font-top-digital-content text-smp text-top-digital-secondary-dark font-medium"
                      >
                        <div className="flex items-center justify-center gap-1">
                          <SystemActionLinkDoc
                            key={`link-${system._id}-${globalState.idDocument}`}
                            document={globalState.idDocument}
                            system={system._id}
                            linked={system.documents!.includes(
                              globalState.idDocument,
                            )}
                          />
                          <span>{system._id}</span>
                        </div>
                      </td>
                      {labels &&
                        labels.map(
                          (label) =>
                            (label[0] === 'idAnatel' ||
                              label[0] === 'Label' ||
                              label[0] === 'FreqRxMHz' ||
                              label[0] === 'FreqTxMHz') && (
                              <td
                                key={`${label[0]}${system._id}`}
                                className="whitespace-nowrap px-6 py-4 font-normal text-smp font-top-digital-content"
                              >
                                <p>
                                  {system[
                                    label[0] as keyof TSystem
                                  ]?.toString()}
                                </p>
                              </td>
                            ),
                        )}
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </>
      )}
    </div>
  );
};

export default SystemLinkDocument;
