// Definir um tipo para os dados que irão para o Excel
export type TBoletoExcelData = {
  NumFistel: string;
  CNPJ: string;
  dataVencimento: Date | null;
  dataPagamento: Date | string | null;
  anoReferencia: string | null;
  servico: string;
  receita: string;
  valorPrincipal: number | null;
  valorDescontosAbatimentos: number | null;
  valorOutrasDeducoes: number | null;
  valorMoraMultaJuros: number | null;
  valorJurosEncargos: number | null;
  valorOutrosAcrescimos: number | null;
  valorTotal: number | null;
  situacao: string;
  Nome_Entidade_Prestadora_Servico: string;
  Nome_Fantasia: string;
  Nome_Municipio_Endereco_Sede: string;
  UF_Endereco_Sede: string;
};

// TBoletoData herda de TBoletoExcelData e adiciona propriedades adicionais
export type TBoletoData = TBoletoExcelData & {
  processo: string;
  nossoNumero: string;
  linhaDigitavel: string;
};

export interface IBoletoStates {
  boletoData: TBoletoData[] | [];
  boletoError: boolean;
  boletoSuccess: boolean;
  boletoLoading: boolean;
  boletoMessage: string;
  boletoProcessing: boolean;
  downloadBoletoData: any;
  downloadBoletoError: boolean;
  downloadBoletoSuccess: boolean;
  downloadBoletoLoading: boolean;
  downloadBoletoMessage: string;
  lastDateCheckBoletos: string;
}

export type BankHolidays = {
  [year: string]: {
    [month: string]: string[];
  };
};

export const bankHolidays: BankHolidays = {
  '2024': {
    '02': ['2024-02-12', '2024-02-13'], // Carnaval
    '03': ['2024-03-29'], // Sexta-Feira da Paixão
    '04': ['2024-04-21'], // Dia de Tiradentes
    '05': ['2024-05-01', '2024-05-30'], // Dia do Trabalho e Corpus Christi
    '09': ['2024-09-07'], // Independência do Brasil
    '10': ['2024-10-12'], // Nossa Senhora Aparecida
    '11': ['2024-11-02', '2024-11-15', '2024-11-20', '2024-11-30'], // Dia de Finados, Proclamação da República e Dia da Consciência Negra
    '12': ['2024-12-25'], // Natal
  },
};

export type TObjectBoleto = {
  nossoNumero: string;
  paymentDate: string;
  fileName: string;
};
