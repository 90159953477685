//react-table
import type { ColumnDef } from '@tanstack/react-table';

import { Link } from 'react-router-dom';

import useAppSelector from '../../hooks/useAppSelector';

import convertMongoDateToBRFormat from '../../utils/dateBR';

import { notificationSelector } from '../../slices/NotificationSlice';
import { useMemo } from 'react';
import { TNotificationData } from '../../Interfaces/INotificationData';
import Field from '../../pages/Notification/Field';
import { Table } from './Table';

type Props = {
  setNotificationsOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

//row.row.getValue(numEstacao)

const Notification = ({ setNotificationsOpen }: Props) => {
  const { notificationUserData } = useAppSelector(notificationSelector);

  const cols = useMemo<ColumnDef<TNotificationData, any>[]>(
    () => [
      {
        header: 'Descrição',
        cell: (row) => {
          return (
            <Field
              data={row.row.original.description}
              read={row.row.original.read}
              id={row.row.original._id}
              key={row.row.original._id}
              setNotificationsOpen={setNotificationsOpen}
            />
          );
        },
        accessorKey: 'description',
      },
      {
        header: 'Data da Operação',
        cell: (row) =>
          convertMongoDateToBRFormat(row.row.getValue('timestamp')), // Corrigido para usar row.getValue()
        accessorKey: 'timestamp',
      },
    ],
    [setNotificationsOpen], // Inclua dependências externas aqui, se houver
  );

  const data: TNotificationData[] = notificationUserData || [];

  return (
    <>
      {/* center */}
      <div className="relative">
        {/* square-main */}
        <div className="absolute right-3 top-3 w-80 h-80 bg-white transform flex flex-col shadow rounded-md">
          {/* square-header */}
          <div className="h-12 w-full bg-top-digital-primary-main flex justify-around items-center rounded-t-md">
            <span className="text-white text-lg">Notificações</span>
          </div>
          <div className="overflow-auto h-full notification">
            {/* notification-content */}
            <Table data={data} columns={cols} />
            {/* notification-content */}
          </div>
          <div className="h-8 w-full bg-top-digital-primary-main flex justify-around items-center rounded-b-md">
            <span className="text-smp">
              <Link
                to="/notification"
                onClick={() => setNotificationsOpen(false)}
                className="text-white hover:text-top-digital-buttom-hover"
              >
                ver todas
              </Link>
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default Notification;
