type ConfirmationProps = {
  email: string;
};

const Confirmation: React.FC<ConfirmationProps> = ({ email }) => {
  return (
    <div className="flex justify-center items-start h-screen mt-10">
      <div className="w-4/5">
        <h1 className="text-center text-lg font-semibold mb-4">
          Recuperação de Senha
        </h1>
        <h2 className="text-center text-md mb-4 font-semibold">
          Verifique seu email {email}
        </h2>
        <p className="text-sm text-justify">
          <br />
          Enviamos um link de recuperação para o seu email. Você tem 24 horas
          para clicar no link e criar uma nova senha. É interessante checar a
          caixa de spam caso o email não tenha chegado. Qualquer dúvida, é só
          entrar em contato com nosso suporte através do email{' '}
          <label>
            <a
              href="mailto:suporte@seudominio.com.br"
              className="font-semibold hover:text-top-digital-link-hover hover:underline"
            >
              suporte@seudominio.com.br
            </a>
          </label>
          , informando no assunto o email utilizado na recuperação e no corpo do
          email apenas informe que o email de recuperação não chegou, pois
          iremos tomar as providências necessárias para ajudá-lo a recuperar sua
          senha.
        </p>
      </div>
    </div>
  );
};

export default Confirmation;
