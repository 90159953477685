type Props = {
  typeName: string; // Frequência em MHz como string
  isRequired: boolean;
  showRequirement: boolean;
};

const DocumentTypeFormater = ({
  typeName,
  isRequired,
  showRequirement,
}: Props) => {
  return (
    <>
      <span>{typeName}</span>
      {showRequirement && (
        <>
          {' '}
          {isRequired ? (
            <span className="text-smp text-red-600">obrigatório</span>
          ) : (
            <span className="text-smp text-blue-600">não obrigatório</span>
          )}
        </>
      )}
    </>
  );
};

export default DocumentTypeFormater;
