const formatCoordinates = (coordValue: number, coordType: string) => {
  let formattedValue = coordValue.toLocaleString('pt-BR', {
    minimumFractionDigits: 8,
    maximumFractionDigits: 8,
  });

  let direction = '';
  if (coordType === 'Latitude') {
    direction = coordValue >= 0 ? 'N' : 'S';
  } else if (coordType === 'Longitude') {
    direction = coordValue >= 0 ? 'E' : 'W';
  }

  // Combina o valor formatado com a direção sem alterar o valor numérico da coordenada
  let formatted = `${formattedValue}° ${direction}`;

  return formatted;
};

export default formatCoordinates;
