import { createSlice, Dispatch } from '@reduxjs/toolkit';
import LoginService from '../services/LoginService';
import { RootState } from '../store';
import { IForgotPassword } from '../Interfaces/IForgotPassword';

const initialState: IForgotPassword = {
  hasForgotPasswordRequested: false,
  isForgotPasswordSucceeded: false,
  isTokenValid: false,
  isCheckingToken: false,
  isSettingNewPassword: false,
  isNewPasswordSet: false,
  hasError: false,
};

export const requestForgotPassword = (email: string) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(forgotPasswordRequested());

      const res = await LoginService.sendRecovery({ email });
      if (res.isRequested) return dispatch(forgotPasswordSucceeded());

      return dispatch(forgotPasswordFailed());
    } catch (err) {
      return dispatch(forgotPasswordFailed());
    }
  };
};

export const checkToken = (tokenConfirm?: string) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(checkTokenRequested());

      const res = await LoginService.recoveryCheck(tokenConfirm);
      if (res.isValid) return dispatch(checkTokenSucceeded());

      return dispatch(checkTokenFailed());
    } catch (err) {
      return dispatch(checkTokenFailed());
    }
  };
};

export const setNewPassword = (
  password: string,
  tokenRecoveryConfirm?: string,
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setNewPasswordRequested());

      const res = await LoginService.recovery({
        tokenRecoveryConfirm,
        password,
      });
      if (res.error) return dispatch(setNewPasswordFailed(res.error));

      if (res.setPassword) return dispatch(setNewPasswordSucceeded());

      return dispatch(setNewPasswordFailed(res));
    } catch (err: any) {
      return dispatch(setNewPasswordFailed(err));
    }
  };
};

export const ForgotPasswordSlice = createSlice({
  name: 'forgotPassword',
  initialState,
  reducers: {
    reset: () => {
      return initialState;
    },
    checkTokenRequested: (state) => {
      state.isCheckingToken = true;
    },
    checkTokenSucceeded: (state) => {
      state.isCheckingToken = false;
      state.isTokenValid = true;
    },
    checkTokenFailed: (state) => {
      state.isCheckingToken = false;
      state.isTokenValid = false;
    },
    setNewPasswordRequested: (state) => {
      state.isSettingNewPassword = true;
    },
    setNewPasswordSucceeded: (state) => {
      state.isSettingNewPassword = false;
      state.isNewPasswordSet = true;
    },
    setNewPasswordFailed: (state, payload: any) => {
      state.isSettingNewPassword = false;
      state.isNewPasswordSet = false;
      state.hasError = true;
      state.errorMessage = payload.error;
    },
    forgotPasswordRequested: (state) => {
      state.hasForgotPasswordRequested = true;
      state.isForgotPasswordSucceeded = false;
    },
    forgotPasswordSucceeded: (state) => {
      state.hasForgotPasswordRequested = false;
      state.isForgotPasswordSucceeded = true;
    },
    forgotPasswordFailed: (state) => {
      state.hasForgotPasswordRequested = false;
      state.isForgotPasswordSucceeded = false;
    },
  },
});

export const {
  reset,
  checkTokenRequested,
  checkTokenSucceeded,
  checkTokenFailed,
  setNewPasswordRequested,
  setNewPasswordSucceeded,
  setNewPasswordFailed,
  forgotPasswordRequested,
  forgotPasswordSucceeded,
  forgotPasswordFailed,
} = ForgotPasswordSlice.actions;
export const forgotPasswordSelector = (state: RootState) =>
  state.ForgotPasswordReducer;
export default ForgotPasswordSlice.reducer;
