import React, { useEffect, useState } from 'react';

//context
import { useGlobalContext } from '../../../hooks/useGlobalContext';

//redux
import useAppDispatch from '../../../hooks/useAppDispatch';
import useAppSelector from '../../../hooks/useAppSelector';
import {
  documentSelector,
  downloadDoc,
  deleteDoc,
  reset,
} from '../../../slices/DocumentSlice';
import { systemSelector } from '../../../slices/SystemSlice';

//components
import DocIcon from '../../Utils/DocIcon';
import DateFormat from '../../Utils/DateFormat';

//icons
import { Download, Trash } from 'lucide-react';

//types
import { TDocument } from '../../../Interfaces/IDocument';
import { DeleteDocumentModal } from '../DeleteDocumentModal';
import SystemActionLinkDoc from '../../../pages/System/SystemActionLinkDoc';
import { TSystem } from '../../../Interfaces/ISystem';

//user
import { useAuth } from '../../../hooks/useAuth';

type Props = {
  stationId?: string;
  systemId?: string;
  docTypeId?: string;
  rowId?: string;
};

const DocumentListReportDocs = ({
  stationId,
  systemId,
  docTypeId,
  rowId,
}: Props) => {
  //tipo de usuário
  const { role } = useAuth().userData;
  //context
  const { dispatchGlobalState } = useGlobalContext();
  const [deleteDocument, setDeleteDocument] = useState<boolean>(false);
  const [document, setDocument] = useState<TDocument | null>(null);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [documentSystem, setDocumentSystem] = useState<TSystem | null>(null);
  //redux
  const dispatchDocument = useAppDispatch();
  const { documents, deleteSuccess } = useAppSelector(documentSelector);
  const { system } = useAppSelector(systemSelector);

  const submitDownload = (
    e: React.MouseEvent<HTMLDivElement>,
    doc: TDocument,
  ) => {
    e.preventDefault();
    dispatchDocument(downloadDoc(doc));
  };

  const warningDelete = (
    e: React.MouseEvent<HTMLDivElement>,
    doc: TDocument,
  ): any => {
    e.preventDefault();
    setDocument(doc);
    dispatchGlobalState({
      type: 'CLOSE_SYSTEM_LINK_FORM',
      idDocument: doc!._id,
    });
    setIsModalOpen(true);
  };

  console.log(documents);

  useEffect(() => {
    if (systemId === system?._id) {
      setDocumentSystem(system);
    }
  }, [system, systemId]);

  useEffect(() => {
    const submitDelete = (doc: TDocument) => {
      dispatchDocument(deleteDoc(doc._id));
    };

    if (deleteDocument) {
      if (document !== null) {
        submitDelete(document);
      }
      dispatchDocument(reset());
    }
  }, [deleteDocument, document, dispatchDocument]);

  useEffect(() => {
    if (deleteSuccess) {
      setDeleteDocument(false);
    }
  }, [deleteSuccess, dispatchGlobalState, document]);

  return (
    <>
      {isModalOpen && (
        <DeleteDocumentModal
          doc={document}
          setDeleteDocument={setDeleteDocument}
          setIsModalOpen={setIsModalOpen}
          isModalOpen={isModalOpen}
        />
      )}
      {documents && documents.length !== 0 && (
        <div className="border-b-[15px] border-top-digital-secondary-lighter">
          <table className="divide-y divide-gray-300 border">
            <thead>
              <tr className="bg-top-digital-secondary-main">
                {systemId !== '' && (
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-black font-top-digital-content text-smp font-medium sm:pl-0"
                  >
                    Vincular
                  </th>
                )}
                <th
                  scope="col"
                  className="py-3.5 pl-4 pr-3 text-left text-black font-top-digital-content text-smp font-medium sm:pl-0"
                >
                  Documento
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-black font-top-digital-content text-smp font-medium whitespace-nowrap"
                >
                  Criado por
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-black font-top-digital-content text-smp font-medium whitespace-nowrap"
                >
                  Sistemas vinculados
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-black font-top-digital-content text-smp font-medium"
                >
                  Tipo
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-black font-top-digital-content text-smp font-medium"
                >
                  Prazo de Validade
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-black font-top-digital-content text-smp font-medium"
                >
                  Extensão
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-black font-top-digital-content text-smp font-medium whitespace-nowrap"
                >
                  Data Criação
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-black font-top-digital-content text-smp font-medium"
                >
                  Download
                </th>
                {role !== 'visualizador' && (
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-black font-top-digital-content text-smp font-medium"
                  >
                    Remover
                  </th>
                )}
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {documents &&
                documents.map((doc, index) => (
                  <tr
                    key={index}
                    className={`${
                      !(index % 2)
                        ? 'bg-white'
                        : 'bg-top-digital-secondary-light'
                    }  text-top-digital-secondary-darker font-normal font-top-digital-content text-smp p-1`}
                  >
                    {systemId !== '' && (
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-smp sm:pl-0">
                        <div className="flex gap-2">
                          {documentSystem && (
                            <SystemActionLinkDoc
                              key={`link-${rowId && '-' + rowId}${documentSystem._id}-${doc._id}`}
                              document={doc._id}
                              system={documentSystem._id || ''}
                              // linked={documentSystem.documents!.includes(doc._id)}
                              linked={
                                systemId !== undefined &&
                                doc.systems!.includes(systemId)
                              }
                              rowId={rowId}
                            />
                          )}
                        </div>
                      </td>
                    )}

                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-smp top-digital-content-color sm:pl-0">
                      {doc.title}
                    </td>
                    <td className="px-3 py-3.5 whitespace-nowrap pl-4 pr-3 text-smp top-digital-content-color sm:pl-3.5">
                      {doc.createdBy}
                    </td>
                    <td className="px-3 py-3.5 whitespace-nowrap pl-4 pr-3 text-smp top-digital-content-color sm:pl-3.5">
                      {doc.systems && doc.systems.length !== 0
                        ? doc.systems.length
                        : '0'}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-smp text-gray-500">
                      <div className="flex gar-2">
                        {doc.toSystem ? (
                          <span>{doc.typeName || '-'}</span>
                        ) : (
                          <span>{doc.typeName || '-'}</span>
                        )}
                      </div>
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-smp text-gray-500">
                      <div className="flex gar-2">
                        {doc.expirationDate !== null ? (
                          <span>
                            {new Date(doc.expirationDate).toLocaleDateString(
                              'pt-BR',
                            )}
                          </span>
                        ) : (
                          <span>-</span>
                        )}
                      </div>
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-smp text-gray-500">
                      <DocIcon extension={doc.extension} />
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-smp text-gray-500">
                      <DateFormat data={doc.createdAt} />
                    </td>
                    <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-smp sm:pr-0">
                      <div
                        className={`${
                          !(index % 2)
                            ? 'bg-white'
                            : 'bg-top-digital-secondary-light'
                        }  flex justify-center items-center p-1 cursor-pointer`}
                        onClick={(e) => submitDownload(e, doc)}
                        title="Download"
                      >
                        <Download className="w-5 h-5" />
                      </div>
                    </td>
                    {role !== 'visualizador' && (
                      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-smp sm:pr-0">
                        <div
                          className={`${
                            !(index % 2)
                              ? 'bg-white'
                              : 'bg-top-digital-secondary-light'
                          } text-top-digital-error-main flex justify-center items-center p-1 cursor-pointer`}
                          onClick={(e) => warningDelete(e, doc)}
                          title="Remover documento"
                        >
                          <Trash className="w-5 h-5" />
                        </div>
                      </td>
                    )}
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};

export default DocumentListReportDocs;
