import { useState, MouseEvent, useEffect, ChangeEvent } from 'react';
import useAppDispatch from '../../hooks/useAppDispatch';
import useAppSelector from '../../hooks/useAppSelector';
import Filter from '../../components/Filter/Filter';

//icons
import { Edit } from 'lucide-react';

//redux
import {
  reset,
  setConfig,
  configSelector,
  alterMobileStations,
} from '../../slices/ConfigStationSlice';

//types
import { TConfigStation } from '../../Interfaces/IConfigStation';
import FilterView from '../../components/Filter/FilterView';
import { TProfile } from '../../Interfaces/IProfileStates';

//user
import { useAuth } from '../../hooks/useAuth';

type Props = {
  childUser?: TProfile | null;
  setConfigChildUser?: React.Dispatch<React.SetStateAction<boolean>>;
};

const TableStationConfig = ({ childUser, setConfigChildUser }: Props) => {
  //tipo de usuário
  const { role } = useAuth().userData;

  const dispatch = useAppDispatch();
  const { config, objInit, loading, success } = useAppSelector(configSelector);

  const [isEditing, setIsEditing] = useState(false);
  const [filterForm, setFilterForm] = useState(false);
  const [showMobileStations, setShowMobileStations] = useState(
    objInit.hideMobileStations,
  );
  const [editForm, setEditForm] = useState<TConfigStation>({
    _id: '',
    campo: '',
    label: '',
    order: 0,
    visible: true,
    filterable: false,
    filtertype: '',
    filtervalue: '',
    positionFixed: 0,
  });

  const handleEditForm = (
    e: MouseEvent<SVGSVGElement, globalThis.MouseEvent>,
    config: TConfigStation,
  ) => {
    window.scrollTo(0, 0);
    e.preventDefault();
    setIsEditing(true);

    if (config.filtervalue !== '') {
      setFilterForm(true);
    }

    if (config.filtertype === 'selecao') {
      setEditForm({
        ...config,
        filtervalue:
          config.filtervalue !== ''
            ? (JSON.parse(config.filtervalue as string) as any[])
            : ([] as any[]),
      });
    } else {
      setEditForm({ ...config });
    }
  };

  const handleBackChildUsers = () => {
    if (setConfigChildUser) {
      setConfigChildUser(false);
    }
  };

  const handleCancelEdit = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setEditForm({
      _id: '',
      campo: '',
      label: '',
      order: 0,
      visible: true,
      filterable: false,
      filtertype: '',
      filtervalue: '',
      positionFixed: 0,
    });
    setIsEditing(false);
    setFilterForm(false);
  };

  const handleFilterForm = (
    e: ChangeEvent<HTMLInputElement>,
    config: TConfigStation,
  ) => {
    setFilterForm(e.target.checked);
    if (!e.target.checked) {
      setEditForm({
        ...config,
        filtervalue: '',
      });
    }
  };

  const handleMobileStations = (e: ChangeEvent<HTMLInputElement>) => {
    setShowMobileStations(e.target.checked);
    const objData = {
      hideMobileStations: e.target.checked,
      childUser: childUser ? childUser._id : '',
    };

    dispatch(alterMobileStations(objData));
  };

  const handleSaveEdit = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    if (
      typeof editForm.filtervalue !== 'string' &&
      editForm.filtervalue.length === 0
    ) {
      dispatch(setConfig({ ...editForm, filtervalue: '' }));
    } else {
      //remove localstorage after change config
      localStorage.removeItem('lsStationPage');
      const coordsValue = localStorage.getItem('coords');
      if (coordsValue) {
        localStorage.setItem('coordstmp', coordsValue);
      }
      localStorage.removeItem('coords');
      dispatch(setConfig(editForm));
    }
  };

  useEffect(() => {
    if (success) {
      setEditForm({
        _id: '',
        campo: '',
        label: '',
        order: 0,
        visible: true,
        filterable: false,
        filtertype: '',
        filtervalue: '',
        positionFixed: 0,
      });
      setIsEditing(false);
    }
    dispatch(reset());
  }, [success, dispatch]);

  return (
    <div className="px-4 sm:px-6 lg:px-8 mt-4">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-sm font-semibold leading-6 top-digital-content-color">
            Configure sua visualização de Estações{' '}
            {childUser && `de ${childUser.name}`}
          </h1>
          <p className="mt-2 text-smp text-gray-700">
            Você poderá definir outros nomes para os campos de uma estação, além
            de definir a ordem de exibição e se o campo deve ser exibido ou não.
          </p>
        </div>
        {setConfigChildUser && (
          <div>
            <button className="customButton" onClick={handleBackChildUsers}>
              Voltar
            </button>
          </div>
        )}
      </div>

      {loading ? (
        <p>Aguarde....</p>
      ) : (
        <div>
          {isEditing && (
            <div className="mt-4">
              <form className="w-[80%] md:w-[50%]">
                <label
                  htmlFor="email"
                  className="block text-smp font-medium leading-6 top-digital-content-color"
                >
                  Campo: {editForm.campo}
                </label>
                <div className="mt-2 mb-4"> </div>
                <label
                  htmlFor="label"
                  className="block text-smp font-medium leading-6 top-digital-content-color"
                >
                  Apelido
                </label>
                <div className="mt-2 mb-4">
                  <input
                    type="text"
                    value={editForm.label}
                    onChange={(e) =>
                      setEditForm({
                        ...editForm,
                        [e.target.name]: e.target.value,
                      })
                    }
                    name="label"
                    id="label"
                    className="customInputText"
                  />
                </div>
                {editForm.filterable && (
                  <>
                    <div className="relative flex items-start mb-2">
                      <div className="flex h-6 items-center">
                        <input
                          aria-describedby="toSystem-description"
                          type="checkbox"
                          checked={filterForm}
                          onChange={(e) => {
                            handleFilterForm(e, editForm);
                          }}
                          name="filterform"
                          id="filterform"
                          className="customInputRadio"
                        />
                      </div>
                      <div className="relative flex items-start">
                        <div className="ml-3 text-smp leading-6">
                          <label
                            htmlFor="toStation"
                            className="font-medium top-digital-content-color"
                          >
                            Criar Filtro
                          </label>
                          <p
                            id="toStation-description"
                            className="text-gray-500 text-xs"
                          >
                            Marque essa opção se você desejar filtrar apenas as
                            Estações que deseja visualizar.
                          </p>
                        </div>
                      </div>
                    </div>
                    {filterForm && (
                      <div>
                        <Filter
                          editForm={editForm}
                          setEditForm={setEditForm}
                          objInit={objInit}
                          setFilterForm={setFilterForm}
                        />
                      </div>
                    )}
                  </>
                )}
                <>
                  {editForm.positionFixed === 100 && (
                    <div>
                      <label
                        htmlFor="order"
                        className="block text-smp font-medium leading-6 top-digital-content-color"
                      >
                        Ordem
                      </label>
                      <div className="mt-2 mb-4">
                        <select
                          id="order"
                          name="order"
                          value={editForm.order}
                          className="customInputText"
                          onChange={(e) =>
                            setEditForm({
                              ...editForm,
                              [e.target.name]: e.target.value,
                            })
                          }
                        >
                          {Array.from({ length: config.length }, (_, i) => (
                            <option value={i + 1} key={i + 1}>
                              {i + 1}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  )}
                </>
                <fieldset>
                  <div className="space-y-5">
                    <div className="relative flex items-start">
                      <div className="flex h-6 items-center">
                        <input
                          aria-describedby="toSystem-description"
                          type="checkbox"
                          checked={editForm.visible}
                          onChange={() => {
                            setEditForm({
                              ...editForm,
                              visible: !editForm.visible,
                            });
                          }}
                          name="toSystem"
                          id="toSystem"
                          className="customInputRadio"
                        />
                      </div>

                      <div className="relative flex items-start">
                        <div className="ml-3 text-smp leading-6">
                          <label
                            htmlFor="toStation"
                            className="font-medium top-digital-content-color"
                          >
                            Exibir
                          </label>
                          <p
                            id="toStation-description"
                            className="text-gray-500 text-xs"
                          >
                            Marque essa opção se o documento estiver relacionado
                            a uma estação.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </fieldset>
                <div className="mt-4">
                  <div className="flex gap-1">
                    <button
                      type="submit"
                      className="block rounded-md bg-top-digital  hover:text-top-digital-buttom-hover px-3 py-2 text-center text-smp font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                      onClick={handleSaveEdit}
                    >
                      Enviar
                    </button>
                    <button
                      className="block rounded-md bg-top-digital  hover:text-top-digital-buttom-hover px-3 py-2 text-center text-smp font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                      type="button"
                      onClick={handleCancelEdit}
                    >
                      Cancelar
                    </button>
                  </div>
                </div>
              </form>
            </div>
          )}
          {config && config.length !== 0 && (
            <div className="mt-8 flow-root">
              <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                  {role && (role === 'admin' || role === 'superadmin') && (
                    <div className="flex mb-1 items-center">
                      <input
                        type="checkbox"
                        onChange={handleMobileStations}
                        checked={showMobileStations}
                        className="customInputCheckbox"
                      />{' '}
                      <label
                        className="ml-2 text-left text-smp font-normal top-digital-content-color"
                        htmlFor="checkbox classes ML"
                      >
                        Ocultar a visualização de estações móveis - classe ML
                      </label>
                    </div>
                  )}
                  <table className="min-w-full divide-y divide-gray-300 border">
                    <thead>
                      <tr className="bg-top-digital-op-40">
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-smp font-semibold top-digital-content-color sm:pl-0"
                        >
                          Campo
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-smp font-semibold top-digital-content-color"
                        >
                          Apelido
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-smp font-semibold top-digital-content-color"
                        >
                          Filtro
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-smp font-semibold top-digital-content-color"
                        >
                          Ordem
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-smp font-semibold top-digital-content-color"
                        >
                          Exibir
                        </th>
                        {role &&
                          (role === 'admin' || role === 'superadmin') && (
                            <th
                              scope="col"
                              className="relative py-3.5 pl-3 pr-4 sm:pr-0"
                            >
                              <span className="sr-only">Editar</span>
                            </th>
                          )}
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                      <>
                        {config &&
                          config.length !== 0 &&
                          config.map((conf, index) => (
                            <tr
                              key={index}
                              className={`${
                                !(index % 2)
                                  ? 'bg-white'
                                  : 'bg-top-digital-op-25'
                              } text-top-digital-content-color p-1`}
                            >
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-smp font-medium top-digital-content-color sm:pl-0">
                                {conf.campo}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-smp text-gray-500">
                                {conf.label}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-smp text-gray-500">
                                <FilterView config={conf} />
                              </td>

                              <td className="whitespace-nowrap px-3 py-4 text-smp text-gray-500">
                                {conf.positionFixed &&
                                conf.positionFixed > 0 &&
                                conf.positionFixed < 100
                                  ? 'Fixa'
                                  : conf.order}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-smp text-gray-500">
                                {conf.visible ? 'Sim' : 'Não'}
                              </td>
                              {role &&
                                (role === 'admin' || role === 'superadmin') && (
                                  <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-smp font-medium sm:pr-0">
                                    <div
                                      className={`${
                                        !(index % 2)
                                          ? 'bg-white'
                                          : 'bg-top-digital-op-25'
                                      } text-top-digital-content-color p-1`}
                                    >
                                      <Edit
                                        className="cursor-pointer"
                                        onClick={(e) => {
                                          handleEditForm(e, conf);
                                        }}
                                      />
                                    </div>{' '}
                                  </td>
                                )}
                            </tr>
                          ))}
                      </>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default TableStationConfig;
