import React from 'react';
import classNames from '../../utils/classNames';

type ButtonProps = {
  onClick?: () => void;
  className?: string;
  children: React.ReactNode;
  type?: 'button' | 'submit' | 'reset';
  disabled?: boolean;
};

const Button: React.FC<ButtonProps> = ({
  onClick,
  className,
  children,
  type = 'button',
  disabled = false,
}) => (
  <button
    type={type}
    onClick={onClick}
    disabled={disabled}
    className={classNames(
      'flex w-full justify-center rounded-md bg-top-digital px-3 py-1.5 font-semibold leading-6 text-white shadow-sm hover:text-top-digital-buttom-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600',
      className,
    )}
  >
    {children}
  </button>
);

export default Button;
