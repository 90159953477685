import React, { useMemo } from 'react';
import { ColumnDef } from '@tanstack/react-table';
import useAppSelector from '../../hooks/useAppSelector';
import { networkFilesSelector } from '../../slices/NetworkFilesSlice';
import DateFormat from '../Utils/DateFormat';
import DownloadDocument from './DownloadDocument';
import DeleteDocument from './DeleteDocument';
import PolylinesStationsFileTable from './PolylinesStationsFileTable';
import { NetworkFile } from '../../Interfaces/INetworkFile';
import { useAuth } from '../../hooks/useAuth';

const PolylinesStationsFileList: React.FC = () => {
  const { role } = useAuth().userData;
  const { networkFiles } = useAppSelector(networkFilesSelector);

  const columns = useMemo<ColumnDef<NetworkFile>[]>(
    () => [
      {
        accessorKey: 'originalName',
        header: 'Nome',
        filterFn: 'equals',
      },
      {
        accessorKey: 'size',
        header: 'Tamanho',
        cell: ({ row }) => (
          <span>{(row.original.size / 1024 / 1024).toFixed(2)} MB</span>
        ),
        filterFn: 'equals',
      },
      {
        accessorKey: 'createdAt',
        header: 'Data de Upload',
        cell: ({ row }) => <DateFormat data={row.original.createdAt} />,
        filterFn: 'equals',
      },
      {
        accessorKey: 'download',
        header: 'Download',
        cell: ({ row }) => <DownloadDocument doc={row.original} />,
        filterFn: 'equals',
      },
      ...(role !== 'visualizador'
        ? [
            {
              accessorKey: 'deleteDocument',
              header: 'Remover',
              cell: (row: any) => {
                return <DeleteDocument doc={row.row.original} />;
              },
            },
          ]
        : []),
    ],
    [role],
  );

  return <PolylinesStationsFileTable data={networkFiles} columns={columns} />;
};

export default PolylinesStationsFileList;
