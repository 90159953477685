import { useEffect, useState } from 'react';
import { TDocumentSmall } from '../../Interfaces/IDocument';
import DocIcon from '../Utils/DocIcon';
import DownloadDocument from './DownloadDocument';
import DeleteDocument from './DeleteDocument';
import { TSystem } from '../../Interfaces/ISystem';
import useAppSelector from '../../hooks/useAppSelector';
import { systemSelector } from '../../slices/SystemSlice';
import SystemActionLinkDoc from '../../pages/System/SystemActionLinkDoc';

type Props = {
  document: {
    _id: string;
    file: string;
    title: string;
    extension: string;
    expirationDate: Date | null;
    typeName: string;
    toSystem: boolean;
    toStation: boolean;
    createdBy: string;
    createdAt: Date;
    systems: string[];
  };
  systemId: string | undefined;
  rowId: string | undefined;
};

const DocumentDetail = ({ document, systemId, rowId }: Props) => {
  const [documentSystem, setDocumentSystem] = useState<TSystem | null>(null);
  const { system } = useAppSelector(systemSelector);

  useEffect(() => {
    if (systemId === system?._id) {
      setDocumentSystem(system);
    }
  }, [system, systemId]);

  const docInfo = [
    {
      header: 'Documento',
      value: document.title,
    },
    {
      header: 'Criado por',
      value: document.createdBy,
    },
    {
      header: 'Sistemas Vinculados',
      value: document.systems.length,
    },
    {
      header: 'Tipo de Documento',
      value: document.typeName,
    },
    {
      header: 'Estação | Sistema',
      value: document.toStation ? 'Estação' : 'Sistema',
    },
    {
      header: 'Extensão',
      value: document.extension,
    },
    {
      header: 'Prazo de Validade',
      value: document.expirationDate
        ? new Date(document.expirationDate).toLocaleDateString('pt-BR')
        : '-',
    },
    {
      header: 'Data Criação',
      value: document.createdAt
        ? new Date(document.createdAt).toLocaleDateString('pt-BR')
        : '-',
    },

    {
      header: 'Download',
      value: '',
    },
    {
      header: 'Excluir',
      value: '',
    },
  ];

  const docDownload: TDocumentSmall = {
    _id: document._id,
    title: document.title,
    extension: document.extension,
    systems: document.systems,
  };

  return (
    <div className="overflow-auto grid grid-cols-2">
      {systemId !== '' && (
        <>
          <div className="flex gap-2 whitespace-nowrap py-4 pl-4 pr-3 text-smp sm:pl-0">
            {documentSystem && (
              <SystemActionLinkDoc
                document={document._id}
                system={documentSystem._id || ''}
                linked={documentSystem.documents!.includes(document._id)}
                rowId={rowId}
              />
            )}
          </div>
          <div></div>
        </>
      )}

      {docInfo.map((doc, index) => (
        <>
          <div
            className={`${
              index % 2 ? 'bg-white' : 'bg-top-digital-secondary-light'
            }  text-black font-top-digital-content text-smp font-medium leading-6 flex items-center p-2`}
          >
            {doc.header}
          </div>
          <div
            className={`${
              index % 2 ? 'bg-white' : 'bg-top-digital-secondary-light'
            } text-top-digital-secondary-darker font-normal font-top-digital-content text-smp flex items-center p-2`}
          >
            {doc.header === 'Extensão' ? (
              <DocIcon extension={doc.value.toString()} />
            ) : doc.header === 'Download' ? (
              <DownloadDocument doc={docDownload} />
            ) : doc.header === 'Excluir' ? (
              <DeleteDocument doc={docDownload} />
            ) : (
              <>{doc.value}</>
            )}
          </div>
        </>
      ))}
    </div>
  );
};

export default DocumentDetail;
