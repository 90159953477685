import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunk } from '@reduxjs/toolkit';
import DashBoardService from '../services/DashBoardService';
import { RootState } from '../store';

//interface of equipments
import { IDashStates } from '../Interfaces/IDashData';

const initialState: IDashStates = {
  dashURL: '',
  dasherror: false,
  dashsuccess: false,
  dashloading: false,
  dashmessage: '',
  exporterror: false,
  exportsuccess: false,
  exportloading: false,
  exportmessage: '',
};

//get address data by CEP
export const generateMetabaseURL = createAsyncThunk(
  'dashboard/generateMetabaseURL',
  async (report: string, thunkAPI) => {
    const appState = thunkAPI.getState() as RootState;
    const token = appState.LoginReducer.user!;

    const res = await DashBoardService.generateMetabaseURL(token, report);
    //check for errors

    if (res.error) {
      return thunkAPI.rejectWithValue(res.error);
    }

    return res;
  },
);

export const exportExcel = createAsyncThunk(
  'dashboard/exportExcel',
  async (dashURL: { url: string; report: string }, thunkAPI) => {
    const appState = thunkAPI.getState() as RootState;
    const token = appState.LoginReducer.user!;

    const res = await DashBoardService.exportExcel(dashURL, token);
    //check for errors

    if (res.error) {
      return thunkAPI.rejectWithValue(res.error);
    }

    return res;
  },
);

export const DashBoardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    resetDashboard: (state) => {
      state.dasherror = false;
      state.dashloading = false;
      state.dashsuccess = false;
      state.dashmessage = '';
      state.dashURL = '';
      state.exporterror = false;
      state.exportloading = false;
      state.exportsuccess = false;
      state.exportmessage = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(generateMetabaseURL.fulfilled, (state, action) => {
        state.dasherror = false;
        state.dashloading = false;
        state.dashsuccess = true;
        state.dashURL = action.payload;
      })
      .addCase(generateMetabaseURL.pending, (state) => {
        state.dasherror = false;
        state.dashloading = true;
        state.dashsuccess = false;
      })
      .addCase(generateMetabaseURL.rejected, (state, action) => {
        state.dasherror = true;
        state.dashloading = false;
        state.dashsuccess = false;
        state.dashURL = '';
        state.dashmessage =
          typeof action.payload === 'string' ? action.payload : '';
      })
      .addCase(exportExcel.fulfilled, (state, action) => {
        state.exporterror = false;
        state.exportloading = false;
        state.exportsuccess = true;
      })
      .addCase(exportExcel.pending, (state) => {
        state.exporterror = false;
        state.exportloading = true;
        state.exportsuccess = false;
      })
      .addCase(exportExcel.rejected, (state, action) => {
        state.exporterror = true;
        state.exportloading = false;
        state.exportsuccess = false;
        state.exportmessage = 'Erro ao exportar o arquivo';
      });
  },
});

export const { resetDashboard } = DashBoardSlice.actions;
export const dashboardSelector = (state: RootState) => state.DashBoardReducer;
export default DashBoardSlice.reducer;
