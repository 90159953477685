import { useEffect } from 'react';
import useAppDispatch from '../../hooks/useAppDispatch';
import useAppSelector from '../../hooks/useAppSelector';

import {
  dashboardSelector,
  generateMetabaseURL,
  exportExcel,
} from '../../slices/DashBoardSlice';
import ExportStates from './ExportStates';

type Props = {
  report: string;
};

const Dashboard = ({ report }: Props) => {
  const dispatch = useAppDispatch();
  const { dashURL, dashloading, exportloading, exporterror } =
    useAppSelector(dashboardSelector);

  useEffect(() => {
    dispatch(generateMetabaseURL(report));
  }, [dispatch, report]);

  const exportPDF = () => {
    const objUrl = {
      url: dashURL,
      report,
    };
    dispatch(exportExcel(objUrl));
  };

  console.log(exporterror);

  if (dashloading) {
    return <div>Carregando...</div>;
  }

  return (
    <>
      <div className="flex flex-col">
        <div onClick={exportPDF} className="cursor-pointer mt-2 mb-4">
          <ExportStates
            exportloading={exportloading}
            exporterror={exporterror}
          />
        </div>
        <div>
          {dashURL && (
            <iframe
              src={dashURL}
              title="Metabase Dashboard"
              width="100%"
              height="800px"
            ></iframe>
          )}
        </div>
      </div>
    </>
  );
};

export default Dashboard;
