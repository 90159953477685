import React, { useState, useEffect } from 'react';
import { Polyline, Marker } from '@react-google-maps/api';
import { Network } from '../../Interfaces/ILocation';
import PolylinesStations from './PolylinesStations';
import { Provider } from 'react-redux';
import { store } from '../../store';
import { createRoot } from 'react-dom/client';
import * as turf from '@turf/turf'; // Importa o Turf para calcular a distância

type NetworkProps = {
  networks: Network[];
  map: google.maps.Map | null;
};

const NetworkComponent: React.FC<NetworkProps> = ({ networks, map }) => {
  const [_, setInfoWindowContainer] = useState<HTMLElement | null>(null);

  useEffect(() => {
    const container = document.createElement('div');
    setInfoWindowContainer(container);
  }, []);

  return (
    <>
      {networks.map((network) => {
        // Calcular a distância entre TX e RX usando turf
        const pointTX = turf.point([network.longitudeTX, network.latitudeTX]);
        const pointRX = turf.point([network.longitudeRX, network.latitudeRX]);
        const distance = parseFloat(
          turf.distance(pointTX, pointRX, 'kilometers').toFixed(2),
        );

        // Calcula o ponto médio da linha
        const midLat = (network.latitudeTX + network.latitudeRX) / 2;
        const midLng = (network.longitudeTX + network.longitudeRX) / 2;

        return (
          <React.Fragment key={`network-${network._id}`}>
            {/* Linha principal */}
            <Polyline
              path={[
                { lat: network.latitudeTX, lng: network.longitudeTX },
                { lat: network.latitudeRX, lng: network.longitudeRX },
              ]}
              options={{
                strokeColor: '#1890FF',
                strokeOpacity: 0.8,
                strokeWeight: 2,
                clickable: true,
                zIndex: 1,
              }}
            />

            {/* Texto sobre a linha no ponto médio */}
            <Marker
              position={{ lat: midLat, lng: midLng }}
              label={
                map && map.getZoom() !== undefined && Number(map.getZoom()) > 13 // Substitua "10" pelo nível de zoom desejado
                  ? {
                      text: `Rede ${network.network} - ${distance} Km`,
                      color: '#4A858C', // Cor do texto
                      fontSize: '10px',
                      fontWeight: 'bold',
                    }
                  : undefined // Não exibe o label em zoom menor
              }
              icon={{
                path: google.maps.SymbolPath.CIRCLE,
                scale: 1, // Ícone invisível
                strokeColor: '#1E3538', // Cor do círculo
              }}
            />

            {/* Linha invisível para ativar o InfoWindow ao clicar */}
            <Polyline
              path={[
                { lat: network.latitudeTX, lng: network.longitudeTX },
                { lat: network.latitudeRX, lng: network.longitudeRX },
              ]}
              options={{
                strokeColor: 'transparent',
                strokeOpacity: 0,
                strokeWeight: 10,
                clickable: true,
                zIndex: 2,
              }}
              onClick={(e) => {
                if (e.latLng) {
                  const markerPosition = {
                    lat: e.latLng.lat(),
                    lng: e.latLng.lng(),
                  };
                  const marker = new window.google.maps.Marker({
                    position: markerPosition,
                    map: map,
                    icon: {
                      path: window.google.maps.SymbolPath.CIRCLE,
                      scale: 0,
                    },
                  });

                  // Criando um novo `div` para cada InfoWindow
                  const container = document.createElement('div');
                  const root = createRoot(container);

                  root.render(
                    <Provider store={store}>
                      <PolylinesStations
                        channels={network.arrIdAnatel}
                        network={network.network}
                        distance={distance} // Incluindo a distância calculada
                      />
                    </Provider>,
                  );

                  const infoWindow = new window.google.maps.InfoWindow({
                    content: container,
                    position: markerPosition,
                  });

                  infoWindow.open(map, marker);
                }
              }}
            />
          </React.Fragment>
        );
      })}
    </>
  );
};

export default NetworkComponent;
