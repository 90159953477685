import { useLocation } from 'react-router-dom';

type ImageMainProps = {
  register?: boolean;
};

const ImageMain = ({ register }: ImageMainProps) => {
  const location = useLocation();
  const showTrial = location.pathname === '/register' && register;

  let imageUrl = '/img/img-top-1536x1536.png'; // Imagem padrão
  if (location.pathname === '/register') {
    imageUrl = '/img/img-top-cadastro-1536x1536.png'; // Imagem para a rota /register
  }

  return (
    <div className="hidden py-2 md:flex md:flex-col mt-1 w-1/2 md:items-start md:justify-stretch max-w-[600px]">
      {showTrial && (
        <div className="mb-16 bg-top-digital-warning-light rounded-2xl pt-4 pb-8 px-4">
          <h2 className="text-xl font-bold tracking-tight text-gray-900 sm:text-xl">
            Pronto para Agilizar?
          </h2>
          <h3 className="text-base font-bold tracking-tight text-gray-900 sm:text-lg justify-start">
            <span>Inicie seu teste grátis</span>
            <span className="hidden md:inline"> agora</span>
          </h3>
          <div className="text-top-digital-content-color">
            <p className="font-semibold md:text-sm text-base">
              20 dias grátis para conhecer o Agiliza.
            </p>
            <p className="md:text-smp font-medium text-sm md:mt-2 text-justify h-4">
              Descubra como o Agiliza pode transformar o seu negócio. Sem cartão
              de crédito, sem complicações.
            </p>
          </div>
        </div>
      )}
      <img src={imageUrl} alt="Um produto Top Digital" />
    </div>
  );
};

export default ImageMain;
