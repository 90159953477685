import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Body from './components/Body/Body';
import { GlobalContextProvider } from './contexts/GlobalContext';
import RoutesWrapper from './components/Routes/Routes';
import 'react-toastify/dist/ReactToastify.css';
import Main from './components/Main/Main';
import AcceptCookies from './components/AcceptCookies/AcceptCookies';

const App = () => {
  return (
    <GlobalContextProvider>
      <BrowserRouter>
        <Body>
          <Header />
          <Main>
            <RoutesWrapper />
          </Main>
          <Footer />
          <ToastContainer />
          <AcceptCookies />
        </Body>
      </BrowserRouter>
    </GlobalContextProvider>
  );
};

export default App;
