// FilterValueDisplay.tsx

import React from 'react';
import { TConfigStation } from '../../Interfaces/IConfigStation';
import { TConfigSystem } from '../../Interfaces/IConfigSystem';

type Props = {
  config: TConfigStation | TConfigSystem;
};
//const FilterView = ({ config }: Props) => {
const FilterValueDisplay = ({ config }: Props) => {
  if (!config.filtervalue || config.filtervalue.length === 0) {
    return null;
  }

  const parsedFilterValue = JSON.parse(config.filtervalue as string);

  let displayField: string;
  switch (config.campo) {
    case 'NumServico':
      displayField = 'servico';
      break;
    case 'CodMunicipio':
      displayField = 'nomMunicipio';
      break;
    case 'SiglaUf':
      displayField = 'nome';
      break;
    case 'CodTiposClasseEstacao':
      displayField = 'classe';
      break;
    case 'Label':
      displayField = 'valor';
      break;
    case 'NomeEntidade':
      displayField = 'valor';
      break;
    case 'CNPJ':
      displayField = 'valor';
      break;
    case 'NumFistel':
      displayField = 'valor';
      break;
    case 'NumEstacao':
      displayField = 'valor';
      break;
    default:
      return null;
  }

  return (
    <div className="flex flex-col font-semibold">
      {parsedFilterValue.map((item: any, index: number) => (
        <div key={index}>{item[displayField]}</div>
      ))}
    </div>
  );
};

export default FilterValueDisplay;
