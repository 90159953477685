import CookieConsent from 'react-cookie-consent';

type Props = {};

const AcceptCookies = (props: Props) => {
  return (
    <CookieConsent
      debug={false}
      disableStyles={true} // Desativa os estilos padrões para que possamos usar o Tailwind
      location="bottom"
      buttonText="Entendi!"
      cookieName="myAwesomeCookieName2"
      containerClasses="fixed bottom-0 inset-x-0 bg-top-digital-primary-lighter text-center p-4" // Posiciona fixo no rodapé com um fundo amarelo e padding
      contentClasses="top-digital-content-color text-base"
      buttonClasses="agiliza-button-selected-2 text-white font-bold py-2 px-4 rounded"
      expires={150}
    >
      <div className="mb-2">
        O Agiliza utiliza cookies para garantir que você tenha a melhor
        experiência. Ao continuar navegando, você concorda com o uso de cookies.
        Para mais informações, acesse nossa{' '}
        <a
          href="https://docs.google.com/document/d/1i_ORar6q4_WWSqThR3Z1G9CiHtvoGOcRr7htbvkpfW8/edit?usp=sharing"
          target="_blank"
          rel="noopener noreferrer"
          className="top-digital-content-color underline"
        >
          Política de Privacidade
        </a>
        .
      </div>
    </CookieConsent>
  );
};

export default AcceptCookies;
