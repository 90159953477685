type Props = {
  situacao: any;
};

const BoletoStatus = ({ situacao }: Props) => {
  const backgroundColor =
    situacao === 'Pago' ? 'green' : situacao === 'Vencido' ? 'red' : 'orange';
  return (
    <div style={{ ...statusStyle, backgroundColor: backgroundColor }}>
      <b>{situacao}</b>
    </div>
  );
};

const statusStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItens: 'center',
  padding: '5px',
  color: '#ffffff',
  borderRadius: '5px',
};

export default BoletoStatus;
