import { Link } from 'react-router-dom';

type Props = {
  numStation: string;
  stationId: string;
};

const LinkToStation = ({ numStation, stationId }: Props) => {
  return (
    <Link className="underline" to={`/stationpage/${stationId}`}>
      {numStation}
    </Link>
  );
};

export default LinkToStation;
