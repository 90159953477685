import { useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import { ptBR } from 'date-fns/locale';
import 'react-datepicker/dist/react-datepicker.css'; // Certifique-se de que isso está presente
import { bankHolidays } from '../../Interfaces/IBoletoData';
import { ExternalLink } from 'lucide-react';
import { Dialog, DialogContent } from '@mui/material';

registerLocale('pt-BR', ptBR);

type Props = {
  nossoNumero: any;
  situacao: string;
};

type DateString = {
  year: string;
  month: string;
  day: string;
};

const DatePaymentSelect = ({ nossoNumero, situacao }: Props) => {
  const [showDatePicker, setShowDatePicker] = useState<boolean>(false);

  const toDateString = (date: Date) => {
    const year = date.getFullYear().toString();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return { year, month, day };
  };

  const isBankHoliday = (date: Date) => {
    const { year, month, day } = toDateString(date);

    const dateStr = `${year}-${month}-${day}`;
    return (
      bankHolidays[year] &&
      bankHolidays[year][month] &&
      bankHolidays[year][month].includes(dateStr)
    );
  };

  const isWeekday = (date: Date) => {
    const day = date.getDay();
    return day !== 0 && day !== 6; // Dias 0 e 6 correspondem a domingo e sábado
  };

  const handleDateChange = (date: Date) => {
    const { year, month, day } = toDateString(date);
    openBillUrl({ year, month, day });
  };

  const openBillUrl = (date: DateString | null) => {
    const args = nossoNumero.split('-');
    const type = situacao === 'Pago' ? 'q' : 'd';
    const dateCalc = date ? `${date.day}/${date.month}/${date.year}` : '';
    const url =
      `https://sistemas.anatel.gov.br/Boleto/Internet/Monta_Boleto.asp?resp=S&Fisteis=${args[0]}@${args[1]}` +
      `&DataCalc=${dateCalc}&IndImpMult=true&CotaUnica=C&IndGrCobrancaGrSimples=False&pIndSistema=&indTipoBoleto=${type}`;
    window.open(url);
    setShowDatePicker(false);
  };

  return (
    <>
      {situacao === 'Vencido' ? (
        <div className="relative group cursor-pointer">
          <ExternalLink
            className="inline ml-1 w-4 h-4 cursor-pointer"
            onClick={() => setShowDatePicker(true)}
          />
          <span className="absolute bottom-full mb-2 hidden w-auto p-2 text-sm text-white bg-top-digital-primary-dark rounded-md group-hover:block">
            Abrir Boleto
          </span>
        </div>
      ) : (
        <div className="relative group cursor-pointer">
          <ExternalLink
            className="inline ml-1 w-4 h-4 cursor-pointer"
            onClick={() => openBillUrl(null)}
          />
          <span className="absolute bottom-full mb-2 hidden w-auto p-2 text-sm text-white bg-top-digital-primary-dark rounded-md group-hover:block">
            Abrir Boleto
          </span>
        </div>
      )}

      <Dialog open={showDatePicker} onClose={() => setShowDatePicker(false)}>
        <DialogContent>
          <div className="flex items-center flex-col text-center gap-4 p-4">
            <b>Selecione a data de vencimento</b>
            <DatePicker
              locale="pt-BR"
              onChange={handleDateChange}
              dateFormat="dd/MM/yyyy"
              filterDate={(date) => {
                return isWeekday(date) && !isBankHoliday(date);
              }}
              highlightDates={[new Date() /* mais datas de feriados aqui */]}
              minDate={new Date()}
              maxDate={
                new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
              }
              name="datePayment"
              className="customInputText"
              placeholderText="Selecione a data de pagamento"
              readOnly={false}
              inline // Usa a opção inline para exibir o calendário diretamente
            />
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default DatePaymentSelect;
