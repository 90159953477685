// src/components/Map/InfoWindowStations.tsx

import { TLocation } from '../../Interfaces/ILocation';
import { Link } from 'react-router-dom';

type Props = {
  location: TLocation;
};

const InfoWindowStations = ({ location }: Props) => {
  return (
    <div className="custom-scroll max-w-[230px] max-h-[230px] info-window">
      {location.stations && location.stations.length !== 0 && (
        <table className="text-left text-smp font-light min-w-full">
          <thead className="border-b bg-white font-medium dark:border-neutral-500">
            <tr>
              <th
                scope="col"
                className="font-top-digital-content font-semibold text-sm"
              >
                {location.stations.length > 1 ? 'Estações' : 'Estação'}
              </th>
            </tr>
          </thead>
          <tbody>
            {location.stations.map((station, index) => (
              <tr
                key={station._id}
                className={`border-b dark:border-neutral-500 ${
                  index % 2 ? 'bg-top-digital-op-25' : 'bg-white'
                }`}
              >
                <td className="text-smp font-top-digital-content">
                  <div className="flex items-center justify-center">
                    <div
                      className={`w-2 h-2 rounded-full mr-2 ${
                        station.documentStatus === 'green'
                          ? 'bg-green-500'
                          : station.documentStatus === 'orange'
                          ? 'bg-orange-500'
                          : 'bg-red-500'
                      }`}
                    ></div>

                    <div>
                      <Link to={`/stationpage/${station._id}`}>
                        {station.Label && station.Label !== ''
                          ? station.Label
                          : station.EnderecoEstacao}
                      </Link>
                    </div>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default InfoWindowStations;
