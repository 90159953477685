import useAppDispatch from '../../hooks/useAppDispatch';
import useAppSelector from '../../hooks/useAppSelector';
import { useEffect, useState } from 'react';
import { format as formatCNPJ } from 'cnpj';
import { format as formatCPF } from 'cpf';
import formatBRPhone from '../../utils/formatBRPhone';
import {
  profile,
  profileSelector,
  resetProfile,
} from '../../slices/ProfileSlice';
import Register from '../Register/Register';
import ImageOffLine from '../../presentational/image-main/ImageMain';
import { roleRegister } from '../../Interfaces/IRegisterStates';

const MyProfile = () => {
  const dispatch = useAppDispatch();
  const [updateProfile, setUpdateProfile] = useState(false);
  const {
    profileUser,
    profileLoading,
    profileSuccess,
    profileMessage,
    profileFormSuccess,
  } = useAppSelector(profileSelector);

  useEffect(() => {
    dispatch(profile());
  }, [dispatch, profileSuccess]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [updateProfile]);

  const handleUpdate = () => {
    setUpdateProfile(true);
  };

  useEffect(() => {
    if (profileFormSuccess) {
      const timer = setTimeout(() => {
        dispatch(resetProfile());
      }, 3000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [dispatch, profileFormSuccess]);

  if (profileLoading) {
    return <p>Carregando...</p>;
  }

  return (
    <>
      {updateProfile ? (
        <Register
          userData={profileUser}
          setUpdateProfile={setUpdateProfile}
          setRegisterChildUser={null}
          isFinishChild={false}
          editChildUserByNotAdmin={
            profileUser?.role === 'superadmin' ? false : true
          }
        />
      ) : (
        <>
          {profileUser !== null ? (
            <div className="flex">
              <div className="flex flex-1 flex-col justify-center md:justify-start px-6 py-2 md:px-2">
                <div className="flex justify-center items-start mt-4 mb-4 lg:mt-6">
                  <div className="w-4/5">
                    {/* demais elementos */}
                    {profileFormSuccess && (
                      <div className="mt-4">
                        <div className="text-sm text-green-700 font-semibold">
                          {profileMessage}
                        </div>
                      </div>
                    )}
                    <h2 className="mt-10 mb-8 text-left text-base font-semibold top-digital-content-color leading-9 tracking-tight">
                      Meu Perfil
                    </h2>
                    {roleRegister.tipoCadastro.isVisible && (
                      <div>
                        <label className="text-left text-smp font-semibold top-digital-content-color mr-1">
                          Tipo de Cadastro:
                        </label>
                        <label className="text-left text-smp font-normal top-digital-content-color">
                          {profileUser?.tipoCadastro === 'PF'
                            ? 'Pessoa Física'
                            : 'Pessoa Jurídica'}
                        </label>
                      </div>
                    )}
                    {roleRegister.name.isVisible && (
                      <div>
                        <label className="text-left text-smp font-semibold top-digital-content-color mr-1">
                          Nome:
                        </label>
                        <label className="text-left text-smp font-normal top-digital-content-color">
                          {profileUser?.name}
                        </label>
                      </div>
                    )}
                    {roleRegister.lastname.isVisible && (
                      <div>
                        <label className="text-left text-smp font-semibold top-digital-content-color mr-1">
                          Sobrenome:
                        </label>
                        <label className="text-left text-smp font-normal top-digital-content-color">
                          {profileUser?.lastname}
                        </label>
                      </div>
                    )}
                    {roleRegister.gender.isVisible && (
                      <div>
                        <label className="text-left text-smp font-semibold top-digital-content-color mr-1">
                          Gênero:
                        </label>
                        <label className="text-left text-smp font-normal top-digital-content-color">
                          {profileUser?.gender}
                        </label>
                      </div>
                    )}
                    {roleRegister.email.isVisible && (
                      <div>
                        <label className="text-left text-smp font-semibold top-digital-content-color mr-1">
                          E-mail:
                        </label>
                        <label className="text-left text-smp font-normal top-digital-content-color">
                          {profileUser?.email}
                        </label>
                      </div>
                    )}
                    {profileUser.role === 'superadmin' && (
                      <>
                        {profileUser?.tipoCadastro === 'PJ' ? (
                          <>
                            {roleRegister.tradename.isVisible && (
                              <div>
                                <label className="text-left text-smp font-semibold top-digital-content-color mr-1">
                                  Razão Social:
                                </label>
                                <label className="text-left text-smp font-normal top-digital-content-color">
                                  {profileUser?.tradename}
                                </label>
                              </div>
                            )}
                            {roleRegister.businessname.isVisible && (
                              <div>
                                <label className="text-left text-smp font-semibold top-digital-content-color mr-1">
                                  Nome Fantasia:
                                </label>
                                <label className="text-left text-smp font-normal top-digital-content-color">
                                  {profileUser?.businessname}
                                </label>
                              </div>
                            )}
                            {roleRegister.cnpj.isVisible &&
                              !roleRegister.cnpj.isComponent && (
                                <div>
                                  <label className="text-left text-smp font-semibold top-digital-content-color mr-1">
                                    CNPJ:
                                  </label>
                                  <label className="text-left text-smp font-normal top-digital-content-color">
                                    {profileUser?.cnpj
                                      ? formatCNPJ(profileUser.cnpj)
                                      : ''}
                                  </label>
                                </div>
                              )}
                          </>
                        ) : (
                          <>
                            {roleRegister.cpf.isVisible && (
                              <div>
                                <label className="text-left text-smp font-semibold top-digital-content-color mr-1">
                                  CPF:
                                </label>
                                <label className="text-left text-smp font-normal top-digital-content-color">
                                  {profileUser?.cpf
                                    ? formatCPF(profileUser.cpf)
                                    : ''}
                                </label>
                              </div>
                            )}
                          </>
                        )}
                        {roleRegister.cep.isVisible && (
                          <div>
                            <label className="text-left text-smp font-semibold top-digital-content-color mr-1">
                              CEP:
                            </label>
                            <label className="text-left text-smp font-normal top-digital-content-color">
                              {profileUser?.cep}
                            </label>
                          </div>
                        )}
                        {roleRegister.street.isVisible && (
                          <div>
                            <label className="text-left text-smp font-semibold top-digital-content-color mr-1">
                              Rua:
                            </label>
                            <label className="text-left text-smp font-normal top-digital-content-color">
                              {profileUser?.street}
                            </label>
                          </div>
                        )}
                        {roleRegister.number.isVisible && (
                          <div>
                            <label className="text-left text-smp font-semibold top-digital-content-color mr-1">
                              Número:
                            </label>
                            <label className="text-left text-sm font-normal top-digital-content-color">
                              {profileUser?.number}
                            </label>
                          </div>
                        )}
                        {roleRegister.complement.isVisible && (
                          <div>
                            <label className="text-left text-smp font-semibold top-digital-content-color mr-1">
                              Complemento:
                            </label>
                            <label className="text-left text-smp font-normal top-digital-content-color">
                              {profileUser?.complement}
                            </label>
                          </div>
                        )}
                        {roleRegister.district.isVisible && (
                          <div>
                            <label className="text-left text-smp font-semibold top-digital-content-color mr-1">
                              Bairro:
                            </label>
                            <label className="text-left text-smp font-normal top-digital-content-color">
                              {profileUser?.district}
                            </label>
                          </div>
                        )}
                        {roleRegister.city.isVisible && (
                          <div>
                            <label className="text-left text-smp font-semibold top-digital-content-color mr-1">
                              Cidade:
                            </label>
                            <label className="text-left text-smp font-normal top-digital-content-color">
                              {profileUser?.city}
                            </label>
                          </div>
                        )}
                        {roleRegister.state.isVisible && (
                          <div>
                            <label className="text-left text-smp font-semibold top-digital-content-color mr-1">
                              State:
                            </label>
                            <label className="text-left text-smp font-normal top-digital-content-color">
                              {profileUser?.state}
                            </label>
                          </div>
                        )}
                        {roleRegister.businessPhone.isVisible && (
                          <div>
                            <label className="text-left text-smp font-semibold top-digital-content-color mr-1">
                              Telefone Comercial:
                            </label>
                            <label className="text-left text-smp font-normal top-digital-content-color">
                              {profileUser?.businessPhone
                                ? formatBRPhone(profileUser.businessPhone)
                                : 'Telefone Comercial não disponível'}
                            </label>
                          </div>
                        )}
                      </>
                    )}
                    {roleRegister.cellPhone.isVisible && (
                      <div>
                        <label className="text-left text-smp font-semibold top-digital-content-color mr-1">
                          Telefone Celular:
                        </label>
                        <label className="text-left text-smp font-normal top-digital-content-color">
                          {profileUser?.cellPhone
                            ? formatBRPhone(profileUser.cellPhone)
                            : 'Telefone Celular não disponível'}
                        </label>
                      </div>
                    )}
                    {roleRegister.receiveLicensingNews.isVisible && (
                      <div className="border-b-2">
                        <label className="text-left text-smp font-semibold top-digital-content-color mr-1">
                          Aceito receber informações sobre os processos de
                          licenciamento Anatel ligados a empresa:
                        </label>
                        <label className="text-left text-smp font-normal top-digital-content-color">
                          {profileUser?.receiveLicensingNews ? 'Sim' : 'Não'}
                        </label>
                      </div>
                    )}
                    {roleRegister.acceptAlertEmails.isVisible && (
                      <div className="border-b-2">
                        <label className="text-left text-smp font-semibold top-digital-content-color mr-1">
                          Aceito receber emails informando sobre novos boletos
                          Anatel e o status dos atuais:
                        </label>
                        <label className="text-left text-smp font-normal top-digital-content-color">
                          {profileUser?.acceptAlertEmails ? 'Sim' : 'Não'}
                        </label>
                      </div>
                    )}
                    {roleRegister.receiveNews.isVisible && (
                      <div className="border-b-2">
                        <label className="text-left text-smp font-semibold top-digital-content-color mr-1">
                          Aceito receber novidades sobre o Agiliza e fatos
                          relevantes da Anatel:
                        </label>
                        <label className="text-left text-smp font-normal top-digital-content-color">
                          {profileUser?.receiveNews ? 'Sim' : 'Não'}
                        </label>
                      </div>
                    )}
                    <div className="mt-12">
                      <button
                        onClick={handleUpdate}
                        disabled={profileFormSuccess ? true : false}
                        className="customButton w-1/2"
                      >
                        Editar
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <ImageOffLine />
            </div>
          ) : (
            <div className="text-red-600 text-smp">
              Erro ao carregar o usuário
            </div>
          )}
        </>
      )}
    </>
  );
};

export default MyProfile;
