import { FileCheck, FileX, FileX2 } from 'lucide-react';
import React from 'react';

type Props = {
  documentStatus: string;
};

const DocumentSituationColor = ({ documentStatus }: Props) => {
  return (
    <div className="flex justify-center items-center">
      {documentStatus === 'valido' ? (
        <div className="relative group">
          <FileCheck color="green" />
          <span
            className="absolute bottom-full mb-2 hidden w-auto p-2 text-sm text-white rounded-md group-hover:block"
            style={{ backgroundColor: 'green' }}
          >
            Documento válido
          </span>
        </div>
      ) : documentStatus === 'opcional-invalido' ? (
        <div className="relative group">
          <FileX color="orange" />
          <span
            className="absolute bottom-full mb-2 hidden w-auto p-2 text-sm text-white rounded-md group-hover:block"
            style={{ backgroundColor: 'orange' }}
          >
            Sem documento opcional válido
          </span>
        </div>
      ) : (
        <div className="relative group">
          <FileX2 color="red" />
          <span
            className="absolute bottom-full mb-2 hidden w-auto p-2 text-sm text-white rounded-md group-hover:block"
            style={{ backgroundColor: 'red' }}
          >
            Sem documento obrigatório válido
          </span>
        </div>
      )}
    </div>
  );
};

export default DocumentSituationColor;
