//Hooks
import { useEffect, useState } from 'react';
import useAppDispatch from '../../hooks/useAppDispatch';
import useAppSelector from '../../hooks/useAppSelector';

import {
  adminSelector,
  getChildrenUsers,
  profileChild,
  adminReset,
  resendConfirmationEmail,
  requestEditUser,
  requestResetEditUser,
} from '../../slices/AdminSlice';
import { profileSelector, resetProfile } from '../../slices/ProfileSlice';
import { urlapp } from '../../utils/config';
import Register from '../Register/Register';
import ConfigStation from '../ConfigStation/ConfigStation';
import ExtraUsers from '../../components/admin/ExtraUsers';
import TableUsers from '../../components/admin/TableUsers';
import { TProfile } from '../../Interfaces/IProfileStates';
import { toast } from 'react-toastify';
import { UpdatedByAdminPayload } from '../../Interfaces/IRegisterStates';

const ConfigUser = () => {
  const [registerChildUser, setRegisterChildUser] = useState<boolean>(false);
  const [editChildUser, setEditChildUser] = useState<boolean>(false);
  const [configChildUser, setConfigChildUser] = useState<boolean>(false);
  const {
    childUsers,
    childUser,
    adminLoading,
    adminMessage,
    adminSuccess,
    isEditingUserSucceeded,
  } = useAppSelector(adminSelector);
  const { profileFormSuccess, profileMessage } =
    useAppSelector(profileSelector);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getChildrenUsers());
  }, [dispatch, editChildUser, registerChildUser, configChildUser]);

  useEffect(() => {
    if (adminSuccess) {
      const timer = setTimeout(() => {
        dispatch(adminReset());
      }, 5000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [adminSuccess, dispatch]);

  useEffect(() => {
    if (profileFormSuccess) {
      const timer = setTimeout(() => {
        dispatch(resetProfile());
        dispatch(adminReset());
      }, 1000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [profileFormSuccess, dispatch]);

  const handleCreateChildUser = () => {
    setRegisterChildUser(true);
  };

  const handleEditChildUser = (id: string) => {
    setEditChildUser(true);
    const userData = {
      id,
    };
    dispatch(profileChild(userData));
  };

  const handleEditConfigChildUser = (id: string) => {
    setConfigChildUser(true);
    const userData = {
      id,
    };
    dispatch(profileChild(userData));
  };

  function handleReSendConfirmationEmail(id: string) {
    const userData = {
      id,
    };
    dispatch(resendConfirmationEmail(userData));
  }

  function handleBlockUser(user: TProfile) {
    const userData: UpdatedByAdminPayload = {
      userUpdate: {
        id: user._id,
        name: user.name,
        lastname: user.lastname,
        gender: user.gender,
        receiveLicensingNews: user.receiveLicensingNews,
        isBlocked: !user.isBlocked,
      },
      roleUpdate: null,
    };

    dispatch(requestEditUser(userData));
  }

  function handleRemoveUser(user: TProfile) {
    const userData: UpdatedByAdminPayload = {
      userUpdate: {
        id: user._id,
        name: user.name,
        lastname: user.lastname,
        gender: user.gender,
        isRemoved: true,
      },
      roleUpdate: null,
    };

    dispatch(requestEditUser(userData));
  }

  if (adminLoading) return <div>Carregando...</div>;

  if (isEditingUserSucceeded) {
    dispatch(requestResetEditUser());
    toast.success('Usuário atualizado com sucesso!');
    dispatch(getChildrenUsers());
  }

  return (
    <>
      {childUsers &&
      !registerChildUser &&
      !editChildUser &&
      !configChildUser &&
      childUsers.length > 0 ? (
        <div className="px-4 sm:px-6 lg:px-8 mt-8">
          <ExtraUsers handleCreateChildUser={handleCreateChildUser} />
          {adminSuccess && (
            <div className="mt-4">
              <div className="text-sm text-green-700 font-semibold">
                {adminMessage}
              </div>
            </div>
          )}
          {profileFormSuccess && (
            <div className="mt-4">
              <div className="text-sm text-green-700 font-semibold">
                {profileMessage}
              </div>
            </div>
          )}
          <div className="mt-8 flow-root">
            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                <TableUsers
                  childUsers={childUsers}
                  handleEditChildUser={handleEditChildUser}
                  handleReSendConfirmationEmail={handleReSendConfirmationEmail}
                  handleEditConfigChildUser={handleEditConfigChildUser}
                  handleBlockUser={handleBlockUser}
                  handleRemoveUser={handleRemoveUser}
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          {registerChildUser || editChildUser ? (
            <>
              <Register
                userData={editChildUser ? childUser : null}
                setUpdateProfile={null}
                setRegisterChildUser={
                  registerChildUser ? setRegisterChildUser : null
                }
                setEditChildUserByAdmin={
                  editChildUser ? setEditChildUser : null
                }
                isFinishChild={false}
              />
            </>
          ) : (
            <>
              {configChildUser ? (
                <ConfigStation
                  childUser={childUser}
                  setConfigChildUser={setConfigChildUser}
                />
              ) : (
                <div className="flex w-full mt-2">
                  <div className="w-full flex items-center justify-center">
                    <div className="flex min-h-screen flex-1 flex-col justify-start px-6 py-6 lg:px-8">
                      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                        <img
                          className="mx-auto h-14 w-auto"
                          src={`${urlapp}/img/logo-antena-top-digital-agi.png`}
                          alt="Your Company"
                        />
                        <h2 className="mt-10 text-center text-lg font-bold leading-9 tracking-tight top-digital-content-color">
                          Gestão de usuários e permissões
                        </h2>
                      </div>
                      <div className="mt-10 mx-auto w-full max-w-sm text-sm text-center">
                        Você ainda não possui nenhum usuário extra.
                      </div>
                      <div className="mt-10 mx-auto w-full max-w-sm text-sm text-center">
                        <button
                          onClick={handleCreateChildUser}
                          className="flex w-full justify-center rounded-md bg-top-digital px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:text-top-digital-buttom-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 disabled:opacity-75"
                        >
                          Cadastrar Primeiro Usuário
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default ConfigUser;
