//react-table
import {
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  FilterFn,
  ColumnDef,
  flexRender,
} from '@tanstack/react-table';

import { filterFns } from '../../utils/reactTableFn';

import { useState, useMemo } from 'react';

import { ChevronDown, ChevronRightIcon } from 'lucide-react';
import { ChevronUp } from 'lucide-react';
import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
} from '@heroicons/react/20/solid';
import { ChevronLeftIcon } from 'lucide-react';
import { DebouncedSearchInput } from './DebouncedSearchInput';
import { TDocumentStationPage } from '../../Interfaces/IDocument';

interface ReactTableProps<T extends object> {
  data: T[];
  columns: ColumnDef<T>[];
  showFooter?: boolean;
  showNavigation?: boolean;
  showGlobalFilter?: boolean;
  filterFn?: FilterFn<T>;
}

const DocumentTable = <T extends TDocumentStationPage>({
  data,
  columns,
  showFooter = false,
  showNavigation = true,
  showGlobalFilter = true,
  filterFn = filterFns.contains,
}: ReactTableProps<T>) => {
  const [globalFilter, setGlobalFilter] = useState('');
  const [documentTypeFilter, setDocumentTypeFilter] = useState('');

  const documentTypes = useMemo(() => {
    const types = Array.from(new Set(data.map((doc) => doc.typeName)));
    return ['Todos os Tipos', ...types];
  }, [data]);

  const filteredData = useMemo(() => {
    if (documentTypeFilter && documentTypeFilter !== 'Todos os Tipos') {
      return data.filter((doc) => doc.typeName === documentTypeFilter);
    }
    return data;
  }, [data, documentTypeFilter]);

  const table = useReactTable({
    data: filteredData,
    columns,
    filterFns: {
      fuzzy: filterFns.contains,
    },
    state: {
      globalFilter,
    },
    globalFilterFn: filterFn,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onGlobalFilterChange: setGlobalFilter,
  });

  return (
    <>
      {data && data.length !== 0 && (
        <div className="border-b-[15px] border-top-digital-secondary-lighter">
          <div className="h-10" />
          <div className="flex items-center justify-start gap-1">
            <nav
              className="items-center justify-between isolate text-smp inline-flex -space-x-px rounded-none"
              aria-label="Pagination"
            >
              <button
                className="relative inline-flex items-center h-8 rounded-l-md px-2 py-0 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                onClick={() => table.setPageIndex(0)}
                disabled={!table.getCanPreviousPage()}
              >
                <span className="sr-only">Primeira</span>
                <ChevronDoubleLeftIcon className="h-3 w-4" aria-hidden="true" />
              </button>
              <button
                className="relative inline-flex items-center px-2 py-2 h-8 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                onClick={() => table.previousPage()}
                disabled={!table.getCanPreviousPage()}
              >
                <span className="sr-only">Anterior</span>
                <ChevronLeftIcon className="h-3 w-4" aria-hidden="true" />
              </button>
              <button
                className="relative inline-flex items-center  px-2 h-8 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                onClick={() => table.nextPage()}
                disabled={!table.getCanNextPage()}
              >
                <span className="sr-only">Próxima</span>
                <ChevronRightIcon className="h-3 w-4" aria-hidden="true" />
              </button>
              <button
                className="relative inline-flex items-center rounded-r-md px-2 h-8 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                onClick={() => table.setPageIndex(table.getPageCount() - 1)}
                disabled={!table.getCanNextPage()}
              >
                <span className="sr-only">Última</span>
                <ChevronDoubleRightIcon
                  className="h-3 w-4"
                  aria-hidden="true"
                />
              </button>
              <div className="pl-2 flex flex-1 items-center justify-between">
                <p className="text-smp text-gray-700">
                  <span className="font-medium">
                    Página {table.getState().pagination.pageIndex + 1}{' '}
                  </span>{' '}
                  de {table.getPageCount()}
                </p>
              </div>
              <div className="h-4" />
            </nav>

            <div className="overflow-hidden p-2">
              <DebouncedSearchInput
                value={globalFilter ?? ''}
                onChange={(value) => setGlobalFilter(String(value))}
                className="font-lg border-block border p-2 shadow mb-2"
                placeholder="Buscar em todas colunas..."
              />
            </div>

            <select
              value={documentTypeFilter}
              onChange={(e) => setDocumentTypeFilter(e.target.value)}
              className="customSelect"
            >
              {documentTypes.map((type, index) => (
                <option key={index} value={type}>
                  {type}
                </option>
              ))}
            </select>
          </div>
          <div className="text-smp mt-1 mb-2">
            <span className="font-semibold">
              {table.getPrePaginationRowModel().rows.length}
            </span>{' '}
            Registros
          </div>
          <table className="text-left font-light w-full">
            <thead className="border-b bg-top-digital-op-40 font-medium dark:border-neutral-500">
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <th
                        key={header.id}
                        colSpan={header.colSpan}
                        className="px-4 py-4 whitespace-nowrap font-top-digital-content font-semibold text-smp"
                      >
                        {header.isPlaceholder ? null : (
                          <>
                            <div
                              {...{
                                className: header.column.getCanSort()
                                  ? 'cursor-pointer select-none'
                                  : '',
                                onClick:
                                  header.column.getToggleSortingHandler(),
                              }}
                              className="flex cursor-pointer"
                            >
                              {flexRender(
                                header.column.columnDef.header,
                                header.getContext(),
                              )}
                              {{
                                asc: <ChevronUp className="h-4 w-4" />,
                                desc: <ChevronDown className="h-4 w-4" />,
                              }[header.column.getIsSorted() as string] ?? (
                                <ChevronUp className="h-4 w-4 opacity-0" />
                              )}
                            </div>
                          </>
                        )}
                      </th>
                    );
                  })}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map((row, index) => {
                return (
                  <tr
                    key={row.id}
                    className={`${
                      !(index % 2) ? 'bg-white' : 'bg-top-digital-op-25'
                    } text-top-digital-content-color p-1`}
                  >
                    {row.getVisibleCells().map((cell, index) => {
                      return (
                        <td
                          key={cell.id}
                          className="px-4 py-4 whitespace-nowrap font-top-digital-content font-normal text-smp"
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext(),
                          )}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};

export default DocumentTable;
