import React, { useState, useEffect } from 'react';

const LoadingDots = () => {
  const [dots, setDots] = useState('');

  useEffect(() => {
    const intervalId = setInterval(() => {
      setDots((prevDots) => (prevDots.length >= 3 ? '' : prevDots + '.'));
    }, 500); // Atualiza a cada meio segundo

    return () => clearInterval(intervalId);
  }, []);

  return (
    <span className="w-3 inline-block text-left">
      {dots.padEnd(3, '\u00A0')}
    </span>
  );
};

const LoadingMessage = () => (
  <div className="mt-5 text-center text-smp text-blue-800 font-bold sm:mt-6">
    Aguarde. Isso pode levar um tempo
    <LoadingDots />
  </div>
);

export default LoadingMessage;
