import { TDocument, TDocumentSmall } from '../Interfaces/IDocument';
import { api, requestConfig } from '../utils/config';

const insertDoc = async (data: any, token: string | undefined) => {
  const config = requestConfig('POST', data, token, true);

  try {
    const res = await fetch(api + '/documents', config)
      .then((res) => res.json())
      .catch((err) => err);

    return res;
  } catch (error) {
    //console.log(error);
  }
};

//PUT
const updateDoc = async (data: any, token: string | undefined) => {
  const config = requestConfig('PUT', data, token);
  try {
    const res = await fetch(api + '/documents', config)
      .then((res) => res.json())
      .catch((err) => err);
    return res;
  } catch (error) {
    //console.log(error);
  }
};

//Delete
const deleteDoc = async (id: string, token: string | undefined) => {
  const config = requestConfig('DELETE', null, token);
  try {
    const res = await fetch(api + '/documents/' + id, config)
      .then((res) => res.json())
      .catch((err) => err);

    return res;
  } catch (error) {
    //console.log(error);
  }
};

//Get
const getAllDocs = async (token: string | undefined) => {
  const config = requestConfig('GET', null, token);

  try {
    const res = await fetch(api + '/documents', config)
      .then((res) => res.json())
      .catch((err) => err);

    return res;
  } catch (error) {
    console.error(error);
  }
};

//Get document by ID
const getDocById = async (data: any, token: string | undefined) => {
  const config = requestConfig('POST', data, token);
  try {
    const res = await fetch(api + '/documents/show', config)
      .then((res) => res.json())
      .catch((err) => err);

    return res;
  } catch (error) {
    //console.log(error);
  }
};

//post
const getSystemDocs = async (data: any, token: string | undefined) => {
  const config = requestConfig('POST', data, token);
  try {
    const res = await fetch(api + '/documents/system', config)
      .then((res) => res.json())
      .catch((err) => err);

    return res;
  } catch (error) {
    //console.log(error);
  }
};

//Get
const getStationDocs = async (objStation: any, token: string | undefined) => {
  const config = requestConfig('GET', null, token);
  const queryParams = new URLSearchParams(objStation).toString();
  try {
    const url = `${api}/documents/station/?${queryParams}`;
    const res = await fetch(url, config)
      .then((res) => res.json())
      .catch((err) => err);
    return res;
  } catch (error) {
    console.error(error);
  }
};

//Get
const downloadDoc = async (
  doc: TDocument | TDocumentSmall | undefined,
  token: string | undefined,
) => {
  const config = requestConfig('GET', null, token);

  try {
    const res = await fetch(api + '/documents/download/' + doc?._id, config)
      .then((res) =>
        res.blob().then((blob) => {
          // Creating new object of PDF/DOC file
          const fileURL = window.URL.createObjectURL(blob);
          // Setting various property values
          let alink = document.createElement('a');
          alink.href = fileURL;
          const fileName = doc?.title
            .replace(/ /g, '_')
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .toLocaleLowerCase();
          alink.download = fileName + doc!.extension;
          alink.click();
          return { download: true };
        }),
      )
      .catch((err) => err);
    return res;
  } catch (error) {
    console.error(error);
  }
};

//Get

const DocumentService = {
  insertDoc,
  updateDoc,
  deleteDoc,
  getAllDocs,
  getDocById,
  getStationDocs,
  getSystemDocs,
  downloadDoc,
};

export default DocumentService;
