// src/slices/ProjectSlice.ts

import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import ProjectService from '../services/ProjectService';
import { RootState } from '../store';
import { IProject, IProjectStates } from '../Interfaces/IProject';

// Estado inicial
const initialState: IProjectStates = {
  projects: [],
  project: null,
  selectedProjectId: null, // Adicionado para gerenciar o projeto selecionado
  error: false,
  message: '',
  insertSuccess: false,
  updateSuccess: false,
  deleteSuccess: false,
  loading: false,
};

// Async Thunks

// Inserir Projeto
export const insertProjectAsync = createAsyncThunk(
  'projects/insertProject',
  async (formData: FormData, thunkAPI) => {
    try {
      const token = (thunkAPI.getState() as RootState).LoginReducer.user;
      const project = await ProjectService.insertProject(formData, token);
      return project;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

// Atualizar Projeto
export const updateProjectAsync = createAsyncThunk(
  'projects/updateProject',
  async ({ id, data }: { id: string; data: Partial<IProject> }, thunkAPI) => {
    try {
      const token = (thunkAPI.getState() as RootState).LoginReducer.user;
      const updatedProject = await ProjectService.updateProject(
        id,
        data,
        token,
      );
      return updatedProject;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

// Deletar Projeto
export const deleteProjectAsync = createAsyncThunk(
  'projects/deleteProject',
  async (id: string, thunkAPI) => {
    try {
      const token = (thunkAPI.getState() as RootState).LoginReducer.user;
      const response = await ProjectService.deleteProject(id, token);
      return { id, message: response.message };
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

// Buscar Todos os Projetos
export const getAllProjectsAsync = createAsyncThunk(
  'projects/getAllProjects',
  async (_, thunkAPI) => {
    try {
      const token = (thunkAPI.getState() as RootState).LoginReducer.user;
      const projects = await ProjectService.getAllProjects(token);
      return projects;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

// Buscar Projeto por ID
export const getProjectByIdAsync = createAsyncThunk(
  'projects/getProjectById',
  async (id: string, thunkAPI) => {
    try {
      const token = (thunkAPI.getState() as RootState).LoginReducer.user;
      const project = await ProjectService.getProjectById(id, token);
      return project;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

// Slice
const ProjectSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    reset: (state) => {
      state.error = false;
      state.loading = false;
      state.insertSuccess = false;
      state.updateSuccess = false;
      state.deleteSuccess = false;
      state.message = '';
      state.project = null;
    },
    setSelectedProjectId: (state, action: PayloadAction<string | null>) => {
      state.selectedProjectId = action.payload;
    },
    clearSelectedProjectId: (state) => {
      state.selectedProjectId = null;
    },
  },
  extraReducers: (builder) => {
    // Inserir Projeto
    builder
      .addCase(insertProjectAsync.pending, (state) => {
        state.loading = true;
        state.error = false;
        state.message = '';
        state.insertSuccess = false;
      })
      .addCase(
        insertProjectAsync.fulfilled,
        (state, action: PayloadAction<IProject>) => {
          state.loading = false;
          state.insertSuccess = true;
          state.projects.unshift(action.payload); // Adiciona no início da lista
        },
      )
      .addCase(
        insertProjectAsync.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = true;
          state.message = action.payload || 'Erro ao inserir projeto.';
        },
      );

    // Atualizar Projeto
    builder
      .addCase(updateProjectAsync.pending, (state) => {
        state.loading = true;
        state.error = false;
        state.message = '';
        state.updateSuccess = false;
      })
      .addCase(
        updateProjectAsync.fulfilled,
        (state, action: PayloadAction<IProject>) => {
          state.loading = false;
          state.updateSuccess = true;
          const index = state.projects.findIndex(
            (p) => p.id === action.payload.id,
          );
          if (index !== -1) {
            state.projects[index] = action.payload;
          }
        },
      )
      .addCase(
        updateProjectAsync.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = true;
          state.message = action.payload || 'Erro ao atualizar projeto.';
        },
      );

    // Deletar Projeto
    builder
      .addCase(deleteProjectAsync.pending, (state) => {
        state.loading = true;
        state.error = false;
        state.message = '';
        state.deleteSuccess = false;
      })
      .addCase(
        deleteProjectAsync.fulfilled,
        (state, action: PayloadAction<{ id: string; message: string }>) => {
          state.loading = false;
          state.deleteSuccess = true;
          state.projects = state.projects.filter(
            (p) => p.id !== action.payload.id,
          );
          state.message = action.payload.message;
        },
      )
      .addCase(
        deleteProjectAsync.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = true;
          state.message = action.payload || 'Erro ao deletar projeto.';
        },
      );

    // Buscar Todos os Projetos
    builder
      .addCase(getAllProjectsAsync.pending, (state) => {
        state.loading = true;
        state.error = false;
        state.message = '';
      })
      .addCase(
        getAllProjectsAsync.fulfilled,
        (state, action: PayloadAction<IProject[]>) => {
          state.loading = false;
          state.projects = action.payload;
        },
      )
      .addCase(
        getAllProjectsAsync.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = true;
          state.message = action.payload || 'Erro ao buscar projetos.';
        },
      );

    // Buscar Projeto por ID
    builder
      .addCase(getProjectByIdAsync.pending, (state) => {
        state.loading = true;
        state.error = false;
        state.message = '';
      })
      .addCase(
        getProjectByIdAsync.fulfilled,
        (state, action: PayloadAction<IProject>) => {
          state.loading = false;
          state.project = action.payload;
        },
      )
      .addCase(
        getProjectByIdAsync.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = true;
          state.message = action.payload || 'Erro ao buscar projeto.';
        },
      );
  },
});

// Exportar ações e reducer
export const { reset, setSelectedProjectId, clearSelectedProjectId } =
  ProjectSlice.actions;
export const projectSelector = (state: RootState) => state.ProjectReducer;
export default ProjectSlice.reducer;
