import { api, requestConfig } from '../utils/config';

//get data by CEP
const consultaCEP = async (cep: string) => {
  const config = requestConfig('GET', null, null);
  try {
    const res = await fetch(api + '/cep/' + cep, config)
      .then((res) => res.json())
      .catch((err) => err);
    return res;
  } catch (error) {
    console.error(error);
  }
};

const CepService = {
  consultaCEP,
};

export default CepService;
