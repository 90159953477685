//types
import {
  TDocumentStationPage,
  TDocumentStationPageSmall,
} from '../../Interfaces/IDocument';

//redux
import useAppDispatch from '../../hooks/useAppDispatch';
import { downloadDoc } from '../../slices/DocumentSlice';

//icons
import { Download } from 'lucide-react';

type Props = {
  doc: TDocumentStationPage | TDocumentStationPageSmall;
};

const DownloadDocument = ({ doc }: Props) => {
  const dispatchDocument = useAppDispatch();

  const submitDownload = (e: React.MouseEvent<SVGSVGElement>) => {
    e.preventDefault();
    dispatchDocument(downloadDoc(doc));
  };

  return (
    <>
      <Download
        className="w-5 h-5 cursor-pointer"
        onClick={(e) => submitDownload(e)}
      />
    </>
  );
};

export default DownloadDocument;
