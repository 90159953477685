import { FormEvent, Fragment, useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import useAppDispatch from '../../hooks/useAppDispatch';
import useAppSelector from '../../hooks/useAppSelector';
import LoadingMessage from '../../components/Loading/LoadingMessage';

import {
  reset,
  licensingSelector,
  getProtocolUrl,
} from '../../slices/LicensingSlice';

type Props = {
  setnewProtocolId: React.Dispatch<React.SetStateAction<boolean>>;
};

const LicensingModal = ({ setnewProtocolId }: Props) => {
  const dispatch = useAppDispatch();
  const { loadingProtocol, error, createProtocolSuccess, message } =
    useAppSelector(licensingSelector);
  const [open, setOpen] = useState(true);
  const [protocol, setProtocol] = useState('');

  const handleProtocolRegister = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(getProtocolUrl(protocol));
  };

  useEffect(() => {
    if (createProtocolSuccess) {
      setOpen(false);
      setnewProtocolId(false);
      dispatch(reset());
    }
  }, [createProtocolSuccess, setnewProtocolId, dispatch]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                <form onSubmit={(e) => handleProtocolRegister(e)}>
                  <div>
                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900"
                      >
                        Insira aqui o protocolo para cadastro
                      </Dialog.Title>
                      <div className="mt-2">
                        <input
                          type="text"
                          placeholder="somente números"
                          value={protocol}
                          onChange={(e) => setProtocol(e.target.value)}
                          name="protocol"
                          id="protocol"
                          autoComplete="protocol"
                          className="customInputText"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex gap-2 justify-end mt-5 sm:mt-6">
                    <button type="submit" className="customButton">
                      Cadastrar
                    </button>
                    <button
                      type="button"
                      className="customButton"
                      onClick={() => setnewProtocolId(false)}
                    >
                      Cancelar
                    </button>
                  </div>
                  {loadingProtocol && <LoadingMessage />}
                  {error && (
                    <div className="mt-5 text-center text-smp text-red-800 font-bold sm:mt-6">
                      {message}
                    </div>
                  )}
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default LicensingModal;
