//Hooks
import useAppDispatch from '../../hooks/useAppDispatch';
import useAppSelector from '../../hooks/useAppSelector';
import { useEffect } from 'react';

//redux
import {
  reset,
  getConfig,
  getChildConfig,
  configSelector,
} from '../../slices/ConfigStationSlice';

//components
import TableStationConfig from './TableStationConfig';
import { TProfile } from '../../Interfaces/IProfileStates';

type Props = {
  childUser?: TProfile | null;
  setConfigChildUser?: React.Dispatch<React.SetStateAction<boolean>>;
};

const ConfigStation = ({ childUser, setConfigChildUser }: Props) => {
  const dispatch = useAppDispatch();
  const { success, loading } = useAppSelector(configSelector);

  useEffect(() => {
    if (childUser) {
      const userData = {
        id: childUser._id,
      };
      dispatch(getChildConfig(userData));
    } else {
      dispatch(getConfig());
    }
  }, [dispatch, childUser]);

  useEffect(() => {
    if (success) {
      dispatch(reset());
    }
  }, [success, dispatch]);

  if (loading) {
    return <div>Carregando...</div>;
  }

  return (
    <TableStationConfig
      childUser={childUser}
      setConfigChildUser={setConfigChildUser}
    />
  );
};

export default ConfigStation;
