import { api, requestConfig } from '../utils/config';

//get All Locations

//get Stations of visible map area

const getLocations = async (data: any, token: string | undefined) => {
  const config = requestConfig('POST', data, token);

  try {
    const res = await fetch(api + '/locations', config)
      .then((res) => res.json())
      .catch((err) => err);

    return res;
  } catch (error) {
    console.error(error);
  }
};

//getEquipamentsByID
const getMapSate = async (token: string | undefined) => {
  const config = requestConfig('GET', null, token);
  try {
    const res = await fetch(api + '/locations/map', config)
      .then((res) => res.json())
      .catch((err) => err);
    return res;
  } catch (error) {
    console.error(error);
  }
};

//getEquipamentsByID
const setMapSate = async (data: any | undefined, token: string | undefined) => {
  const config = requestConfig('POST', data, token);
  try {
    const res = await fetch(api + '/locations/map', config)
      .then((res) => res.json())
      .catch((err) => err);
    return res;
  } catch (error) {
    console.error(error);
  }
};

//setPageFromMap
const setPageFromMap = async (data: number, token: string | undefined) => {
  const config = requestConfig('POST', data, token);
  try {
    const res = await fetch(api + '/locations/page', config)
      .then((res) => res.json())
      .catch((err) => err);
    return res;
  } catch (error) {
    console.error(error);
  }
};

//getEquipamentsByID
const getPageFromMap = async (token: string | undefined) => {
  const config = requestConfig('GET', null, token);
  try {
    const res = await fetch(api + '/locations/page', config)
      .then((res) => res.json())
      .catch((err) => err);
    return res;
  } catch (error) {
    console.error(error);
  }
};

const LocationService = {
  getLocations,
  setMapSate,
  getMapSate,
  setPageFromMap,
  getPageFromMap,
};

export default LocationService;
