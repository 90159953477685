import { useState } from 'react';
import { X, Plus, Save } from 'lucide-react';
import { cnpj as validaCNPJ } from 'cpf-cnpj-validator';

import React from 'react';

type Props = {
  cnpjList: string[];
  setCnpjList: React.Dispatch<React.SetStateAction<string[]>>;
  setCnpjError: React.Dispatch<React.SetStateAction<string>>;
};

const CNPJSelection = ({ cnpjList, setCnpjList, setCnpjError }: Props) => {
  const [cnpjFormValue, setCnpjFormValue] = useState('');
  const [openCNPJForm, setOpenCNPJForm] = useState(true);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleCNPJChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value.replace(/\D/g, '');

    value = value.replace(/^(\d{2})(\d)/, '$1.$2');
    value = value.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3');
    value = value.replace(/\.(\d{3})(\d)/, '.$1/$2');
    value = value.replace(/(\d{4})(\d)/, '$1-$2');
    setCnpjFormValue(value);
  };

  const handleAddCode = () => {
    setErrorMessage('');
    setCnpjError('');
    setOpenCNPJForm(true);
  };

  const handleAddCancelCode = () => {
    setCnpjError('');
    setErrorMessage('');
    setOpenCNPJForm(false);
    setError(false);
  };

  const handleSave = () => {
    setCnpjError('');
    setErrorMessage('');
    if (!validaCNPJ.isValid(cnpjFormValue)) {
      setError(true);
      setErrorMessage('CNPJ inválido');
      return;
    }
    if (cnpjList.includes(cnpjFormValue)) {
      setError(true);
      setErrorMessage('CNPJ já adicionado');
      setCnpjFormValue('');
      return;
    }
    setCnpjList([...cnpjList, cnpjFormValue]);
    setCnpjFormValue('');
  };

  const handleRemoveSavedCode = (index: number) => {
    setCnpjError('');
    setErrorMessage('');
    const newList = [...cnpjList];
    newList.splice(index, 1);
    setCnpjList(newList);
  };

  return (
    <div className="flex-col">
      <div className="flex items-center">
        <label
          htmlFor="cpf"
          className="block text-smp font-semibold leading-6 top-digital-content-color"
        >
          CNPJ's selecionados *
        </label>
        <Plus className="cursor-pointer w-4 h-4" onClick={handleAddCode} />
      </div>
      {openCNPJForm && (
        <div className="mt-2 flex items-center gap-1">
          <input
            type="text"
            value={cnpjFormValue}
            onChange={(e) => handleCNPJChange(e)}
            className="customInputText w-2/3"
          />
          <X className="cursor-pointer w-4 h-4" onClick={handleAddCancelCode} />
          <Save className="cursor-pointer w-4 h-4" onClick={handleSave} />
        </div>
      )}
      {error && (
        <div className="block text-smp font-semibold leading-6 text-red-500">
          {errorMessage}
        </div>
      )}

      {cnpjList.length > 0 ? (
        <div>
          <h3 className="block text-smp font-semibold leading-6 top-digital-content-color">
            CNPJ's Salvos:
          </h3>
          <ul>
            {cnpjList.map((cnpj, index) => (
              <div className="flex items-center" key={cnpj}>
                <li
                  className="block text-smp font-normal leading-6 top-digital-content-color"
                  key={index}
                >
                  {cnpj}
                </li>
                <X
                  className="cursor-pointer w-4 h-4"
                  onClick={() => handleRemoveSavedCode(index)}
                />
              </div>
            ))}
          </ul>
        </div>
      ) : (
        <p className="block text-smp font-normal leading-6 top-digital-content-color">
          Nenhum CNPJ adicionado
        </p>
      )}
    </div>
  );
};

export default CNPJSelection;
