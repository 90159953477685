import { api, requestConfig } from '../utils/config';

//get All Equipamentos

const getConfig = async (token: string | undefined) => {
  const config = requestConfig('GET', null, token);

  try {
    const res = await fetch(api + '/configstations', config)
      .then((res) => res.json())
      .catch((err) => err);

    return res;
  } catch (error) {
    console.error(error);
  }
};

const getChildConfig = async (user: any, token: string | undefined) => {
  const config = requestConfig('POST', user, token);

  try {
    const res = await fetch(api + '/configstations/childuser', config)
      .then((res) => res.json())
      .catch((err) => err);

    return res;
  } catch (error) {
    //console.log(error);
  }
};

//get visible fields customized by user
const getVisibleFields = async (token: string | undefined) => {
  const config = requestConfig('GET', null, token);

  try {
    const res = await fetch(api + '/configstations/visible', config)
      .then((res) => res.json())
      .catch((err) => err);

    return res;
  } catch (error) {
    console.error(error);
  }
};

//getAllEquipamentosByLocation

const setConfig = async (configs: any, token: string | undefined) => {
  const config = requestConfig('PUT', configs, token);
  try {
    const res = await fetch(api + '/configstations', config)
      .then((res) => res.json())
      .catch((err) => err);
    return res;
  } catch (error) {
    //console.log(error);
  }
};

const alterMobileStations = async (data: any, token: string | undefined) => {
  const config = requestConfig('PUT', data, token);
  try {
    const res = await fetch(api + '/configstations/mobilestation', config)
      .then((res) => res.json())
      .catch((err) => err);
    return res;
  } catch (error) {
    //console.log(error);
  }
};

//showMobileStations

const ConfigStationService = {
  getConfig,
  getChildConfig,
  setConfig,
  getVisibleFields,
  alterMobileStations,
};

export default ConfigStationService;
