import { api, requestConfig } from '../utils/config';
import Cookies from 'js-cookie';

const logout = () => {
  Cookies.remove('user');
};

const login = async (data: any): Promise<any> => {
  const config = requestConfig('POST', data);

  try {
    const res = await fetch(api + '/users/login', config)
      .then((res) => res.json())
      .catch((err) => err);

    if (res.token) {
      const cookieOptions: Cookies.CookieAttributes = {
        secure: true,
        sameSite: 'strict',
      };

      if (data.rememberMe) {
        cookieOptions.expires = 90; // 90 dias
      }

      Cookies.set('user', res.token, cookieOptions);
    }

    return res;
  } catch (error) {
    //console.log(error);
  }
};

const recovery = async (data: any): Promise<any> => {
  const config = requestConfig('POST', data);

  try {
    const res = await fetch(api + '/users/forgot/recovery', config)
      .then((res) => res.json())
      .catch((err) => err);

    return res;
  } catch (error) {
    return error;
  }
};

const sendRecovery = async (email: { email: string }): Promise<any> => {
  const config = requestConfig('POST', email);

  try {
    const res = await fetch(api + '/users/forgot', config)
      .then((res) => res.json())
      .catch((err) => err);

    return res;
  } catch (error) {
    return error;
  }
};

const recoveryCheck = async (tokenConfirm?: string): Promise<any> => {
  const config = requestConfig('GET', null);
  try {
    const res = await fetch(
      api + '/users/forgot/recovery/' + tokenConfirm,
      config,
    );

    return res.json();
  } catch (error) {
    return error;
  }
};

const LoginService = {
  logout,
  login,
  sendRecovery,
  recoveryCheck,
  recovery,
};

export default LoginService;
