type Props = {
  timestamp: string;
};

const DataUnixTimeStamp = ({ timestamp }: Props) => {
  /**
   * Verifica se o timestamp está no formato ISO (YYYY-MM-DD).
   * Adiciona 6 horas para ajustar ao fuso horário do Brasil.
   */
  let date: Date;

  if (/^\d{4}-\d{2}-\d{2}$/.test(timestamp)) {
    // Trata formato ISO e adiciona hora para evitar fuso incorreto
    date = new Date(`${timestamp}T00:00:00Z`);
  } else {
    // Trata timestamp UNIX
    date = new Date(parseInt(timestamp));
  }

  // Adiciona 6 horas ao horário UTC para ajustar ao Brasil
  date.setHours(date.getHours() + 6);

  return (
    <div className="text-top-digital-hover font-semibold text-smp">
      {date.toLocaleDateString('pt-BR')}
    </div>
  );
};

export default DataUnixTimeStamp;
