import { useEffect } from 'react';
import useAppDispatch from '../../hooks/useAppDispatch';
import useAppSelector from '../../hooks/useAppSelector';
import { systemSelector, getSystemsByStation } from '../../slices/SystemSlice';
import { documentSelector } from '../../slices/DocumentSlice';
import { linkSystemDocSelector } from '../../slices/LinkSystemDocSlice';

//component
import SystemTable from './SystemTable';

//context
import { useGlobalContext } from '../../hooks/useGlobalContext';
import Pagination from '../../components/Table/Pagination';

type Props = {
  stationId: string | undefined;
  refreshSystems: boolean;
};

const System = ({ stationId, refreshSystems }: Props) => {
  const dispatch = useAppDispatch();
  const { systems, labels, loading, totalSystems } =
    useAppSelector(systemSelector);
  const { insertSuccess, deleteSuccess } = useAppSelector(documentSelector);
  const { successLinkSystem } = useAppSelector(linkSystemDocSelector);
  const { globalState, dispatchGlobalState } = useGlobalContext();
  useEffect(() => {
    dispatchGlobalState({ type: 'SET_SYSTEM_PAGE', systemPage: 1 });
  }, [dispatchGlobalState]);

  useEffect(() => {
    if (typeof stationId === 'string') {
      const objData = {
        stationId,
        page: globalState.systemPage || 1,
      };
      dispatch(getSystemsByStation(objData));
    }
  }, [
    stationId,
    globalState.systemPage,
    dispatch,
    refreshSystems,
    insertSuccess,
    deleteSuccess,
    successLinkSystem,
  ]);

  // if (loading) {
  //   return <div>Aguarde.....</div>;
  // }
  return (
    <>
      <div className="hidden md:block mb-4">
        <div className="flex relative">
          {loading ? (
            <h1 className="text-base text-top-digital-content-color font-semibold mb-2 font-top-digital-content">
              Sistemas instalados na Estação. Aguarde...
            </h1>
          ) : (
            <h1 className="text-base text-top-digital-content-color font-semibold mb-2 font-top-digital-content">
              Sistemas instalados na Estação
            </h1>
          )}
        </div>
      </div>
      <div className="md:hidden block">
        <div className="flex justify-center items-center">
          {loading ? (
            <h1 className="text-sm text-top-digital-content-color font-semibold mb-2 font-top-digital-content">
              Sistemas instalados na Estação. Aguarde...
            </h1>
          ) : (
            <h1 className="text-sm text-top-digital-content-color font-semibold mb-2 font-top-digital-content">
              Sistemas instalados na Estação
            </h1>
          )}
        </div>
      </div>
      <div className="bg-top-digital-secondary-lighter p-4 flex flex-col inset-0 mr-[-18px] rounded-lg">
        <SystemTable systems={systems} labels={labels} />
        <Pagination
          page={globalState.systemPage}
          globalPageParam="systemPage"
          totalResults={totalSystems}
          pageSize={10}
          localStoragePageParam="lsSystemPage"
          bg="bg-top-digital-secondary-lighter"
        />
      </div>
    </>
  );
};

export default System;
