import { createSlice, Dispatch } from '@reduxjs/toolkit';
import UnsubscribeService from '../services/UnsubscribeService';
import { RootState } from '../store';
import { IUnSubscribe } from '../Interfaces/IUnsubscribe';

const initialState: IUnSubscribe = {
  isCheckingToken: false,
  isSettingUnsubscribe: false,
  messageReceivingSettings: {
    isTokenValid: false,
    email: '',
    id: '',
    receiveLicensingNews: false,
    receiveNews: false,
    acceptAlertEmails: false,
  },
  isUnsubscribeSet: false,
  hasError: false,
};

export const checkToken = (tokenConfirm?: string) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(checkTokenRequested());

      const res = await UnsubscribeService.checkToken(tokenConfirm);
      if (res.receivingSettings)
        return dispatch(checkTokenSucceeded(res.receivingSettings));

      return dispatch(checkTokenFailed());
    } catch (err) {
      return dispatch(checkTokenFailed());
    }
  };
};

export const setUnsubscribe = (options: any) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setUnsubscribeRequested());

      const res = await UnsubscribeService.setUnsubscribe(options);
      if (res.error) return dispatch(setUnsubscribeFailed(res.error));

      if (res.receivingSettings)
        return dispatch(setUnsubscribeSucceeded(res.receivingSettings));

      return dispatch(setUnsubscribeFailed(res));
    } catch (err: any) {
      return dispatch(setUnsubscribeFailed(err));
    }
  };
};

export const UnsubscribeSlice = createSlice({
  name: 'unsubscribe',
  initialState,
  reducers: {
    reset: () => {
      return initialState;
    },
    checkTokenRequested: (state) => {
      state.isCheckingToken = true;
    },
    checkTokenSucceeded: (state, payload: any) => {
      state.isCheckingToken = false;
      state.messageReceivingSettings = payload.payload;
    },
    checkTokenFailed: (state) => {
      state.isCheckingToken = false;
    },
    setUnsubscribeRequested: (state) => {
      state.isSettingUnsubscribe = true;
    },
    setUnsubscribeSucceeded: (state, payload: any) => {
      state.isSettingUnsubscribe = false;
      state.isUnsubscribeSet = true;
      state.messageReceivingSettings = payload.payload;
    },
    setUnsubscribeFailed: (state, payload: any) => {
      state.isSettingUnsubscribe = false;
      state.isUnsubscribeSet = false;
      state.hasError = true;
      state.errorMessage = payload.error;
    },
  },
});

export const {
  reset,
  checkTokenRequested,
  checkTokenSucceeded,
  checkTokenFailed,
  setUnsubscribeRequested,
  setUnsubscribeSucceeded,
  setUnsubscribeFailed,
} = UnsubscribeSlice.actions;
export const unsubscribeSelector = (state: RootState) =>
  state.UnsubscribeReducer;
export default UnsubscribeSlice.reducer;
