import { api, requestConfig } from '../utils/config';

//get data by CEP
const generateMetabaseURL = async (
  token: string | undefined,
  report: string,
) => {
  const config = requestConfig('GET', null, token);
  try {
    const res = await fetch(api + '/dashboard/' + report, config)
      .then((res) => res.json())
      .catch((err) => err);
    return res;
  } catch (error) {
    console.error(error);
  }
};

//post
const exportExcel = async (
  dashURL: { url: string; report: string },
  token: string | undefined,
) => {
  const config = requestConfig('POST', dashURL, token);
  try {
    const response = await fetch(api + '/dashboard/export', config);
    if (response.ok) {
      const blob = await response.blob();
      const fileURL = window.URL.createObjectURL(blob);
      let alink = document.createElement('a');
      alink.href = fileURL;
      const date = new Date();
      const day = date.getDate().toString().padStart(2, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Janeiro é 0!
      const year = date.getFullYear();
      const hours = date.getHours().toString().padStart(2, '0');
      const minutes = date.getMinutes().toString().padStart(2, '0');
      const seconds = date.getSeconds().toString().padStart(2, '0');
      alink.download = `dashboard_${dashURL.report}_${day}_${month}_${year}_${hours}_${minutes}_${seconds}.pdf`;
      document.body.appendChild(alink); // Garantir que o link esteja no DOM para funcionar em todos os navegadores
      alink.click();
      window.URL.revokeObjectURL(fileURL); // Limpar o objeto URL
      document.body.removeChild(alink); // Limpar o link do DOM
      return { download: true };
    } else {
      // Tratar a resposta não-ok (erro)
      const errorText = await response.text();
      throw new Error(errorText);
    }
  } catch (error: any) {
    return { error: error.message };
  }
};

const DashBoardService = {
  generateMetabaseURL,
  exportExcel,
};

export default DashBoardService;
