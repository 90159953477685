import React, { useState } from 'react';
import { IdAnatel } from '../../Interfaces/ILocation';
import { FaUpload } from 'react-icons/fa';
import useIsomorphicLayoutEffect from '../../hooks/useIsomorphicLayoutEffect';
import useAppDispatch from '../../hooks/useAppDispatch';
import useAppSelector from '../../hooks/useAppSelector';
import {
  networkFilesSelector,
  uploadFile,
  getFiles,
} from '../../slices/NetworkFilesSlice';
import PolylinesStationsFileList from './PolylinesStationsFileList';
import Enabled from '../Utils/Enabled';

type Props = {
  channels: { TX: IdAnatel; RX: IdAnatel }[];
  network: number;
  distance?: number;
};

const PolylinesStations: React.FC<Props> = ({
  channels,
  network,
  distance,
}) => {
  const [showUpload, setShowUpload] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const dispatch = useAppDispatch();
  const { loading } = useAppSelector(networkFilesSelector);
  const [showFiles, setShowFiles] = useState(false);

  const filteredChannels = channels.filter(
    (channel) => channel.TX && channel.RX,
  );

  const handleUploadToggle = () => {
    setShowUpload(!showUpload);
  };

  const toggleFiles = () => {
    setShowFiles(!showFiles);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0] || null;
    setSelectedFile(file);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (selectedFile) {
      const formData = new FormData();
      formData.append('file', selectedFile, selectedFile.name);
      formData.append('network', network.toString());
      dispatch(uploadFile(formData)).then(() => {
        setShowUpload(false);
      });
    }
  };

  const formatDistance = (distance: number | undefined) => {
    if (distance === undefined) return 'Distância não disponível';

    return distance % 1 === 0 ? distance.toString() : distance.toFixed(2);
  };

  useIsomorphicLayoutEffect(() => {
    dispatch(getFiles(network));
  }, [dispatch, network]);

  return (
    <>
      <div className="outline-none" tabIndex={0}>
        <div className="text-center mb-4 text-lg font-semibold">
          <div className="outline-none" tabIndex={0}>
            <div className="text-center mb-4 text-lg font-semibold">
              Rede {network} - {formatDistance(distance)} Km
            </div>
          </div>
        </div>
        {loading && <p>Carregando...</p>}
        <div className="flex items-center justify-center space-x-2">
          <div
            className="w-full flex items-start justify-start mb-2"
            onClick={handleUploadToggle}
          >
            <div className="flex gap-2 justify-center items-center cursor-pointer">
              <FaUpload title="Upload do arquivo" /> novo arquivo
            </div>
          </div>
          <div className="flex items-center border-b-1 gap-1">
            <div onClick={toggleFiles}>
              <Enabled enabled={showFiles} />
            </div>
            <span className="text-smp whitespace-nowrap">Arquivos</span>
          </div>
        </div>
        {showUpload && (
          <div className="flex items-center justify-center mt-2">
            <form
              onSubmit={handleSubmit}
              className="flex items-center justify-center space-x-2"
              encType="multipart/form-data"
            >
              <input
                type="file"
                className="customInputFile"
                onChange={handleFileChange}
              />
              <button type="submit" className="customButton font-normal h-7">
                Enviar
              </button>
            </form>
          </div>
        )}
        {showFiles && <PolylinesStationsFileList />}
        {filteredChannels.map((channel, idx) => (
          <div
            key={idx}
            className={`mb-4 mt-6 ${idx % 2 ? 'bg-white' : 'bg-top-digital-secondary-light'}`}
          >
            <p>
              <strong className="text-base font-bold">
                {idx + 1} -{' '}
                {channel.TX.LabelStation
                  ? channel.TX.LabelStation
                  : channel.TX.EnderecoEstacao}
                (TX) x{' '}
                {channel.RX.LabelStation
                  ? channel.RX.LabelStation
                  : channel.RX.EnderecoEstacao}
                (RX)
              </strong>
            </p>
            <p>N&ordm; Estação: {channel.TX.NumEstacao})</p>
            <p>
              Frequência TX: {channel.TX.FreqTxMHz} MHz -{' '}
              {channel.TX.Polarizacao}
            </p>
            <p>
              Frequência RX: {channel.RX.FreqTxMHz} MHz -{' '}
              {channel.RX.Polarizacao}
            </p>
            <p>Designação de Emissão: {channel.TX.DesignacaoEmissao}</p>
            <p>Fabricante Transmissor: {channel.TX.FabricanteTransmissor}</p>
            <p className="mb-1">
              Modelo Transmissor: {channel.TX.ModeloEquipamentoTransmissor}
            </p>
          </div>
        ))}
      </div>
    </>
  );
};

export default PolylinesStations;
