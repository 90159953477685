import React, { ChangeEvent, Dispatch } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { TConfigStation } from '../../Interfaces/IConfigStation';
import { TConfigSystem } from '../../Interfaces/IConfigSystem';

type Props = {
  editForm: TConfigStation | TConfigSystem;
  setEditForm: Dispatch<React.SetStateAction<TConfigStation | TConfigSystem>>;
  objInit: any;
  setFilterForm: Dispatch<React.SetStateAction<boolean>>;
};

const Filter = ({ editForm, setEditForm, objInit, setFilterForm }: Props) => {
  const handleOnChangeSelecao = (
    event: ChangeEvent<{}>,
    newValue: readonly any[],
  ) => {
    if (newValue.length === 0) {
      setFilterForm(false);
      setEditForm({ ...editForm, filtervalue: '' });
    } else {
      setEditForm({ ...editForm, filtervalue: [...newValue] });
    }
  };

  const theme = createTheme({
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: '#4A858C',
              },
              '&:hover fieldset': {
                borderColor: '#4A858C',
              },
              '&.Mui-focused fieldset': {
                borderColor: '#4A858C',
              },
            },
          },
        },
      },
    },
  });

  switch (editForm.filtertype) {
    case 'checkbox':
      return <div className="flex flex-col">oi</div>;
    case 'selecao':
      switch (editForm.campo) {
        case 'NumServico':
          return (
            <ThemeProvider theme={theme}>
              <Autocomplete
                multiple
                limitTags={2}
                isOptionEqualToValue={(option, value) =>
                  option.codServico === value.codServico
                }
                value={
                  editForm.filtervalue !== undefined &&
                  editForm.filtervalue !== ''
                    ? (editForm.filtervalue as any[])
                    : []
                }
                id="multiple-limit-tags"
                options={objInit.services}
                onChange={(event, newValue) =>
                  handleOnChangeSelecao(event, newValue)
                }
                getOptionLabel={(option) => `${option.codServico}`}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={editForm.campo}
                    placeholder="buscar..."
                  />
                )}
              />
            </ThemeProvider>
          );
        case 'CodMunicipio':
          return (
            <ThemeProvider theme={theme}>
              <Autocomplete
                multiple
                limitTags={2}
                isOptionEqualToValue={(option, value) =>
                  option.codMunicipio === value.codMunicipio
                }
                value={
                  editForm.filtervalue !== undefined &&
                  editForm.filtervalue !== ''
                    ? (editForm.filtervalue as any[])
                    : []
                }
                id="multiple-limit-tags"
                options={objInit.municipios}
                onChange={(event, newValue) =>
                  handleOnChangeSelecao(event, newValue)
                }
                getOptionLabel={(option) =>
                  `${option.nomMunicipio}(${option.codMunicipio})`
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={editForm.campo}
                    placeholder="buscar..."
                  />
                )}
              />
            </ThemeProvider>
          );

        case 'SiglaUf':
          return (
            <ThemeProvider theme={theme}>
              <Autocomplete
                multiple
                limitTags={2}
                isOptionEqualToValue={(option, value) => option.UF === value.UF}
                value={
                  editForm.filtervalue !== undefined &&
                  editForm.filtervalue !== ''
                    ? (editForm.filtervalue as any[])
                    : []
                }
                id="multiple-limit-tags"
                options={objInit.uf}
                onChange={(event, newValue) =>
                  handleOnChangeSelecao(event, newValue)
                }
                getOptionLabel={(option) => `${option.nome}`}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={editForm.campo}
                    placeholder="buscar..."
                  />
                )}
              />
            </ThemeProvider>
          );

        case 'CodTiposClasseEstacao':
          return (
            <ThemeProvider theme={theme}>
              <Autocomplete
                multiple
                limitTags={2}
                isOptionEqualToValue={(option, value) =>
                  option.cod === value.cod
                }
                value={
                  editForm.filtervalue !== undefined &&
                  editForm.filtervalue !== ''
                    ? (editForm.filtervalue as any[])
                    : []
                }
                id="multiple-limit-tags"
                options={objInit.classEstacao}
                onChange={(event, newValue) =>
                  handleOnChangeSelecao(event, newValue)
                }
                getOptionLabel={(option) => `${option.classe}`}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={editForm.campo}
                    placeholder="buscar..."
                  />
                )}
              />
            </ThemeProvider>
          );

        case 'Label':
          if (
            Object.keys(objInit.label).length === 0 &&
            objInit.label.constructor === Object
          ) {
            return (
              <div className="px-3 py-3.5 text-left text-smp font-semibold top-digital-content-color">
                Não existe nenhuma label definida.
              </div>
            );
          }
          return (
            <ThemeProvider theme={theme}>
              <Autocomplete
                multiple
                limitTags={2}
                isOptionEqualToValue={(option, value) =>
                  option.cod === value.cod
                }
                value={
                  editForm.filtervalue !== undefined &&
                  editForm.filtervalue !== ''
                    ? (editForm.filtervalue as any[])
                    : []
                }
                id="multiple-limit-tags"
                options={objInit.label}
                onChange={(event, newValue) =>
                  handleOnChangeSelecao(event, newValue)
                }
                getOptionLabel={(option) => `${option.valor}`}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={editForm.campo}
                    placeholder="buscar..."
                  />
                )}
              />
            </ThemeProvider>
          );

        case 'NomeEntidade':
          return (
            <ThemeProvider theme={theme}>
              <Autocomplete
                multiple
                limitTags={2}
                isOptionEqualToValue={(option, value) =>
                  option.cod === value.cod
                }
                value={
                  editForm.filtervalue !== undefined &&
                  editForm.filtervalue !== ''
                    ? (editForm.filtervalue as any[])
                    : []
                }
                id="multiple-limit-tags"
                options={objInit.entidade}
                onChange={(event, newValue) =>
                  handleOnChangeSelecao(event, newValue)
                }
                getOptionLabel={(option) => `${option.valor}`}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={editForm.campo}
                    placeholder="buscar..."
                  />
                )}
              />
            </ThemeProvider>
          );

        case 'CNPJ':
          return (
            <ThemeProvider theme={theme}>
              <Autocomplete
                multiple
                limitTags={2}
                isOptionEqualToValue={(option, value) =>
                  option.cod === value.cod
                }
                value={
                  editForm.filtervalue !== undefined &&
                  editForm.filtervalue !== ''
                    ? (editForm.filtervalue as any[])
                    : []
                }
                id="multiple-limit-tags"
                options={objInit.cnpj}
                onChange={(event, newValue) =>
                  handleOnChangeSelecao(event, newValue)
                }
                getOptionLabel={(option) => `${option.valor}`}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={editForm.campo}
                    placeholder="buscar..."
                  />
                )}
              />
            </ThemeProvider>
          );

        case 'NumFistel':
          return (
            <ThemeProvider theme={theme}>
              <Autocomplete
                multiple
                limitTags={2}
                isOptionEqualToValue={(option, value) =>
                  option.cod === value.cod
                }
                value={
                  editForm.filtervalue !== undefined &&
                  editForm.filtervalue !== ''
                    ? (editForm.filtervalue as any[])
                    : []
                }
                id="multiple-limit-tags"
                options={objInit.fistel}
                onChange={(event, newValue) =>
                  handleOnChangeSelecao(event, newValue)
                }
                getOptionLabel={(option) => `${option.valor}`}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={editForm.campo}
                    placeholder="buscar..."
                  />
                )}
              />
            </ThemeProvider>
          );

        case 'NumEstacao':
          return (
            <ThemeProvider theme={theme}>
              <Autocomplete
                multiple
                limitTags={2}
                isOptionEqualToValue={(option, value) =>
                  option.cod === value.cod
                }
                value={
                  editForm.filtervalue !== undefined &&
                  editForm.filtervalue !== ''
                    ? (editForm.filtervalue as any[])
                    : []
                }
                id="multiple-limit-tags"
                options={objInit.numestacao}
                onChange={(event, newValue) =>
                  handleOnChangeSelecao(event, newValue)
                }
                getOptionLabel={(option) => `${option.valor}`}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={editForm.campo}
                    placeholder="buscar..."
                  />
                )}
              />
            </ThemeProvider>
          );
        default:
          return null;
      }
    default:
      return null;
  }
};

export default Filter;
