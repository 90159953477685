import { LogOut } from 'lucide-react';

interface ILogoutProps {
  handleLogout: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const Logout = ({ handleLogout }: ILogoutProps) => (
  <button
    onClick={(e) => handleLogout(e)}
    className="mb-2 text-sm self-start hover:text-top-digital-link-hover lg:ml-4 lg:mr-5"
  >
    <div className="flex gap-1 justify-center items-center">
      Sair{' '}
      <span aria-hidden="true">
        <LogOut className="w-4 h-4" />
      </span>
    </div>
  </button>
);

export default Logout;
