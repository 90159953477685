//componnents
import { useNavigate, useParams } from 'react-router-dom';

//Hooks
import useAppDispatch from '../../hooks/useAppDispatch';
import useAppSelector from '../../hooks/useAppSelector';

import {
  registerSelector,
  confirmationToken,
  registerReset,
} from '../../slices/RegisterSlice';
import { useEffect, useState } from 'react';
import ImageOffLine from '../../presentational/image-main/ImageMain';

const ConfirmationToken = () => {
  const { registerLoading, registerError, registerSuccess, registerGreeting } =
    useAppSelector(registerSelector);
  const { tokenConfirm } = useParams();
  const [greetingText, setGreetingText] = useState<string>('');

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (registerGreeting) {
      if (registerGreeting.gender === 'male') {
        setGreetingText('Prezado');
      } else if (registerGreeting.gender === 'female') {
        setGreetingText('Prezada');
      } else if (registerGreeting.gender === 'other') {
        setGreetingText('Olá');
      }
    }
  }, [registerGreeting]);

  useEffect(() => {
    if (
      tokenConfirm !== undefined &&
      tokenConfirm !== null &&
      tokenConfirm !== ''
    )
      dispatch(confirmationToken(tokenConfirm));
  }, [dispatch, tokenConfirm]);

  useEffect(() => {
    let timerId: NodeJS.Timeout;
    if (registerSuccess) {
      timerId = setTimeout(() => {
        navigate('/login');
        dispatch(registerReset());
      }, 5000);
      // Retorna uma função de limpeza que será chamada quando o componente for desmontado
      return () => {
        clearTimeout(timerId);
      };
    }
  }, [dispatch, tokenConfirm, registerSuccess, navigate]);

  if (registerLoading) {
    return <p>Carregando...</p>;
  }

  return (
    <div className="flex w-full">
      {registerSuccess && (
        <div className="flex justify-center items-start h-screen mt-10">
          <div className="w-4/5">
            <p className="text-sm text-justify">
              {greetingText}{' '}
              {registerGreeting !== null &&
              registerGreeting.name !== undefined &&
              registerGreeting.name !== ''
                ? registerGreeting.name
                : ''}
              ,
              <br />
              E-mail confirmado com sucesso. Você já pode acessar o sistema.
              Você será redirecionado para a página de login em 5 segundos.....
            </p>
          </div>
        </div>
      )}
      {registerError && (
        <div className="flex justify-center items-start h-screen mt-10">
          <div className="w-4/5">
            <p className="text-sm text-justify">
              Ocorreu um erro ao confirmar o cadastro. Por favor, tente
              novamente. Caso o erro persista, entre em contato com o suporte
              através do email{' '}
              <label>
                <a href="mailto:agiliza@topdigital.com.br">
                  agiliza@topdigital.com.br
                </a>
              </label>
            </p>
          </div>
        </div>
      )}
      <ImageOffLine />
    </div>
  );
};

export default ConfirmationToken;
