import { Edit } from 'lucide-react';
import { TSystem } from '../../Interfaces/ISystem';
import DataUnixTimeStamp from '../../components/Utils/DataUnixTimeStamp';
import { useGlobalContext } from '../../hooks/useGlobalContext';

type Props = {
  index: number;
  label: string;
  campo: string;
  system: TSystem;
  reportDocuments?: boolean;
};

const SystemField = ({
  index,
  label,
  campo,
  system,
  reportDocuments = false,
}: Props) => {
  const { globalState, dispatchGlobalState } = useGlobalContext();

  const handleOpenLabelSystemForm = (idSystem: string, label: string) => {
    window.scroll({
      top: 0,
      behavior: 'smooth',
    });
    dispatchGlobalState({
      type: 'OPEN_FORM',
      currentForm: 'openedLabelSystemForm',
      labelSystem: label,
      idSystem: idSystem,
    });
  };

  return (
    <>
      <div
        className={`${
          index % 2 ? 'bg-white' : 'bg-top-digital-secondary-light'
        }  text-top-digital-content-color font-top-digital-content text-smp font-semibold leading-6`}
      >
        {label}
      </div>
      <div
        className={`${
          index % 2 ? 'bg-white' : 'bg-top-digital-secondary-light'
        } text-top-digital-content-color font-normal font-top-digital-content text-sm`}
      >
        {campo === 'Label' && (
          <div
            className="w-full text-center flex items-center gap-2"
            onClick={() => {
              handleOpenLabelSystemForm(
                system._id,
                system[campo as keyof TSystem]!.toString(),
              );
            }}
          >
            {system[campo as keyof TSystem]?.toString() !== '' ? (
              system[campo as keyof TSystem]?.toString()
            ) : reportDocuments ? (
              '-'
            ) : (
              <button
                className={`${
                  system._id === globalState.idSystem
                    ? 'text-top-digital-link-hover'
                    : 'hover:text-top-digital-link-hover'
                }`}
                title="Coloque um apelido no sistema"
              >
                <Edit className="h-4 w-4" />
              </button>
            )}
          </div>
        )}{' '}
        {campo === 'DataValidade' ? (
          <DataUnixTimeStamp
            timestamp={system[campo as keyof TSystem]!.toString()}
          />
        ) : (
          <div className="flex gap-2 items-center text-smp">
            {system[campo as keyof TSystem] !== undefined
              ? system[campo as keyof TSystem]!.toString()
              : campo + ' não definido'}{' '}
          </div>
        )}
      </div>
    </>
  );
};

export default SystemField;
