export const api: string = process.env.REACT_APP_URL_BASE_API
  ? String(process.env.REACT_APP_URL_BASE_API)
  : '';
export const urlapp: string = process.env.REACT_APP_URL_BASE
  ? String(process.env.REACT_APP_URL_BASE)
  : '';
export const urlappbackend: string = process.env.REACT_APP_URL_BASE_API
  ? String(process.env.REACT_APP_URL_BASE_API)
  : '';
export const mode: string = process.env.REACT_APP_MODE
  ? String(process.env.REACT_APP_MODE)
  : 'dev';

export const requestConfig = (
  method: string,
  data: any,
  token: string | undefined | null = null,
  image: any | null = null,
) => {
  let config: any = {};

  if (image) {
    config = {
      method: method,
      body: data,
      headers: {},
    };
  } else if (method === 'DELETE' || data === null) {
    config = {
      method: method,
      headers: {},
    };
  } else {
    config = {
      method: method,
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
      },
    };
  }

  if (token) {
    config = {
      ...config,
      headers: { ...config.headers, Authorization: `Bearer ${token}` },
    };
  }

  return config;
};
