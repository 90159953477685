//Icon
import { Link } from 'lucide-react';

//types
import { TDocumentStationPage } from '../../Interfaces/IDocument';

//context
import { useGlobalContext } from '../../hooks/useGlobalContext';

type Props = {
  doc: TDocumentStationPage;
};

const LinkDocument = ({ doc }: Props) => {
  const { dispatchGlobalState } = useGlobalContext();

  /*Inicio Formulário de Vinculação dos Sistemas*/
  const handleOpenFormLinkSystem = (idDocument: string) => {
    dispatchGlobalState({
      type: 'OPEN_FORM',
      currentForm: 'openSystemLinkForm',
      idDocument: idDocument,
    });
  };
  /*Fim Formulário de Vinculação dos Sistemas*/

  return (
    <div className="flex gar-2">
      {doc.toSystem ? (
        <div className="flex justify-center items-center gap-1">
          <Link
            className="cursor-pointer text-top-digital-hover w-5 h-5"
            onClick={() => {
              handleOpenFormLinkSystem(doc._id);
            }}
          />
          <span>{doc.typeName || '-'}</span>
        </div>
      ) : (
        <span>{doc.typeName || '-'}</span>
      )}
    </div>
  );
};

export default LinkDocument;
