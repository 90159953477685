//redux
import useAppSelector from '../../hooks/useAppSelector';
import { documentSelector } from '../../slices/DocumentSlice';

//components
import DocIcon from '../Utils/DocIcon';
import DateFormat from '../Utils/DateFormat';
import LinkDocument from './LinkDocument';
import DownloadDocument from './DownloadDocument';

//types
import { TDocumentStationPage } from '../../Interfaces/IDocument';

//react-table
import { ColumnDef } from '@tanstack/react-table';

import { useMemo } from 'react';
import DocumentSystemsCount from './DocumentSystemsCount';
import DocumentTable from './DocumentTable';
import DeleteDocument from './DeleteDocument';
import { useAuth } from '../../hooks/useAuth';

//fim react-table

const DocumentList = () => {
  const { role } = useAuth().userData;
  //redux
  const { documents } = useAppSelector(documentSelector);

  const columns = useMemo<ColumnDef<TDocumentStationPage>[]>(
    () => [
      {
        accessorKey: 'title',
        header: 'Documento',
        filterFn: 'equals',
      },
      {
        accessorKey: 'createdBy',
        header: 'Criado por',
        filterFn: 'equals',
      },
      {
        accessorKey: 'systems',
        header: 'Sistemas vinculados',
        cell: (row) => {
          return <DocumentSystemsCount systems={row.row.getValue('systems')} />;
        },
        filterFn: 'equals',
      },
      {
        accessorKey: 'typeName',
        header: 'Tipo',
        cell: (row) => {
          return <LinkDocument doc={row.row.original} />;
        },
        filterFn: 'equals',
      },
      {
        accessorKey: 'extension',
        header: 'Extensão',
        cell: (row) => {
          return <DocIcon extension={row.row.getValue('extension')} />;
        },
        filterFn: 'equals',
      },
      {
        accessorKey: 'createdAt',
        header: 'Data Criação',
        cell: (row) => {
          return <DateFormat data={row.row.getValue('createdAt')} />;
        },
        filterFn: 'equals',
      },
      {
        accessorKey: 'downloadDocument',
        header: 'Download',
        cell: (row) => {
          return <DownloadDocument doc={row.row.original} />;
        },
        filterFn: 'equals',
      },
      ...(role !== 'visualizador'
        ? [
            {
              accessorKey: 'deleteDocument',
              header: 'Remover',
              cell: (row: any) => {
                return <DeleteDocument doc={row.row.original} />;
              },
            },
          ]
        : []),
    ],
    [role],
  );

  return <DocumentTable data={documents} columns={columns} />;
};

export default DocumentList;
