import React, { MouseEvent } from 'react';
import { IFieldConfig, TStation } from '../../Interfaces/IStation';
import { useGlobalContext } from '../../hooks/useGlobalContext';
import { Edit, Save, X } from 'lucide-react';
import { TConfigStation } from '../../Interfaces/IConfigStation';
import { useAuth } from '../../hooks/useAuth';

type Props = {
  home: boolean;
  station: TStation;
  label: IFieldConfig | TConfigStation;
  setEditStation: React.Dispatch<React.SetStateAction<string>>;
  editStation: string;
  setEditLabelStation: React.Dispatch<React.SetStateAction<string>>;
  editLabelStation: string;
  setEditResTecnicoTorreNome: React.Dispatch<React.SetStateAction<string>>;
  editResTecnicoTorreNome: string;
  setEditResTecnicoTorreTelefone: React.Dispatch<React.SetStateAction<string>>;
  editResTecnicoTorreTelefone: string;
  setEditResTecnicoTorreEmail: React.Dispatch<React.SetStateAction<string>>;
  editResTecnicoTorreEmail: string;
  setEditAlturaTorre: React.Dispatch<React.SetStateAction<string>>;
  editAlturaTorre: string;
  setEditAEVTorre: React.Dispatch<React.SetStateAction<string>>;
  editAEVTorre: string;
  setEditAEVFundacao: React.Dispatch<React.SetStateAction<string>>;
  editAEVFundacao: string;
  setLabelStation: React.Dispatch<
    React.SetStateAction<TStation[keyof TStation]>
  >;
  labelStation: string | number | string[];
  setResTecnicoTorreNome: React.Dispatch<
    React.SetStateAction<TStation[keyof TStation]>
  >;
  resTecnicoTorreNome: string | number | string[];
  setResTecnicoTorreTelefone: React.Dispatch<
    React.SetStateAction<TStation[keyof TStation]>
  >;
  resTecnicoTorreTelefone: string | number | string[];
  setResTecnicoTorreEmail: React.Dispatch<
    React.SetStateAction<TStation[keyof TStation]>
  >;
  resTecnicoTorreEmail: string | number | string[];
  setAlturaTorre: React.Dispatch<
    React.SetStateAction<TStation[keyof TStation]>
  >;
  alturaTorre: string | number | string[];
  setAEVTorre: React.Dispatch<React.SetStateAction<TStation[keyof TStation]>>;
  aEVTorre: string | number | string[];
  setAEVFundacao: React.Dispatch<
    React.SetStateAction<TStation[keyof TStation]>
  >;
  aEVFundacao: string | number | string[];
  setResetVisibleStations: React.Dispatch<React.SetStateAction<boolean>>;
  handleUpdateStation: (e: MouseEvent<HTMLElement>, campo: string) => void;
  color: boolean;
};

const EditableFilters = ({
  home,
  station,
  label,
  setEditStation,
  editStation,
  setEditLabelStation,
  editLabelStation,
  setEditResTecnicoTorreNome,
  editResTecnicoTorreNome,
  setEditResTecnicoTorreTelefone,
  editResTecnicoTorreTelefone,
  setEditResTecnicoTorreEmail,
  editResTecnicoTorreEmail,
  setEditAlturaTorre,
  editAlturaTorre,
  setEditAEVTorre,
  editAEVTorre,
  setEditAEVFundacao,
  editAEVFundacao,
  setLabelStation,
  labelStation,
  setResTecnicoTorreNome,
  resTecnicoTorreNome,
  setResTecnicoTorreTelefone,
  resTecnicoTorreTelefone,
  setResTecnicoTorreEmail,
  resTecnicoTorreEmail,
  setAlturaTorre,
  alturaTorre,
  setAEVTorre,
  aEVTorre,
  setAEVFundacao,
  aEVFundacao,
  handleUpdateStation,
  setResetVisibleStations,
  color,
}: Props) => {
  const { globalState, dispatchGlobalState } = useGlobalContext();
  const { role } = useAuth().userData;

  const highLightStation = (station: TStation) => {
    setResetVisibleStations(false);
    dispatchGlobalState({
      type: 'SET_INFOWINDOW_COORD',
      lat: parseFloat(station.Latitude.toString()),
      lng: parseFloat(station.Longitude.toString()),
      idStation: station._id,
    });
  };

  const renderEditableField = (
    fieldName: keyof TStation,
    editState: string,
    setEditState: React.Dispatch<React.SetStateAction<string>>,
    fieldState: string | number | string[],
    setFieldState: React.Dispatch<
      React.SetStateAction<TStation[keyof TStation]>
    >,
  ) => {
    return station._id !== editState ? (
      <div
        className={`flex items-center gap-2 ${
          label.editable
            ? station[fieldName] !== '' && station[fieldName] !== -1
              ? 'justify-start'
              : 'justify-center'
            : ''
        }`}
      >
        {station[fieldName] && station[fieldName] !== -1
          ? station[fieldName]
          : role === 'visualizador'
            ? '-'
            : ''}
        <button
          className={` 
          ${
            station._id === globalState.idStation && color
              ? 'text-top-digital-black-light'
              : 'hover:text-top-digital-link-hover'
          }
          text-center ${role !== 'visualizador' ? '' : 'hidden'}`}
          title={`Entre com ${label.campo}`}
          onClick={(e) => {
            setEditState(station._id);
            setFieldState(station[fieldName]);
            highLightStation(station);
          }}
        >
          <Edit
            className="w-4 h-4"
            strokeWidth={station._id === globalState.idStation && home ? 3 : 2}
          />
        </button>
      </div>
    ) : (
      <div className="flex items-center">
        <input
          className="customInputText w-[150px]"
          type="text"
          name={fieldName as string}
          value={fieldState === -1 ? '' : (fieldState as string)}
          onChange={(e) => setFieldState(e.target.value)}
        />
        <button
          className={` ${
            station._id === globalState.idStation && color
              ? 'text-top-digital-black-light'
              : 'hover:text-top-digital-link-hover'
          } text-center`}
          title={`Entre com ${label.campo}`}
          onClick={(e) => {
            handleUpdateStation(e, label.campo);
          }}
        >
          <Save className="w-4 h-4" />
        </button>
        <button
          className={` ${
            station._id === globalState.idStation && color
              ? 'text-top-digital-black-light'
              : 'hover:text-top-digital-link-hover'
          } text-center`}
          title={`Entre com ${label.campo}`}
          onClick={(e) => {
            setEditState('');
          }}
        >
          <X className="w-4 h-4" />
        </button>
      </div>
    );
  };

  switch (label.campo) {
    case 'Label':
      return renderEditableField(
        'Label',
        editLabelStation,
        setEditLabelStation,
        labelStation,
        setLabelStation,
      );
    case 'ResTecnicoTorreNome':
      return renderEditableField(
        'ResTecnicoTorreNome',
        editResTecnicoTorreNome,
        setEditResTecnicoTorreNome,
        resTecnicoTorreNome,
        setResTecnicoTorreNome,
      );
    case 'ResTecnicoTorreTelefone':
      return renderEditableField(
        'ResTecnicoTorreTelefone',
        editResTecnicoTorreTelefone,
        setEditResTecnicoTorreTelefone,
        resTecnicoTorreTelefone,
        setResTecnicoTorreTelefone,
      );
    case 'ResTecnicoTorreEmail':
      return renderEditableField(
        'ResTecnicoTorreEmail',
        editResTecnicoTorreEmail,
        setEditResTecnicoTorreEmail,
        resTecnicoTorreEmail,
        setResTecnicoTorreEmail,
      );
    case 'AlturaTorre':
      return renderEditableField(
        'AlturaTorre',
        editAlturaTorre,
        setEditAlturaTorre,
        alturaTorre,
        setAlturaTorre,
      );
    case 'AEVTorre':
      return renderEditableField(
        'AEVTorre',
        editAEVTorre,
        setEditAEVTorre,
        aEVTorre,
        setAEVTorre,
      );
    case 'AEVFundacao':
      return renderEditableField(
        'AEVFundacao',
        editAEVFundacao,
        setEditAEVFundacao,
        aEVFundacao,
        setAEVFundacao,
      );
    default:
      return null;
  }
};

export default EditableFilters;
