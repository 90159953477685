import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk } from '@reduxjs/toolkit';
import FistelService from '../services/FistelService';

import { RootState } from '../store';
import { IFistel } from '../Interfaces/IFistel';

const initialState: IFistel = {
  fistelError: false,
  fistelSuccess: false,
  fistelLoading: false,
  fistelMessage: '',
  fistelToCheck: '',
  fistelList: [],
};

export const checkFistel = createAsyncThunk(
  'login/login',
  async (user: any, thunkAPI) => {
    const res = await FistelService.checkFistel(user);

    //check for errors
    if (res.errors) {
      return thunkAPI.rejectWithValue(res.errors);
    }
    return res;
  },
);

export const FilstelSlice = createSlice({
  name: 'fistel',
  initialState,
  reducers: {
    reset: (state) => {
      state.fistelError = false;
      state.fistelLoading = false;
      state.fistelSuccess = false;
      state.fistelMessage = '';
    },
    removeFistel: (state, action: PayloadAction<number>) => {
      state.fistelList = state.fistelList.filter(
        (_, i) => i !== action.payload,
      );
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(checkFistel.pending, (state) => {
        state.fistelError = false;
        state.fistelLoading = true;
        state.fistelSuccess = false;
      })
      .addCase(checkFistel.fulfilled, (state, action: PayloadAction<any>) => {
        state.fistelError = false;
        state.fistelLoading = false;
        state.fistelSuccess = true;
        state.fistelToCheck = action.payload;
        state.fistelList = [...state.fistelList, action.payload];
      })
      .addCase(checkFistel.rejected, (state, action: PayloadAction<any>) => {
        state.fistelError = true;
        state.fistelLoading = false;
        state.fistelSuccess = false;
        state.fistelMessage = action.payload[0];
        state.fistelToCheck = action.payload[1];
      });
  },
});

export const { reset, removeFistel } = FilstelSlice.actions;
export const fistelSelector = (state: RootState) => state.FistelReducer;
export default FilstelSlice.reducer;
