import { Link } from 'react-router-dom';
//Hooks
import { useEffect } from 'react';

//Hooks Redux
import useAppSelector from '../../hooks/useAppSelector';
import useAppDispatch from '../../hooks/useAppDispatch';
import { getLocations, locationSelector } from '../../slices/LocationSlice';

//Components
import Map from '../Map/Map';
import Station from '../Station/Station';

//context
import { useGlobalContext } from '../../hooks/useGlobalContext';
import { X } from 'lucide-react';

//for work with map
const workWithMap = true;

const Home = () => {
  //contexto global da aplicação
  const { globalState, dispatchGlobalState } = useGlobalContext();
  //Filtros para as estações que se localizam no componente station, eu subo esses filtros para que o componente home possa disparar
  //a ação de buscar as estações via redux

  //redux
  const dispatch = useAppDispatch();
  //locations contem todas as estações que foram buscadas na api via reedux
  //são retornados coordenadas e tipo de estação. Se houver filtro de campos e adicionalmente filtro de coordenadas
  //eles serão aplicados na API.
  const { locations, networks, loading, filters, error } =
    useAppSelector(locationSelector);

  //useEffect para buscar as estações na API
  //Sempre que o usuário clicar no botão de buscar ou limpar o filtro de coordenadas esse useEffect será disparado
  //além disse em alguns pontos da aplicação é necessáriu disparar esse useEffect para que o mapa seja atualizado através
  //do dispatchGlobalState setando a variável resetMap para true.
  useEffect(() => {
    const objData = {
      filters: globalState.filters,
    };
    dispatch(getLocations(objData));
  }, [dispatch, globalState.resetMap, globalState.filters]);

  //esse useEffect é usado para setar as estações visíveis no mapa
  //E também serve para mostrar ou não o mapa
  useEffect(() => {
    if (locations.length !== 0) {
      if (!workWithMap) {
        dispatchGlobalState({
          type: 'SET_VISIBLE_LOCATIONS',
          visibleLocations: locations,
        });
      }
      //dispatch(resetLoading());
    }
  }, [locations, dispatchGlobalState, dispatch]);

  if (loading) {
    return <p>Carregando...</p>;
  }
  return (
    <div className="flex justify-center items-center mx-auto">
      {((locations && locations.length !== 0) ||
        Object.keys(globalState.filters).length !== 0) && (
        <div className="text-left flex-1 w-full">
          <div className="flex-col items-start">
            <div className="font-semibold font-top-digital-content text-xl md:text-lg text-top-digital-content-color mt-2 md:leading-none">
              <h2>Home</h2>
            </div>
            <div className="font-top-digital-content text-smp md:text-lg text-top-digital-content-color mb-5 md:leading-none">
              <span className="text-smp">Mapa das Estações</span>
            </div>
          </div>
          <div>
            {workWithMap && !error ? (
              <Map locations={locations} networks={networks} />
            ) : (
              <p>
                <div className="flex flex-col items-center">
                  <div className="w-auto text-center py-3">
                    <h2 className="font-normal text-red-600">
                      O filtro aplicado não retornou resultados.
                    </h2>
                    <h2 className="font-normal text-top-digital-content-color">
                      <div className="flex text-sm items-center">
                        Clique no <X className="w-4 h-4" /> à sua direita para
                        resetá-lo
                      </div>
                    </h2>
                  </div>
                </div>
              </p>
            )}
          </div>
          <Station />
        </div>
      )}
      {locations &&
        locations.length === 0 &&
        Object.keys(globalState.filters).length === 0 && (
          <>
            {filters && filters > 0 ? (
              <p>
                {' '}
                <div className="flex flex-col items-center">
                  <div className="w-auto text-center py-3">
                    <h2 className="font-semibold text-top-digital-content-color">
                      Você possui estações cadastradas, porém você criou filtros
                      que não estão retornando resultados.
                    </h2>
                    <p className="text-top-digital-content-color">
                      Por favor,{' '}
                      <Link
                        className="font-bold hover:text-top-digital-hover"
                        to="/configstation"
                      >
                        clique aqui
                      </Link>{' '}
                      para acessar sua página de configuração de filtros.
                    </p>
                  </div>
                  <div className="py-2 min-h-full w-1/2 mt-12">
                    <img
                      src="/img/img-top-1536x1536.png"
                      alt="Um produto Top Digital"
                      className="h-auto"
                    />
                  </div>
                </div>
              </p>
            ) : (
              <div className="flex flex-col items-center">
                <div className="w-auto text-center py-3">
                  <h2 className="font-semibold text-top-digital-content-color">
                    Você ainda não tem estações cadastrados
                  </h2>
                  <p className="text-top-digital-content-color">
                    Para cadastrar uas estações{' '}
                    <Link
                      className="font-bold hover:text-top-digital-hover"
                      to="/upload"
                    >
                      clique aqui
                    </Link>{' '}
                    para acessar nossa página de upload do CSV Anatel.
                  </p>
                </div>
                <div className="py-2 min-h-full w-1/2 mt-12">
                  <img
                    src="/img/img-top-1536x1536.png"
                    alt="Um produto Top Digital"
                    className="h-auto"
                  />
                </div>
              </div>
            )}
          </>
        )}
    </div>
  );
};

export default Home;
