//react hooks
import { useEffect, useState } from 'react';

//component
import { DeleteDocumentModal } from './DeleteDocumentModal';

//types
import { TDocument, TDocumentSmall } from '../../Interfaces/IDocument';

//context
import { useGlobalContext } from '../../hooks/useGlobalContext';

//icons
import { Trash } from 'lucide-react';

//redux
import useAppDispatch from '../../hooks/useAppDispatch';
import useAppSelector from '../../hooks/useAppSelector';
import { documentSelector, deleteDoc, reset } from '../../slices/DocumentSlice';

type Props = {
  doc: TDocument | TDocumentSmall;
};

const DeleteDocument = ({ doc }: Props) => {
  //context
  const { dispatchGlobalState } = useGlobalContext();

  //redux
  const dispatch = useAppDispatch();
  const { deleteSuccess } = useAppSelector(documentSelector);

  //states
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [document, setDocument] = useState<TDocument | TDocumentSmall | null>(
    null,
  );
  const [deleteDocument, setDeleteDocument] = useState<boolean>(false);

  const warningDelete = (doc: TDocument | TDocumentSmall): any => {
    setDocument(doc);
    dispatchGlobalState({
      type: 'CLOSE_SYSTEM_LINK_FORM',
      idDocument: doc!._id,
    });
    setIsModalOpen(true);
  };

  useEffect(() => {
    const submitDelete = (doc: TDocument | TDocumentSmall) => {
      dispatch(deleteDoc(doc._id));
    };

    if (deleteDocument) {
      if (document !== null) {
        submitDelete(document);
      }
      dispatch(reset());
    }
  }, [deleteDocument, document, dispatch]);

  useEffect(() => {
    if (deleteSuccess) {
      setDeleteDocument(false);
    }
  }, [deleteSuccess, dispatchGlobalState, document]);

  return (
    <div>
      {isModalOpen && (
        <DeleteDocumentModal
          doc={doc}
          setDeleteDocument={setDeleteDocument}
          setIsModalOpen={setIsModalOpen}
          isModalOpen={isModalOpen}
        />
      )}

      <div
        className="text-top-digital-error-main flex items-center cursor-pointer"
        onClick={() => warningDelete(doc)}
        title="Remover documento"
      >
        <Trash className="w-5 h-5" />
      </div>
    </div>
  );
};

export default DeleteDocument;
