import { api, requestConfig } from '../utils/config';

const changePassword = async (
  token: string,
  actualPassword: string,
  newPassword: string,
): Promise<any> => {
  const config = requestConfig('PUT', { actualPassword, newPassword }, token);

  try {
    const res = await fetch(api + '/users/password', config)
      .then((res) => res.json())
      .catch((err) => err);

    return res;
  } catch (error) {
    return error;
  }
};

const ChangePasswordService = {
  changePassword,
};

export default ChangePasswordService;
