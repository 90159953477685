import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import NetworkFilesService from '../services/NetworkFilesService';
import { RootState } from '../store';
import { NetworkFile, NetworkFileStatus } from '../Interfaces/INetworkFile';

const initialState: NetworkFileStatus = {
  error: false,
  loading: false,
  success: false,
  message: '',
  networkFiles: [],
};

// Thunks
export const getFiles = createAsyncThunk(
  'network/getFiles',
  async (network: any, thunkAPI) => {
    const appState = thunkAPI.getState() as RootState;
    const token = appState.LoginReducer.user!;
    const res = await NetworkFilesService.getFiles(network, token);
    if (res.errors) {
      return thunkAPI.rejectWithValue(res.errors[0]);
    }
    return res.networkFiles;
  },
);

export const uploadFile = createAsyncThunk(
  'network/uploadFile',
  async (data: any, thunkAPI) => {
    const appState = thunkAPI.getState() as RootState;
    const token = appState.LoginReducer.user!;
    const res = await NetworkFilesService.uploadFile(data, token);
    if (res.errors) {
      return thunkAPI.rejectWithValue(res.errors[0]);
    }
    return res.networkFiles;
  },
);

export const downloadFile = createAsyncThunk(
  'network/downloadFile',
  async (doc: NetworkFile | undefined, thunkAPI) => {
    const appState = thunkAPI.getState() as RootState;
    const token = appState.LoginReducer.user!;
    try {
      const res = await NetworkFilesService.downloadFile(doc, token);
      if (res.errors) {
        return thunkAPI.rejectWithValue(res.errors[0]);
      }
      return res;
    } catch (error: unknown) {
      if (error instanceof Error) {
        return thunkAPI.rejectWithValue(error.message);
      }
      return thunkAPI.rejectWithValue('An unknown error occurred');
    }
  },
);

export const deleteFile = createAsyncThunk(
  'network/deleteFile',
  async (id: string, thunkAPI) => {
    const appState = thunkAPI.getState() as RootState;
    const token = appState.LoginReducer.user!;
    try {
      const res = await NetworkFilesService.deleteFile(id, token);
      if (res.errors) {
        return thunkAPI.rejectWithValue(res.errors[0]);
      }
      return res;
    } catch (error: unknown) {
      if (error instanceof Error) {
        return thunkAPI.rejectWithValue(error.message);
      }
      return thunkAPI.rejectWithValue('An unknown error occurred');
    }
  },
);

// Slice
export const networkFilesSlice = createSlice({
  name: 'network',
  initialState,
  reducers: {
    resetSuccess: (state) => {
      state.success = false;
    },
    reset: (state) => {
      state.error = false;
      state.loading = false;
      state.success = false;
      state.message = '';
      state.networkFiles = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getFiles.fulfilled,
        (state, action: PayloadAction<NetworkFile[]>) => {
          state.error = false;
          state.loading = false;
          state.success = true;
          state.networkFiles = action.payload;
        },
      )
      .addCase(getFiles.pending, (state) => {
        state.error = false;
        state.loading = true;
      })
      .addCase(getFiles.rejected, (state, action) => {
        state.error = true;
        state.loading = false;
        state.success = false;
        state.message =
          typeof action.payload === 'string'
            ? action.payload
            : 'Erro ao buscar arquivos de rede';
      })
      .addCase(
        uploadFile.fulfilled,
        (state, action: PayloadAction<NetworkFile[]>) => {
          state.error = false;
          state.loading = false;
          state.success = true;
          state.networkFiles = action.payload ? action.payload : [];
        },
      )
      .addCase(uploadFile.pending, (state) => {
        state.error = false;
        state.loading = true;
      })
      .addCase(uploadFile.rejected, (state, action) => {
        state.error = true;
        state.loading = false;
        state.success = false;
        state.message =
          typeof action.payload === 'string'
            ? action.payload
            : 'Erro ao fazer upload do arquivo';
      })
      .addCase(downloadFile.fulfilled, (state, action: PayloadAction<any>) => {
        state.error = false;
        state.loading = false;
        state.success = true;
      })
      .addCase(downloadFile.pending, (state) => {
        state.error = false;
        state.loading = true;
      })
      .addCase(downloadFile.rejected, (state, action) => {
        state.error = true;
        state.loading = false;
        state.success = false;
        state.message =
          typeof action.payload === 'string'
            ? action.payload
            : 'Erro ao fazer download do arquivo';
      })
      .addCase(
        deleteFile.fulfilled,
        (state, action: PayloadAction<NetworkFile[]>) => {
          state.error = false;
          state.loading = false;
          state.success = true;
          state.networkFiles = action.payload ? action.payload : [];
        },
      )
      .addCase(deleteFile.pending, (state) => {
        state.error = false;
        state.loading = true;
      })
      .addCase(deleteFile.rejected, (state, action) => {
        state.error = true;
        state.loading = false;
        state.success = false;
        state.message =
          typeof action.payload === 'string'
            ? action.payload
            : 'Erro ao fazer upload do arquivo';
      });
  },
});

export const { resetSuccess, reset } = networkFilesSlice.actions;
export const networkFilesSelector = (state: RootState) =>
  state.NetworkFilesReducer;
export default networkFilesSlice.reducer;
