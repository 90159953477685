import React, { MouseEvent, useEffect, useState } from 'react';

//types
import { TStation } from '../../Interfaces/IStation';

//utils
import isValidObjectId from '../../utils/isValidObjectId';

//redux
import useAppSelector from '../../hooks/useAppSelector';
import useAppDispatch from '../../hooks/useAppDispatch';
import { configSelector } from '../../slices/ConfigStationSlice';
import { getStationById, stationSelector } from '../../slices/StationSlice';
import {
  editableFieldStationSelector,
  setEditableFieldStationValue,
  resetEditableField,
} from '../../slices/EditableFieldStationSlice';

//context
import { useGlobalContext } from '../../hooks/useGlobalContext';

//icons

import EditableFilters from './EditableFilters';
import StationPrintFiled from './StationPrintFiled';

type Props = {
  stationId?: string;
};

const StationDetails = ({ stationId }: Props) => {
  //states
  const [resetVisbleStations, setResetVisibleStations] =
    useState<boolean>(true);

  const [editStation, setEditStation] = useState<string>('');
  const [editLabelStation, setEditLabelStation] = useState<string>('');
  const [editResTecnicoTorreNome, setEditResTecnicoTorreNome] =
    useState<string>('');
  const [editResTecnicoTorreTelefone, setEditResTecnicoTorreTelefone] =
    useState<string>('');
  const [editResTecnicoTorreEmail, setEditResTecnicoTorreEmail] =
    useState<string>('');
  const [editAlturaTorre, setEditAlturaTorre] = useState<string>('');
  const [editAEVTorre, setEditAEVTorre] = useState<string>('');
  const [editAEVFundacao, setEditAEVFundacao] = useState<string>('');
  const [labelStation, setLabelStation] =
    useState<TStation[keyof TStation]>('');
  const [resTecnicoTorreNome, setResTecnicoTorreNome] =
    useState<TStation[keyof TStation]>('');
  const [resTecnicoTorreTelefone, setResTecnicoTorreTelefone] =
    useState<TStation[keyof TStation]>('');
  const [resTecnicoTorreEmail, setResTecnicoTorreEmail] =
    useState<TStation[keyof TStation]>('');
  const [alturaTorre, setAlturaTorre] = useState<TStation[keyof TStation]>(-1);
  const [aEVTorre, setAEVTorre] = useState<TStation[keyof TStation]>(-1);
  const [aEVFundacao, setAEVFundacao] = useState<TStation[keyof TStation]>(-1);
  //global context
  const { dispatchGlobalState } = useGlobalContext();
  //redux
  const dispatch = useAppDispatch();
  const { config } = useAppSelector(configSelector);
  const { station, stationLoading } = useAppSelector(stationSelector);
  const { editableFieldStationSuccess, editableFieldStationMessage } =
    useAppSelector(editableFieldStationSelector);

  /*
   * Esse useEffect é chamado se o parametro stationId for diferente de undefined e se o id for um id valido
   * Isso significa que ele não está sendo chamado do componmente StationPage
   */
  useEffect(() => {
    if (stationId !== undefined && isValidObjectId(stationId)) {
      dispatch(getStationById(stationId));
    }
  }, [dispatch, stationId]);

  useEffect(() => {
    if (editableFieldStationSuccess) {
      switch (editableFieldStationMessage) {
        case 'Label':
          setEditLabelStation('');
          break;
        case 'ResTecnicoTorreNome':
          setEditResTecnicoTorreNome('');
          break;
        case 'ResTecnicoTorreTelefone':
          setEditResTecnicoTorreTelefone('');
          break;
        case 'ResTecnicoTorreEmail':
          setEditResTecnicoTorreEmail('');
          break;
        case 'AlturaTorre':
          setEditAlturaTorre('');
          break;
        case 'AEVTorre':
          setEditAEVTorre('');
          break;
        case 'AEVFundacao':
          setEditAEVFundacao('');
          break;
        default:
          break;
      }
      dispatchGlobalState({ type: 'SET_LABEL_NEW_VALUE', labelNewValue: true });
      dispatch(getStationById(station!._id));
    } else {
      dispatchGlobalState({
        type: 'SET_LABEL_NEW_VALUE',
        labelNewValue: false,
      });
    }
    dispatch(resetEditableField());
  }, [
    editableFieldStationSuccess,
    dispatch,
    setResetVisibleStations,
    dispatchGlobalState,
    editableFieldStationMessage,
    station,
  ]);

  const handleUpdateStation = (e: MouseEvent<HTMLElement>, campo: string) => {
    e.preventDefault();
    let data: any;
    switch (campo) {
      case 'Label':
        data = {
          value: labelStation,
          field: campo,
          idStation: editLabelStation,
        };
        dispatch(setEditableFieldStationValue(data));
        return;
      case 'ResTecnicoTorreNome':
        data = {
          value: resTecnicoTorreNome,
          field: campo,
          idStation: editResTecnicoTorreNome,
        };
        dispatch(setEditableFieldStationValue(data));
        return;
      case 'ResTecnicoTorreTelefone':
        data = {
          value: resTecnicoTorreTelefone,
          field: campo,
          idStation: editResTecnicoTorreTelefone,
        };
        dispatch(setEditableFieldStationValue(data));
        return;
      case 'ResTecnicoTorreEmail':
        data = {
          value: resTecnicoTorreEmail,
          field: campo,
          idStation: editResTecnicoTorreEmail,
        };
        dispatch(setEditableFieldStationValue(data));
        return;
      case 'AlturaTorre':
        data = {
          value: alturaTorre,
          field: campo,
          idStation: editAlturaTorre,
        };
        dispatch(setEditableFieldStationValue(data));
        return;
      case 'AEVTorre':
        data = {
          value: aEVTorre,
          field: campo,
          idStation: editAEVTorre,
        };
        dispatch(setEditableFieldStationValue(data));
        return;
      case 'AEVFundacao':
        data = {
          value: aEVFundacao,
          field: campo,
          idStation: editAEVFundacao,
        };
        dispatch(setEditableFieldStationValue(data));
        return;
      default:
        return null;
    }
  };
  if (stationLoading) {
    return <div>Carregando...</div>;
  }
  return (
    <div className="overflow-auto">
      {resetVisbleStations}
      <h2 className="md:text-base text-smp text-top-digital-content-color font-semibold mb-2 font-top-digital-content">
        Dados da Estação
      </h2>
      {config &&
        station &&
        config.map((conf, index) => (
          <div key={`label${index}`} className="grid grid-cols-2">
            <div
              className={`${
                index % 2 ? 'bg-white' : 'bg-top-digital-secondary-light'
              }  text-black font-top-digital-content text-smp font-medium leading-6`}
            >
              {conf.label}
            </div>
            <div
              className={`${
                index % 2 ? 'bg-white' : 'bg-top-digital-secondary-light'
              } text-top-digital-secondary-darker font-normal font-top-digital-content text-smp`}
            >
              <div className="flex gap-2 items-center">
                {conf.editable ? (
                  <EditableFilters
                    home={false}
                    label={conf}
                    setEditStation={setEditStation}
                    editStation={editStation}
                    setEditLabelStation={setEditLabelStation}
                    editLabelStation={editLabelStation}
                    setEditResTecnicoTorreNome={setEditResTecnicoTorreNome}
                    editResTecnicoTorreNome={editResTecnicoTorreNome}
                    setEditResTecnicoTorreTelefone={
                      setEditResTecnicoTorreTelefone
                    }
                    editResTecnicoTorreTelefone={editResTecnicoTorreTelefone}
                    setEditResTecnicoTorreEmail={setEditResTecnicoTorreEmail}
                    editResTecnicoTorreEmail={editResTecnicoTorreEmail}
                    setEditAlturaTorre={setEditAlturaTorre}
                    editAlturaTorre={editAlturaTorre}
                    setEditAEVTorre={setEditAEVTorre}
                    editAEVTorre={editAEVTorre}
                    setEditAEVFundacao={setEditAEVFundacao}
                    editAEVFundacao={editAEVFundacao}
                    setLabelStation={setLabelStation}
                    labelStation={labelStation}
                    setResTecnicoTorreNome={setResTecnicoTorreNome}
                    resTecnicoTorreNome={resTecnicoTorreNome}
                    setResTecnicoTorreTelefone={setResTecnicoTorreTelefone}
                    resTecnicoTorreTelefone={resTecnicoTorreTelefone}
                    setResTecnicoTorreEmail={setResTecnicoTorreEmail}
                    resTecnicoTorreEmail={resTecnicoTorreEmail}
                    setAlturaTorre={setAlturaTorre}
                    alturaTorre={alturaTorre}
                    setAEVTorre={setAEVTorre}
                    aEVTorre={aEVTorre}
                    setAEVFundacao={setAEVFundacao}
                    aEVFundacao={aEVFundacao}
                    setResetVisibleStations={setResetVisibleStations}
                    station={station}
                    key={`${station._id}${station.Label}`}
                    handleUpdateStation={handleUpdateStation}
                    color={false}
                  />
                ) : (
                  <StationPrintFiled station={station} config={conf} />
                )}
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};

export default StationDetails;
