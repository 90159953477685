import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk } from '@reduxjs/toolkit';
import LoginService from '../services/LoginService';
import { ILoginStates } from '../Interfaces/ILoginStates';
import Cookies from 'js-cookie';
import { RootState } from '../store';

const userCookie = Cookies.get('user');

const initialState: ILoginStates = {
  loginError: false,
  loginSuccess: false,
  loginLoading: false,
  loginMessage: '',
  user: userCookie,
};

export const login = createAsyncThunk(
  'login/login',
  async (user: any, thunkAPI) => {
    const res = await LoginService.login(user);

    //check for errors
    if (res.errors) {
      return thunkAPI.rejectWithValue(res.errors[0]);
    }
    return res;
  },
);

export const logout = createAsyncThunk('login/logout', async () => {
  LoginService.logout();
});

export const LoginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    reset: (state) => {
      state.loginError = false;
      state.loginLoading = false;
      state.loginSuccess = false;
      state.loginMessage = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(logout.fulfilled, (state, action) => {
        state.loginError = false;
        state.loginLoading = false;
        state.loginSuccess = true;
        state.user = undefined;
      })
      .addCase(login.pending, (state) => {
        state.loginError = false;
        state.loginLoading = true;
      })
      .addCase(login.fulfilled, (state, action: PayloadAction<any>) => {
        state.loginError = false;
        state.loginLoading = false;
        state.loginSuccess = true;
        state.user = action.payload ? action.payload.token : undefined;
      })
      .addCase(login.rejected, (state, action: PayloadAction<any>) => {
        state.loginError = true;
        state.loginLoading = false;
        state.user = undefined;
        if (action.payload) {
          state.loginMessage = action.payload;
        }
      });
  },
});

export const { reset } = LoginSlice.actions;
export const loginSelector = (state: RootState) => state.LoginReducer;
export default LoginSlice.reducer;
