// src/pages/Project.tsx

import React, { useState, useEffect, ChangeEvent } from 'react';
import useAppDispatch from '../../hooks/useAppDispatch';
import useAppSelector from '../../hooks/useAppSelector';
import {
  projectSelector,
  insertProjectAsync,
  updateProjectAsync,
  deleteProjectAsync,
  getAllProjectsAsync,
  setSelectedProjectId,
  reset,
} from '../../slices/ProjectSlice';
import { IProject } from '../../Interfaces/IProject';
import Loader from '../../components/Loading/Loader'; // Importando o componente Loader
import { Link } from 'react-router-dom';

const Project = () => {
  const dispatch = useAppDispatch();
  const {
    projects,
    loading,
    error,
    message,
    insertSuccess,
    updateSuccess,
    deleteSuccess,
  } = useAppSelector(projectSelector);

  // Estados para o formulário
  const [name, setName] = useState('');
  const [file, setFile] = useState<File | null>(null);
  const [isVisible, setIsVisible] = useState(true);
  const [Latitude, setLatitude] = useState('');
  const [Longitude, setLongitude] = useState('');

  // Estado para controlar a visibilidade do formulário
  const [showForm, setShowForm] = useState(false);

  useEffect(() => {
    dispatch(getAllProjectsAsync());

    // Limpar estados de sucesso e erro após exibir mensagens
    return () => {
      dispatch(reset());
    };
  }, [dispatch, insertSuccess, updateSuccess, deleteSuccess]);

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const selectedFile = e.target.files[0];
      // Verificar se o arquivo é KMZ
      const fileExtension = selectedFile.name.split('.').pop()?.toLowerCase();
      if (fileExtension !== 'kmz') {
        alert('Apenas arquivos KMZ são permitidos.');
        e.target.value = ''; // Resetar o input
        setFile(null);
        return;
      }
      setFile(selectedFile);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (
      name.trim() === '' ||
      !file ||
      Latitude.trim() === '' ||
      Longitude.trim() === ''
    ) {
      alert('Por favor, preencha todos os campos obrigatórios.');
      return;
    }

    // Validar Latitude e Longitude
    const lat = parseFloat(Latitude);
    const lng = parseFloat(Longitude);

    if (isNaN(lat) || isNaN(lng)) {
      alert('Latitude e Longitude devem ser números válidos.');
      return;
    }
    console.log('handleSubmit chamado');
    // Criar FormData para enviar o arquivo
    const formData = new FormData();
    formData.append('name', name);
    formData.append('file', file);
    formData.append('Latitude', lat.toString());
    formData.append('Longitude', lng.toString());
    formData.append('isVisible', isVisible.toString());

    // Dispatch para inserir o projeto
    dispatch(insertProjectAsync(formData));

    // Resetar os campos após o envio
    setName('');
    setFile(null);
    setIsVisible(true);
    setLatitude('');
    setLongitude('');
    setShowForm(false); // Ocultar o formulário após o envio
  };

  const handleDelete = (id: string) => {
    if (window.confirm('Tem certeza que deseja remover este projeto?')) {
      dispatch(deleteProjectAsync(id));
    }
  };

  const handleToggleVisibility = (project: IProject) => {
    dispatch(
      updateProjectAsync({
        id: project.id,
        data: { isVisible: !project.isVisible },
      }),
    );
  };

  return (
    <div className="container mx-auto p-4">
      {/* Loader */}
      {loading && <Loader />}

      <h2 className="text-xl font-semibold mb-4">Gerenciar Projetos</h2>

      {/* Botão para abrir/fechar o formulário de cadastro */}
      <div className="mb-6">
        <button
          onClick={() => setShowForm(!showForm)}
          className="customButton"
          disabled={loading} // Desabilitar botão enquanto carrega
        >
          {showForm ? 'Fechar Cadastro' : 'Cadastrar Projeto'}
        </button>
      </div>

      {/* Seção para Adicionar Novo Projeto */}
      {showForm && (
        <div className="w-full md:w-1/2 lg:w-1/3 mb-6">
          <form
            onSubmit={handleSubmit}
            className="flex flex-col gap-4"
            encType="multipart/form-data"
          >
            <div>
              <label
                htmlFor="name"
                className="block text-sm font-medium text-gray-700"
              >
                Nome do Projeto *
              </label>
              <input
                type="text"
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="customInputText w-full"
                required
                placeholder="Ex: Projeto de Exemplo"
              />
            </div>
            <div>
              <label
                htmlFor="file"
                className="block text-sm font-medium text-gray-700"
              >
                Arquivo KMZ *
              </label>
              <input
                type="file"
                id="file"
                accept=".kmz"
                onChange={handleFileChange}
                className="customInputFile border border-top-digital-op-40 w-full"
                required
              />
            </div>

            {/* Seção Opcional: Coordenadas de Ponto de Referência */}
            <div className="mt-4">
              <h3 className="text-md font-medium mb-2">Coordenadas</h3>
              <div className="flex flex-col gap-4">
                <div className="flex-1">
                  <label
                    htmlFor="Latitude"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Latitude *
                  </label>
                  <input
                    type="text"
                    id="Latitude"
                    value={Latitude}
                    onChange={(e) => setLatitude(e.target.value)}
                    className="customInputText w-full"
                    placeholder="Ex: -23.5505"
                  />
                </div>
                <div className="flex-1">
                  <label
                    htmlFor="Longitude"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Longitude *
                  </label>
                  <input
                    type="text"
                    id="Longitude"
                    value={Longitude}
                    onChange={(e) => setLongitude(e.target.value)}
                    className="customInputText w-full"
                    placeholder="Ex: -46.6333"
                  />
                </div>
              </div>
            </div>

            <div className="flex items-center">
              <input
                type="checkbox"
                id="isVisible"
                checked={isVisible}
                onChange={(e) => setIsVisible(e.target.checked)}
                className="customInputCheckbox"
              />
              <label
                htmlFor="isVisible"
                className="ml-2 block text-sm text-gray-700"
              >
                Visível
              </label>
            </div>

            <div className="flex justify-end gap-2">
              <button
                type="submit"
                className="customButton"
                disabled={loading} // Desabilitar botão enquanto carrega
              >
                {loading ? 'Adicionando...' : 'Adicionar'}
              </button>
            </div>
          </form>
        </div>
      )}

      {/* Mensagens de Sucesso ou Erro */}
      {error && (
        <div className="bg-red-100 text-red-700 p-4 rounded mb-4">
          {message}
        </div>
      )}
      {insertSuccess && (
        <div className="bg-green-100 text-green-700 p-4 rounded mb-4">
          Projeto adicionado com sucesso!
        </div>
      )}
      {updateSuccess && (
        <div className="bg-green-100 text-green-700 p-4 rounded mb-4">
          Projeto atualizado com sucesso!
        </div>
      )}
      {deleteSuccess && (
        <div className="bg-green-100 text-green-700 p-4 rounded mb-4">
          Projeto removido com sucesso!
        </div>
      )}

      {/* Seção para Gerenciar Projetos Existentes */}
      <div>
        <h3 className="text-lg font-medium mb-2">Projetos Existentes</h3>
        {projects.length === 0 ? (
          <p className="text-sm">Nenhum projeto adicionado.</p>
        ) : (
          <ul className="space-y-2">
            {projects.map((project) => (
              <li
                key={project.id}
                className="flex items-center justify-between p-2 border rounded-md"
              >
                <div>
                  <span className="font-medium">{project.name}</span>
                  <p className="text-sm text-gray-500 truncate w-48">
                    {project.file}
                  </p>
                  <p className="text-sm text-gray-500">
                    Latitude: {project.Latitude}, Longitude: {project.Longitude}
                  </p>
                </div>
                <div className="flex items-center gap-2">
                  <label className="flex items-center text-sm text-gray-700">
                    <input
                      type="checkbox"
                      checked={project.isVisible}
                      onChange={() => handleToggleVisibility(project)}
                      className="customInputCheckbox"
                    />
                    <span className="ml-1">Visível</span>
                  </label>
                  {/* Botão para Ver no Mapa */}
                  <Link
                    to="/"
                    onClick={() => dispatch(setSelectedProjectId(project.id))}
                    className="text-blue-500 hover:text-blue-700 text-sm"
                  >
                    Ver no Mapa
                  </Link>
                  {/* Botão de Remover */}
                  <button
                    onClick={() => handleDelete(project.id)}
                    className="text-red-500 hover:text-red-700 text-sm"
                  >
                    Remover
                  </button>
                </div>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default Project;
