import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunk } from '@reduxjs/toolkit';
import NotificationService from '../services/NotificationService';
import { RootState } from '../store';

//interface of equipments
import { INotificationStates } from '../Interfaces/INotificationData';

const initialState: INotificationStates = {
  notificationData: [],
  notificationUserData: [],
  notificationError: false,
  notificationSuccess: false,
  notificationLoading: false,
  notificationMessage: '',
  notificationProcessing: false,
  notificationPage: 0,
};

export const getUserNotifications = createAsyncThunk(
  'boleto/getUserNotifications',
  async (_, thunkAPI) => {
    const appState = thunkAPI.getState() as RootState;
    const token = appState.LoginReducer.user!;
    const res = await NotificationService.getUserNotifications(token);
    //check for errors
    if (res.errors) {
      return thunkAPI.rejectWithValue(res.errors[0]);
    }
    return res;
  },
);

export const getAllNotifications = createAsyncThunk(
  'boleto/getAllNotifications',
  async (filterObj: any, thunkAPI) => {
    const appState = thunkAPI.getState() as RootState;
    const token = appState.LoginReducer.user!;
    const res = await NotificationService.getAllNotifications(filterObj, token);
    //check for errors
    if (res.errors) {
      return thunkAPI.rejectWithValue(res.errors[0]);
    }
    return res;
  },
);

export const setNotificationsRead = createAsyncThunk(
  'boleto/setNotificationsRead',
  async (data: any, thunkAPI) => {
    const appState = thunkAPI.getState() as RootState;
    const token = appState.LoginReducer.user!;
    const res = await NotificationService.setNotificationsRead(data, token);
    //check for errors
    if (res.errors) {
      return thunkAPI.rejectWithValue(res.errors[0]);
    }
    return res;
  },
);

export const NotificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    resetNotification: (state) => {
      state.notificationError = false;
      state.notificationLoading = false;
      state.notificationSuccess = false;
      state.notificationMessage = '';
      state.notificationProcessing = false;
      state.notificationPage = 0;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllNotifications.fulfilled, (state, action) => {
        state.notificationError = false;
        state.notificationLoading = false;
        state.notificationSuccess = true;
        state.notificationData = action.payload[0];
      })
      .addCase(getAllNotifications.pending, (state) => {
        state.notificationError = false;
        state.notificationLoading = true;
        state.notificationSuccess = false;
      })
      .addCase(getAllNotifications.rejected, (state, action) => {
        state.notificationError = true;
        state.notificationLoading = false;
        state.notificationSuccess = false;
        state.notificationMessage =
          typeof action.payload === 'string' ? action.payload : '';
      })
      .addCase(getUserNotifications.fulfilled, (state, action) => {
        state.notificationError = false;
        state.notificationLoading = false;
        state.notificationSuccess = false;
        state.notificationUserData = action.payload[0];
      })
      .addCase(getUserNotifications.pending, (state) => {
        state.notificationError = false;
        state.notificationLoading = true;
        state.notificationSuccess = false;
      })
      .addCase(getUserNotifications.rejected, (state, action) => {
        state.notificationError = true;
        state.notificationLoading = false;
        state.notificationSuccess = false;
        state.notificationMessage =
          typeof action.payload === 'string' ? action.payload : '';
      })
      .addCase(setNotificationsRead.fulfilled, (state, action) => {
        state.notificationError = false;
        state.notificationLoading = false;
        state.notificationSuccess = true;
        state.notificationUserData = action.payload[0];
        state.notificationPage = action.payload[1];
      })
      .addCase(setNotificationsRead.pending, (state) => {
        state.notificationError = false;
        state.notificationLoading = true;
        state.notificationSuccess = false;
      })
      .addCase(setNotificationsRead.rejected, (state, action) => {
        state.notificationError = true;
        state.notificationLoading = false;
        state.notificationSuccess = false;
        state.notificationMessage =
          typeof action.payload === 'string' ? action.payload : '';
      });
  },
});

export const { resetNotification } = NotificationSlice.actions;
export const notificationSelector = (state: RootState) =>
  state.NotificationReducer;
export default NotificationSlice.reducer;
