import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunk } from '@reduxjs/toolkit';
import ReportDocumentService from '../services/ReportDocumentService';
import { RootState } from '../store';

import { IReportDocumentStates } from '../Interfaces/IReportDocument';

const initialState: IReportDocumentStates = {
  error: false,
  loading: false,
  success: false,
  message: '',
  reportDocument: {
    CNPJ: [],
    Fistel: [],
    Estado: [],
    Cidade: [],
    Usuario: [],
    TipoDocumento: [],
    cnpj: '',
    fistel: '',
    uf: '',
    municipio: '',
    situacaodocumento: '',
    user: '',
    result: [],
    resultCount: 0,
    stationDescription: '',
  },
};

export const getReportDocument = createAsyncThunk(
  'reportDocument/getReportDocument',
  async (objFilter: any, thunkAPI) => {
    const appState = thunkAPI.getState() as RootState;
    const token = appState.LoginReducer.user!;
    const res = await ReportDocumentService.getReportDocument(objFilter, token);
    //check for errors
    if (res.errors) {
      return thunkAPI.rejectWithValue(res.errors[0]);
    }
    return res;
  },
);

export const ReportDocumentSlice = createSlice({
  name: 'reportDocument',
  initialState,
  reducers: {
    reset: (state) => {
      state.error = false;
      state.loading = false;
      state.success = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getReportDocument.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.error = false;
        state.reportDocument = action.payload;
      })
      .addCase(getReportDocument.pending, (state) => {
        state.error = false;
        state.loading = true;
      })
      .addCase(getReportDocument.rejected, (state, action) => {
        state.error = true;
        state.loading = false;
        state.success = false;
        state.message =
          typeof action.payload === 'string' ? action.payload : '';
      });

    //setNewProtocolName
  },
});

export const { reset } = ReportDocumentSlice.actions;
export const reportDocumentSelector = (state: RootState) =>
  state.DocumentReportReducer;
export default ReportDocumentSlice.reducer;
