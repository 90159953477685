import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk } from '@reduxjs/toolkit';
import UserPreferenceService from '../services/UserPreferenceService';

import { RootState } from '../store';
import {
  IUserPreference,
  IUserPreferenceStates,
} from '../Interfaces/IUserPreference';

const initialState: IUserPreferenceStates = {
  userPreference: {
    hideMobileStations: false,
    lastBillCheck: null,
    nextBillCheck: null,
    showLinkPoints: false,
  },
  userPreferenceSuccess: false,
  userPreferenceError: false,
  userPreferenceLoading: false,
  userPreferenceMessage: '',
};

export const getUserPreferences = createAsyncThunk(
  'userpreferences/getUserPreferences',
  async (_, thunkAPI) => {
    const appState = thunkAPI.getState() as RootState;
    const token = appState.LoginReducer.user!;
    try {
      const res = await UserPreferenceService.getUserPreferences(token);

      if (res.errors) {
        return thunkAPI.rejectWithValue(res.errors);
      }
      return res;
    } catch (error: unknown) {
      if (error instanceof Error) {
        return thunkAPI.rejectWithValue(error.message);
      }
      return thunkAPI.rejectWithValue('An unknown error occurred');
    }
  },
);

export const setUserPreference = createAsyncThunk(
  'userpreferences/setUserPreference',
  async (data: { field: string; value: boolean }, thunkAPI) => {
    const appState = thunkAPI.getState() as RootState;
    const token = appState.LoginReducer.user!;
    const res = await UserPreferenceService.setUserPreference(data, token);
    if (res.errors) {
      return thunkAPI.rejectWithValue(res.errors[0]);
    }
    return res;
  },
);

export const UserPreferenceSlice = createSlice({
  name: 'userpreferences',
  initialState,
  reducers: {
    reset: (state) => {
      state.userPreferenceError = false;
      state.userPreferenceLoading = false;
      state.userPreferenceSuccess = false;
      state.userPreferenceMessage = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserPreferences.pending, (state) => {
        state.userPreferenceError = false;
        state.userPreferenceLoading = true;
        state.userPreferenceSuccess = false;
      })
      .addCase(
        getUserPreferences.fulfilled,
        (state, action: PayloadAction<IUserPreference>) => {
          state.userPreferenceError = false;
          state.userPreferenceLoading = false;
          state.userPreferenceSuccess = true;
          state.userPreference = action.payload;
        },
      )
      .addCase(
        getUserPreferences.rejected,
        (state, action: PayloadAction<any>) => {
          state.userPreferenceError = true;
          state.userPreferenceLoading = false;
          state.userPreferenceSuccess = false;
        },
      )
      .addCase(setUserPreference.pending, (state) => {
        state.userPreferenceError = false;
        state.userPreferenceLoading = true;
        state.userPreferenceSuccess = false;
      })
      .addCase(
        setUserPreference.fulfilled,
        (state, action: PayloadAction<IUserPreference>) => {
          state.userPreferenceError = false;
          state.userPreferenceLoading = false;
          state.userPreferenceSuccess = true;
          state.userPreference = action.payload;
        },
      )
      .addCase(
        setUserPreference.rejected,
        (state, action: PayloadAction<any>) => {
          state.userPreferenceError = true;
          state.userPreferenceLoading = false;
          state.userPreferenceSuccess = false;
          state.userPreferenceMessage = action.payload[0];
        },
      );
  },
});

export const { reset } = UserPreferenceSlice.actions;
export const userPreferenceSliceSelector = (state: RootState) =>
  state.UserPreferenceReducer;
export default UserPreferenceSlice.reducer;
