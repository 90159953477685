import React from 'react';

type Props = {
  handleCreateChildUser: () => void;
};

const ExtraUsers: React.FC<Props> = ({ handleCreateChildUser }) => (
  <div className="sm:flex sm:items-center w-2/3">
    <div className="sm:flex-auto">
      <h1 className="text-base font-semibold leading-6 text-gray-900">
        Usuários Extras
      </h1>
      <p className="mt-2 text-sm text-gray-700">
        Veja abaixo os usuários adicionais que você possui e suas permissões.
      </p>
    </div>
    <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
      <button
        onClick={handleCreateChildUser}
        type="button"
        className="customButton"
      >
        Cadastrar Usuário
      </button>
    </div>
  </div>
);

export default ExtraUsers;
