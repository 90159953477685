const checkBarCode = (barcode: any): string => {
  // Verifica se o valor não é uma string ou não possui o tamanho esperado
  // if (typeof barcode !== "string" || barcode.length !== 47 || !/^\d+$/.test(barcode)) {
  //   return "-";
  // }

  return barcode ? barcode : '-';
};

export default checkBarCode;
