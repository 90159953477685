type Props = {
  isRequired: string;
};

const DocumentRequirement = ({ isRequired }: Props) => {
  return (
    <>
      {isRequired === 'Sim' ? (
        <span className="text-smp text-red-600">obrigatório</span>
      ) : (
        <span className="text-smp text-blue-600">não obrigatório</span>
      )}
    </>
  );
};

export default DocumentRequirement;
