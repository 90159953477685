interface IInput {
  name: string;
  value: string;
  type: string;
  title: string;
  placeholder: string;
  onChange: any;
  required?: boolean;
  autoComplete?: string;
}

const Input = ({
  name,
  value,
  type,
  title,
  placeholder,
  onChange,
  required = false,
  autoComplete = '',
}: IInput) => (
  <div>
    <label
      htmlFor={name}
      className="block text-sm font-normal leading-6 top-digital-content-color"
    >
      {title}
    </label>
    <div>
      <input
        id={name}
        name={name}
        autoComplete={autoComplete}
        required={required}
        type={type}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          onChange(e.target.value)
        }
        value={value}
        placeholder={placeholder}
        className="customInputText w-full text-sm py-1"
      />
    </div>
  </div>
);

export default Input;
