import {
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronDoubleLeftIcon,
} from '@heroicons/react/20/solid';

//context
import { useGlobalContext } from '../../hooks/useGlobalContext';
import { useCallback, useEffect, useState } from 'react';

//types
import { IPagination } from '../../Interfaces/IPagination';

type Props = {
  page: number;
  totalResults: number;
  pageSize: number;
  globalPageParam: string;
  localStoragePageParam?: string;
  bg?: string;
};

const Pagination = ({
  page,
  totalResults,
  pageSize,
  globalPageParam,
  localStoragePageParam,
  bg,
}: Props) => {
  const pageCount =
    totalResults % pageSize === 0
      ? Math.floor(totalResults / pageSize)
      : Math.floor(totalResults / pageSize) + 1;

  const listFirstPage =
    localStoragePageParam === undefined
      ? undefined
      : page === 1
        ? '1'
        : localStorage.getItem(globalPageParam + 'ListFirstPage') || '1';

  const { dispatchGlobalState } = useGlobalContext();

  const [loading, setLoading] = useState<boolean>(false);
  const [objPagination, setObjPagination] = useState<IPagination>({
    arrayPages: [],
    arrayLeftPages: [],
    arrayRightPages: [],
  });

  // let params = `page: ${page} \npageSize: ${pageSize} \ntotalResults: ${totalResults} \nglobalPageParam: ${globalPageParam} \nlocalStoragePageParam: ${localStoragePageParam} \nlistFirstPage: ${listFirstPage} \npageCount: ${pageCount} \nloading: ${loading} \narrayPages: ${objPagination?.arrayPages} \narrayLeftPages: ${objPagination?.arrayLeftPages} \narrayRightPages: ${objPagination?.arrayRightPages}`;

  const setLocalStorage = useCallback(
    (key: string, value: string) => {
      if (localStoragePageParam !== undefined) {
        localStorage.setItem(key, value);
      }
    },
    [localStoragePageParam],
  );

  useEffect(() => {
    const pageStart =
      listFirstPage !== undefined ? parseInt(listFirstPage) : null || page;

    const objPaginationLoad: IPagination = {
      arrayPages: [],
      arrayLeftPages: [],
      arrayRightPages: [],
    };

    if (pageCount < 6) {
      for (let i = 1; i <= pageCount; i++) {
        objPaginationLoad.arrayPages.push(i);
      }
    } else if (pageCount >= 6 && pageCount - page < 6) {
      for (let i: number = pageCount - 5; i <= pageCount; i++) {
        objPaginationLoad.arrayPages.push(i);
      }
    } else {
      /*QUANDO TEM OS 3 PONTOS*/

      for (let i = pageStart; i < pageStart + 3; i++) {
        objPaginationLoad.arrayLeftPages.push(i);
      }

      for (let i = pageCount; i > pageCount - 3; i--) {
        objPaginationLoad.arrayRightPages.push(i);
      }
      objPaginationLoad.arrayRightPages.sort((a, b) => a - b);
      /*QUANDO TEM OS 3 PONTOS*/
    }
    setLocalStorage(globalPageParam + 'ListFirstPage', String(page));
    setObjPagination(objPaginationLoad);
    setLoading(false);
  }, [page, pageCount, listFirstPage, globalPageParam, setLocalStorage]);

  const handleFirstPage = () => {
    setLoading(true);
    updateGlobalParams(1);
  };

  const handleChevronLeftIcon = () => {
    setLoading(true);
    let newPage = page - 1;
    if (newPage <= 0) newPage = 1;
    updateGlobalParams(newPage);
  };

  const handleChevronRightIcon = () => {
    setLoading(true);
    let newPage = page + 1;
    if (newPage > pageCount) newPage = pageCount;
    updateGlobalParams(newPage);
  };

  const handleClickPage = (newPage: number) => {
    setLoading(true);
    updateGlobalParams(newPage);
  };

  const updateGlobalParams = (newPage: number) => {
    dispatchGlobalState({
      type: 'SET_GLOBAL_PAGE_PARAM',
      globalPageParam: globalPageParam,
      globalPageValue: newPage,
    });
    dispatchGlobalState({
      type: 'SET_INFOWINDOW_COORD',
      lat: 0,
      lng: 0,
      classesEstacao: [],
    });

    dispatchGlobalState({
      type: 'SET_STATION_ID',
      idStation: '',
    });
    if (localStoragePageParam)
      localStorage.setItem(localStoragePageParam, String(newPage));
  };

  return (
    <>
      <div
        className={`flex items-center justify-between ${
          bg && bg !== '' ? bg : 'border-t border-gray-200 bg-white px-0'
        } pt-3 sm:px-0 text-smp`}
      >
        <div className="flex flex-1 justify-start sm:hidden">
          <button
            disabled={page === 1 || loading === true}
            onClick={() => handleFirstPage()}
            className="relative inline-flex items-center rounded-l-md px-2 py-2 h-8 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
          >
            <span className="sr-only">Previous</span>
            <ChevronDoubleLeftIcon className="h-5 w-5" aria-hidden="true" />
          </button>
          <button
            disabled={page === 1 || loading === true}
            onClick={() => handleChevronLeftIcon()}
            className="relative inline-flex items-center px-2 py-2 h-8 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
          >
            <span className="sr-only">Previous</span>
            <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
          </button>
        </div>
        <div className="flex flex-1 justify-center sm:hidden">
          <p className="text-smp text-gray-700">
            <span className="font-medium">{(page - 1) * pageSize + 1}</span> até{' '}
            <span className="font-medium">
              {totalResults >= pageSize
                ? page === pageCount
                  ? totalResults
                  : page * pageSize
                : totalResults}
            </span>{' '}
            de <span className="font-medium">{totalResults}</span>
          </p>
        </div>
        <div className="flex flex-1 justify-end sm:hidden">
          <button
            onClick={() => handleChevronRightIcon()}
            disabled={page === pageCount || loading === true}
            className="relative inline-flex items-center rounded-r-md px-2 py-2 h-8 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
          >
            <span className="sr-only">Próxima</span>
            <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
          </button>
        </div>
        <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
          <div>
            <p className="text-gray-700">
              <span className="font-medium">{(page - 1) * pageSize + 1}</span>{' '}
              até{' '}
              <span className="font-medium">
                {totalResults >= pageSize
                  ? page === pageCount
                    ? totalResults
                    : page * pageSize
                  : totalResults}
              </span>{' '}
              de <span className="font-medium">{totalResults}</span> resultados
            </p>
          </div>
          {pageCount && pageCount > 1 && (
            <div>
              <nav
                className="isolate text-smp inline-flex -space-x-px rounded-none shadow-sm"
                aria-label="Pagination"
              >
                <button
                  disabled={page === 1 || loading === true}
                  onClick={() => handleFirstPage()}
                  className="relative inline-flex items-center h-8 rounded-l-md px-2 py-0 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                >
                  <span className="sr-only">Primeira</span>
                  <ChevronDoubleLeftIcon
                    className="h-3 w-4"
                    aria-hidden="true"
                  />
                </button>
                <button
                  disabled={page === 1 || loading === true}
                  onClick={() => handleChevronLeftIcon()}
                  className="relative inline-flex items-center px-2 py-2 h-8 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                >
                  <span className="sr-only">Anterior</span>
                  <ChevronLeftIcon className="h-3 w-4" aria-hidden="true" />
                </button>
                {objPagination?.arrayPages &&
                  objPagination?.arrayPages.length !== 0 &&
                  objPagination?.arrayPages.map((arrayPagesPage) => (
                    <div key={arrayPagesPage}>
                      {!loading && page === arrayPagesPage ? (
                        <button
                          aria-current="page"
                          disabled={true}
                          className="relative z-10 inline-flex items-center bg-top-digital px-4 py-2 h-8 font-normal text-white focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                          {arrayPagesPage}
                        </button>
                      ) : (
                        <button
                          onClick={() => handleClickPage(arrayPagesPage)}
                          className="relative inline-flex items-center px-4 py-2  font-normal top-digital-content-color ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                        >
                          {arrayPagesPage}
                        </button>
                      )}
                    </div>
                  ))}
                {objPagination?.arrayLeftPages &&
                  objPagination.arrayRightPages &&
                  objPagination?.arrayLeftPages.length !== 0 &&
                  objPagination.arrayRightPages.length !== 0 && (
                    <>
                      {objPagination.arrayLeftPages.map(
                        (arrayLeftPagesPage) => (
                          <div key={arrayLeftPagesPage}>
                            {!loading && page === arrayLeftPagesPage ? (
                              <button
                                aria-current="page"
                                disabled={true}
                                className="relative z-10 inline-flex items-center bg-top-digital px-4 py-2 font-normal text-white focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                              >
                                {arrayLeftPagesPage}
                              </button>
                            ) : (
                              <button
                                onClick={() =>
                                  handleClickPage(arrayLeftPagesPage)
                                }
                                className="relative inline-flex items-center px-4 py-2 font-normal top-digital-content-color ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                              >
                                {arrayLeftPagesPage}
                              </button>
                            )}
                          </div>
                        ),
                      )}
                      <span className="relative inline-flex items-center text-smp px-4 py-2 h-8 font-normal text-gray-700 ring-1 ring-inset ring-gray-300 focus:outline-offset-0">
                        ...
                      </span>
                      {objPagination.arrayRightPages.map(
                        (arrayRightPagesPage) => (
                          <div key={arrayRightPagesPage}>
                            {!loading && page === arrayRightPagesPage ? (
                              <button
                                aria-current="page"
                                disabled={true}
                                className="relative z-10 inline-flex items-center bg-top-digital h-8 px-4 py-2 font-normal text-white focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                              >
                                {arrayRightPagesPage}
                              </button>
                            ) : (
                              <button
                                onClick={() =>
                                  handleClickPage(arrayRightPagesPage)
                                }
                                className="relative inline-flex items-center px-4 py-2 h-8 font-normal top-digital-content-color ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                              >
                                {arrayRightPagesPage}
                              </button>
                            )}
                          </div>
                        ),
                      )}
                    </>
                  )}

                <button
                  onClick={() => handleChevronRightIcon()}
                  disabled={page === pageCount || loading === true}
                  className="relative inline-flex items-center rounded-r-md px-2 h-8 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                >
                  <span className="sr-only">Próxima</span>
                  <ChevronRightIcon className="h-3 w-4" aria-hidden="true" />
                </button>
              </nav>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Pagination;
