import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from 'react';
import {
  GoogleMap,
  InfoWindow,
  MarkerF,
  useJsApiLoader,
  KmlLayer,
} from '@react-google-maps/api';
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from 'use-places-autocomplete';
import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from '@reach/combobox';
import '@reach/combobox/styles.css';
import { isEqual } from 'lodash';
import { urlapp } from '../../utils/config';
import {
  Network,
  TBounds,
  TCoordInfo,
  TCoordinates,
  TLocation,
} from '../../Interfaces/ILocation';
import InfoWindowStations from '../../components/Map/InfoWindowStations';
import { IProject } from '../../Interfaces/IProject';
import { useGlobalContext } from '../../hooks/useGlobalContext';
import Enabled from '../../components/Utils/Enabled';
import NetworkComponent from '../../components/Map/Network';

// Hooks Redux
import useAppSelector from '../../hooks/useAppSelector';
import useAppDispatch from '../../hooks/useAppDispatch';
import {
  userPreferenceSliceSelector,
  getUserPreferences,
  setUserPreference,
} from '../../slices/UserPreferenceSlice';
import {
  projectSelector,
  clearSelectedProjectId,
} from '../../slices/ProjectSlice'; // Importação adicionada

const googleMapsApiKey: string =
  String(process.env.REACT_APP_GOOGLE_API_KEY) || '';

type Props = {
  locations: TLocation[];
  networks: Network[];
};

const Map = ({ locations, networks }: Props) => {
  const map = useRef<any>(null);
  const [mapon, setMapon] = useState(true);
  const [isMouseInside, setIsMouseInside] = useState(false);
  const [showPoints, setShowPoints] = useState(true);
  const [mousePosition, setMousePosition] = useState<{
    lat: number | null;
    lng: number | null;
  }>({
    lat: null,
    lng: null,
  });
  const dispatch = useAppDispatch();
  const { userPreference } = useAppSelector(userPreferenceSliceSelector);

  // Selecionar projetos do Redux
  const { projects, selectedProjectId } = useAppSelector(projectSelector);

  // Filtrar projetos com arquivos KMZ e isVisible=true
  const kmzProjects = projects.filter(
    (project) => project.file.endsWith('.kmz') && project.isVisible,
  );

  // Filtragem adicional baseada no selectedProjectId
  const displayedProjects = selectedProjectId
    ? projects.filter((project) => project.id === selectedProjectId)
    : projects;

  const displayedKmzProjects = selectedProjectId
    ? kmzProjects.filter((project) => project.id === selectedProjectId)
    : kmzProjects;

  const setMapTypeToRoadmap = () => {
    setMapon(true);
    if (map.current) {
      map.current.setMapTypeId(google.maps.MapTypeId.ROADMAP);
    }
  };

  const setMapTypeToSatellite = () => {
    setMapon(false);
    if (map.current) {
      map.current.setMapTypeId(google.maps.MapTypeId.HYBRID);
    }
  };

  const togglePoints = () => {
    const data: { field: string; value: boolean } = {
      field: 'showLinkPoints',
      value: !userPreference.showLinkPoints,
    };

    dispatch(setUserPreference(data));
  };

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: googleMapsApiKey,
    libraries: useMemo(() => ['places'], []),
  });

  const { globalState, dispatchGlobalState } = useGlobalContext();

  const [iniDrag, setIniDrag] = useState<TBounds | null>(null);
  const [zoom, setZoom] = useState(0);

  const onLoad = useCallback(
    (mapInstance: any) => {
      const storedCoords = localStorage.getItem('coords');
      const bounds = new google.maps.LatLngBounds();
      const coords: TBounds | null = storedCoords
        ? JSON.parse(storedCoords)
        : null;

      if (coords !== null) {
        bounds.extend(
          new google.maps.LatLng(coords.Latitude.North, coords.Longitude.East),
        );
        bounds.extend(
          new google.maps.LatLng(coords.Latitude.South, coords.Longitude.West),
        );
        mapInstance.fitBounds(bounds, 0);
      } else {
        // Use 'displayedProjects' ao invés de 'locations' para garantir que apenas os projetos selecionados sejam usados
        displayedProjects.forEach((project) => {
          bounds.extend(
            new google.maps.LatLng(project.Latitude, project.Longitude),
          );
        });
        if (
          globalState.filters.coordsFilters &&
          globalState.filters.coordsFilters.Latitude !== '' &&
          globalState.filters.coordsFilters.Longitude !== ''
        ) {
          bounds.extend(
            new google.maps.LatLng(
              globalState.filters.coordsFilters.Latitude,
              globalState.filters.coordsFilters.Longitude,
            ),
          );
        }
        mapInstance.fitBounds(bounds, 0);
      }
      map.current = mapInstance;
    },
    [displayedProjects, globalState.filters.coordsFilters],
  );

  const coordInfo = (): TCoordInfo => {
    const neNorth = map.current.getBounds().getNorthEast().lat();
    const neEast = map.current.getBounds().getNorthEast().lng();
    const swSouth = map.current.getBounds().getSouthWest().lat();
    const swWest = map.current.getBounds().getSouthWest().lng();
    const storedCoords = localStorage.getItem('coords') || '';
    const last: TBounds | null = storedCoords ? JSON.parse(storedCoords) : null;

    const current: TBounds = {
      Latitude: {
        North: neNorth,
        South: swSouth,
      },
      Longitude: {
        West: swWest,
        East: neEast,
      },
    };

    const CheckDragged: boolean =
      JSON.stringify(current) !== JSON.stringify(iniDrag) && iniDrag !== null;
    const change: boolean =
      storedCoords !== JSON.stringify(current) || CheckDragged;

    if (change) {
      localStorage.setItem('lsStationPage', '1');
      dispatchGlobalState({ type: 'SET_STATION_PAGE', stationPage: 1 });
    }

    return {
      last,
      current,
      isChanged: change,
    };
  };

  const getStationsVisible = () => {
    dispatchGlobalState({ type: 'LOADING_STATION_LIST_START' });
    const info = coordInfo();

    if (info.current) {
      localStorage.setItem('coords', JSON.stringify(info.current));
    }

    const visible: TCoordinates[] = [];
    for (const station of locations) {
      const latitude = station.Latitude;
      const longitude = station.Longitude;
      if (
        latitude > info.current?.Latitude.South &&
        latitude < info.current?.Latitude.North &&
        longitude > info.current?.Longitude.West &&
        longitude < info.current?.Longitude.East
      ) {
        visible.push({
          Latitude: station.Latitude,
          Longitude: station.Longitude,
          CodTiposClasseEstacao: station.CodTiposClasseEstacao,
        });
      }
    }

    if (
      globalState.latInfoWindow &&
      globalState.lngInfoWindow &&
      !isVisible(globalState.latInfoWindow, globalState.lngInfoWindow)
    ) {
      dispatchGlobalState({
        type: 'SET_INFOWINDOW_COORD',
        lat: null,
        lng: null,
        classesEstacao: [],
        idStation: '',
        stationPage: 1,
      });
    }

    if (
      !isEqual(globalState.visibleLocations, visible) ||
      visible.length === 0
    ) {
      dispatchGlobalState({
        type: 'SET_VISIBLE_LOCATIONS',
        visibleLocations: visible,
      });
    }

    const newZoom = map.current?.getZoom() || 0;
    setZoom(newZoom);
  };

  const setMapCoordenates = () => {
    const info = coordInfo();
    if (info.current) {
      localStorage.setItem('coords', JSON.stringify(info.current));
    }
  };

  const setDragStart = (): void => {
    if (!map.current) return;
    const ne = map.current.getBounds()?.getNorthEast();
    const sw = map.current.getBounds()?.getSouthWest();

    if (!ne || !sw) return;

    const ini: TBounds = {
      Latitude: {
        North: ne.lat(),
        South: sw.lat(),
      },
      Longitude: {
        West: sw.lng(),
        East: ne.lng(),
      },
    };
    setIniDrag(ini);
  };

  const handleProjectInfoWindow = (project: IProject) => {
    if (
      project.Latitude === globalState.latInfoWindow &&
      project.Longitude === globalState.lngInfoWindow
    ) {
      return;
    }
    dispatchGlobalState({
      type: 'SET_INFOWINDOW_COORD',
      lat: project.Latitude,
      lng: project.Longitude,
      // Adicione outras propriedades se necessário
    });
  };

  const handleInfoWindow = (station: TLocation) => {
    if (
      station.Latitude === globalState.latInfoWindow &&
      station.Longitude === globalState.lngInfoWindow &&
      station.CodTiposClasseEstacao === globalState.classeEstacaoInfoWindow
    ) {
      return;
    }
    dispatchGlobalState({
      type: 'SET_INFOWINDOW_COORD',
      lat: station.Latitude,
      lng: station.Longitude,
      classesEstacao: station.CodTiposClasseEstacao,
    });
    dispatchGlobalState({
      type: 'SET_STATION_ID',
      idStation: station.stations[0]._id,
    });
  };

  function isVisible(lat: number, lng: number) {
    for (let i = 0; i < globalState.visibleLocations.length; i++) {
      if (
        globalState.visibleLocations[i].Latitude === lat &&
        globalState.visibleLocations[i].Longitude === lng
      ) {
        return true;
      }
    }
    return false;
  }

  const mapStyles = {
    styles: [
      {
        featureType: 'administrative.locality',
        elementType: 'labels.text',
        stylers: [
          { weight: '0.4' },
          { visibility: 'on' },
          { color: mapon ? '#154449' : '#ffffff' },
        ],
      },
      {
        featureType: 'transit.line',
        elementType: 'geometry.fill',
        stylers: [{ visibility: 'on' }, { color: '#3B6A70' }, { weight: 4 }],
      },
    ],
  };

  const getIcon = (eq: TLocation | IProject) => {
    if ('CodTiposClasseEstacao' in eq && eq.CodTiposClasseEstacao) {
      // Supondo que TLocation tenha 'CodTiposClasseEstacao'
      const fillColor = mapon ? '' : '-custom';
      let iconPath = `radio-tower${fillColor}.svg`;

      if (eq.CodTiposClasseEstacao.includes('ML')) {
        return {
          url: `${urlapp}/img/car${fillColor}.svg`,
          anchor: new window.google.maps.Point(-12, 0),
        };
      } else if (eq.CodTiposClasseEstacao.includes('RR')) {
        return {
          url: `${urlapp}/img/radio-tower-blue.svg`,
          anchor: new window.google.maps.Point(12, 0),
        };
      } else {
        const towerStatus = (eq as any).color; // Ajuste conforme a propriedade real
        if (towerStatus === 'red') {
          iconPath = 'radio-tower-red.svg';
        } else if (towerStatus === 'orange') {
          iconPath = 'radio-tower-orange.svg';
        } else if (towerStatus === 'green') {
          iconPath = 'radio-tower-green.svg';
        }

        return {
          url: `${urlapp}/img/${iconPath}`,
          anchor: new window.google.maps.Point(12, 0),
        };
      }
    } else {
      // Para IProject, defina um ícone diferente ou reutilize o mesmo
      return {
        url: `${urlapp}/img/project-marker.svg`, // Defina um ícone adequado para projetos
        anchor: new window.google.maps.Point(12, 0),
      };
    }
  };

  const handleMouseMove = useCallback(
    async (e: google.maps.MapMouseEvent) => {
      if (e.latLng) {
        const latLng = e.latLng;
        if (isMouseInside) {
          setMousePosition({
            lat: latLng.lat(),
            lng: latLng.lng(),
          });
        }
      }
    },
    [isMouseInside],
  );

  const handleMouseOut = () => {
    setIsMouseInside(false);
    setMousePosition({
      lat: null,
      lng: null,
    });
  };

  const handleMouseOver = () => {
    setIsMouseInside(true);
  };

  useEffect(() => {
    dispatch(getUserPreferences());
  }, [dispatch]);

  useEffect(() => {
    if (userPreference.showLinkPoints !== undefined) {
      setShowPoints(userPreference.showLinkPoints);
    } else {
      setShowPoints(true);
    }
  }, [userPreference]);

  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      location: isLoaded
        ? new google.maps.LatLng(-14.235, -51.9253)
        : undefined, // Coordenadas aproximadas do centro do Brasil
      radius: 200 * 1000, // Definindo o raio de pesquisa em km
      language: 'pt-BR', // Idioma configurado para português
      componentRestrictions: { country: 'BR' }, // Restringindo a pesquisa ao Brasil
    },
  });

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  const handleSelect = async (address: string) => {
    setValue(address, false);
    clearSuggestions();

    try {
      const results = await getGeocode({ address });
      const { lat, lng } = await getLatLng(results[0]);

      if (map.current) {
        const bounds = new google.maps.LatLngBounds();
        bounds.extend({ lat, lng });
        map.current.fitBounds(bounds);

        google.maps.event.addListenerOnce(map.current, 'bounds_changed', () => {
          if (map.current.getZoom() > 12) {
            map.current.setZoom(12);
          }
        });

        map.current.panTo({ lat, lng });
      }
    } catch (error) {
      console.log('Erro ao buscar localização: ', error);
    }
  };

  // Centralizar no projeto selecionado via Redux
  useEffect(() => {
    if (selectedProjectId) {
      const project = projects.find((p) => p.id === selectedProjectId);
      if (project && map.current) {
        const latLng = new google.maps.LatLng(
          project.Latitude,
          project.Longitude,
        );
        map.current.panTo(latLng);
        map.current.setZoom(15);

        // Abrir InfoWindow para o projeto selecionado
        dispatchGlobalState({
          type: 'SET_INFOWINDOW_COORD',
          lat: project.Latitude,
          lng: project.Longitude,
          // Adicione outras propriedades se necessário
        });
      }

      // Limpar selectedProjectId após centralizar
      dispatch(clearSelectedProjectId());
    }
  }, [selectedProjectId, projects, dispatch, dispatchGlobalState]);

  return (
    <>
      <div className="mb-1 flex justify-between items-end">
        <div className="space-x-2 flex items-end">
          <button
            onClick={setMapTypeToRoadmap}
            className={`w-20 h-8 ${
              mapon && mapon === true
                ? 'agiliza-button-selected-2'
                : 'agiliza-button-unselected-2 '
            }`}
          >
            Mapa
          </button>
          <button
            onClick={setMapTypeToSatellite}
            className={`w-20 h-8 ${
              mapon === false
                ? 'agiliza-button-selected-2'
                : 'agiliza-button-unselected-2'
            }`}
          >
            Satélite
          </button>
          <div className="flex items-end relative">
            <div className="flex items-center absolute left-1 border-b-1 gap-1">
              <span className="text-smp whitespace-nowrap">Exibir Links</span>
              <div onClick={togglePoints}>
                <Enabled enabled={userPreference.showLinkPoints} />
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-center items-end gap-5">
          <div className="search-bar bg-white">
            {isLoaded && (
              <Combobox onSelect={handleSelect}>
                <ComboboxInput
                  value={value}
                  onChange={handleInput}
                  disabled={!ready}
                  placeholder="Pesquisar local"
                  className="customInputText w-60 h-8"
                />
                <ComboboxPopover>
                  {status === 'OK' && (
                    <ComboboxList>
                      {data.map(({ place_id, description }) => (
                        <ComboboxOption key={place_id} value={description} />
                      ))}
                    </ComboboxList>
                  )}
                </ComboboxPopover>
              </Combobox>
            )}
          </div>

          <div className="bg-white z-0 flex-col gap-1 justify-end">
            {isMouseInside && mousePosition.lat && mousePosition.lng ? (
              <div className="">
                <div className="text-smp w-32 h-5 text-left">
                  <span>Latitude:</span> {mousePosition.lat.toFixed(4) || 'N/A'}
                </div>
                <div className="text-smp w-32 h-5 text-left">
                  <span>Longitude:</span>{' '}
                  {mousePosition.lng.toFixed(4) || 'N/A'}
                </div>
              </div>
            ) : (
              <div className="">
                <div className="text-smp w-32 h-5 text-left">
                  <span>Latitude:</span> {0.0 || 'N/A'}
                </div>
                <div className="text-smp w-32 h-5 text-left">
                  <span>Longitude:</span> {0.0 || 'N/A'}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="h-[52vh] w-full" id="map_principal">
        {!isLoaded ? (
          <p>Carregando...</p>
        ) : (
          <GoogleMap
            ref={map}
            mapContainerClassName="h-[52vh] w-full"
            onLoad={onLoad}
            onDragEnd={getStationsVisible}
            onDragStart={setDragStart}
            onBoundsChanged={setMapCoordenates}
            onZoomChanged={getStationsVisible}
            onMouseMove={handleMouseMove}
            onMouseOut={handleMouseOut}
            onMouseOver={handleMouseOver}
            options={{ ...mapStyles, minZoom: 4, mapTypeControl: false }}
          >
            {/* Inserção das Camadas KMZ para 'displayedKmzProjects' */}
            {displayedKmzProjects.map((project) => (
              <KmlLayer
                key={project.id}
                url={`https://storage.googleapis.com/agiliza_projects_file/${project.file}`}
                options={{
                  preserveViewport: true, // Mantém o zoom e o centro atuais do mapa ao carregar o KML
                  suppressInfoWindows: false, // Exibe as infowindows definidas no KML
                }}
                onLoad={(layer) => {
                  // Remover o console.log
                  layer.addListener('status_changed', () => {
                    const status = layer.getStatus();
                    if (status !== google.maps.KmlLayerStatus.OK) {
                      console.error(
                        `Erro ao carregar KMZ: https://storage.googleapis.com/agiliza_projects_file/${project.file}`,
                      );
                    }
                  });
                }}
              />
            ))}

            {/* Renderizar Marcadores para 'displayedProjects' */}
            {displayedProjects.map((project) => (
              <MarkerF
                key={project.id}
                position={{
                  lat: project.Latitude,
                  lng: project.Longitude,
                }}
                icon={{
                  url: `${urlapp}/img/project-marker.svg`, // Defina um ícone adequado para projetos
                  anchor: new window.google.maps.Point(12, 0),
                }}
                onClick={() => handleProjectInfoWindow(project)}
              >
                {globalState.latInfoWindow === project.Latitude &&
                  globalState.lngInfoWindow === project.Longitude && (
                    <InfoWindow
                      options={{ disableAutoPan: true }}
                      position={{
                        lat: project.Latitude,
                        lng: project.Longitude,
                      }}
                      onCloseClick={() => {
                        dispatchGlobalState({
                          type: 'CLEAN_INFOWINDOW_COORD',
                        });
                      }}
                    >
                      <div>{project.name}</div>{' '}
                      {/* Substitua por um componente mais detalhado se desejar */}
                    </InfoWindow>
                  )}
              </MarkerF>
            ))}

            {/* Renderizar Marcadores para 'locations' */}
            {locations &&
              locations.map((eq) => (
                <React.Fragment key={`${eq.Latitude}${eq.Longitude}-black`}>
                  {mapon ? (
                    <MarkerF
                      position={{
                        lat: eq.Latitude + 0.0,
                        lng: eq.Longitude,
                      }}
                      icon={getIcon(eq)}
                      onClick={() => handleInfoWindow(eq)}
                    >
                      {(globalState.latInfoWindow === eq.Latitude &&
                        globalState.lngInfoWindow === eq.Longitude) ||
                      (zoom > 30 && isVisible(eq.Latitude, eq.Longitude)) ? (
                        <InfoWindow
                          options={{ disableAutoPan: true }}
                          position={{ lat: eq.Latitude, lng: eq.Longitude }}
                          onCloseClick={() => {
                            dispatchGlobalState({
                              type: 'CLEAN_INFOWINDOW_COORD',
                            });
                          }}
                        >
                          <InfoWindowStations location={eq} />
                        </InfoWindow>
                      ) : null}
                    </MarkerF>
                  ) : (
                    <MarkerF
                      key={`${eq.Latitude}${eq.Longitude}-white`}
                      position={{
                        lat: eq.Latitude + 0.0,
                        lng: eq.Longitude,
                      }}
                      icon={getIcon(eq)}
                      onClick={() => handleInfoWindow(eq)}
                    >
                      {(globalState.latInfoWindow === eq.Latitude &&
                        globalState.lngInfoWindow === eq.Longitude) ||
                      (zoom > 30 && isVisible(eq.Latitude, eq.Longitude)) ? (
                        <InfoWindow
                          options={{ disableAutoPan: true }}
                          position={{ lat: eq.Latitude, lng: eq.Longitude }}
                          onCloseClick={() => {
                            dispatchGlobalState({
                              type: 'CLEAN_INFOWINDOW_COORD',
                            });
                          }}
                        >
                          <InfoWindowStations location={eq} />
                        </InfoWindow>
                      ) : null}
                    </MarkerF>
                  )}
                </React.Fragment>
              ))}

            {/* Marcador para Filtro de Coordenadas */}
            {globalState.filters.coordsFilters &&
              globalState.filters.coordsFilters.Latitude !== '' &&
              globalState.filters.coordsFilters.Longitude !== '' && (
                <MarkerF
                  position={{
                    lat: globalState.filters.coordsFilters.Latitude,
                    lng: globalState.filters.coordsFilters.Longitude,
                  }}
                  icon={{
                    url: `${urlapp}/img/pin.svg`,
                    fillColor: 'red',
                  }}
                >
                  <InfoWindow
                    options={{
                      disableAutoPan: true,
                      pixelOffset: new window.google.maps.Size(0, -20),
                    }}
                    position={{
                      lat: globalState.filters.coordsFilters.Latitude,
                      lng: globalState.filters.coordsFilters.Longitude,
                    }}
                  >
                    <div>Aqui está seu filtro</div>
                  </InfoWindow>
                </MarkerF>
              )}
            {showPoints && (
              <NetworkComponent networks={networks} map={map.current} />
            )}
          </GoogleMap>
        )}
      </div>
    </>
  );
};

export default Map;
