type Props = {
  protocolProcessId: any;
  urlProcess: any;
};

const LicencindURL = ({ protocolProcessId, urlProcess }: Props) => {
  return (
    <a
      href={urlProcess}
      className="cursor-pointer font-semibold"
      target="_blank"
      rel="noopener noreferrer"
    >
      {protocolProcessId}
    </a>
  );
};

export default LicencindURL;
