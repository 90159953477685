import { useEffect } from 'react';

//context
import { useGlobalContext } from '../../../hooks/useGlobalContext';

//redux
import useAppDispatch from '../../../hooks/useAppDispatch';
import useAppSelector from '../../../hooks/useAppSelector';
import {
  reset,
  getStationDocs,
  documentSelector,
} from '../../../slices/DocumentSlice';
import { linkSystemDocSelector } from '../../../slices/LinkSystemDocSlice';
import { systemSelector, getSystemById } from '../../../slices/SystemSlice';
import { getStationById } from '../../../slices/StationSlice';
import {
  docTypeSelector,
  getDocTypes,
} from '../../../slices/DocumentTypeSlice';

//components

import DocumentListReportDocs from './DocumentListReportDocs';
import { useAuth } from '../../../hooks/useAuth';

import { roleRegister } from '../../../Interfaces/IRegisterStates';
import StationDetails from '../../Station/StationDetails';
import SystemDetails from '../../../pages/System/SystemDetails';
import isValidObjectId from '../../../utils/isValidObjectId';
import DocumentUpload from '../DocumentUpload';
import DocumentDetail from '../DocumentDetail';

type Props = {
  stationId: string;
  systemId?: string;
  docTypeId?: string;
  rowId?: string;
  hasExpirationDate?: boolean;
};

const DocumentsReportDocs = ({
  stationId,
  systemId,
  docTypeId,
  rowId,
  hasExpirationDate,
}: Props) => {
  const dispatch = useAppDispatch();

  const { loading: loadingSystem } = useAppSelector(systemSelector);
  const { loading: loadingDocTypes } = useAppSelector(docTypeSelector);
  const {
    documents,
    insertSuccess,
    deleteSuccess,
    loading: loadingDocument,
  } = useAppSelector(documentSelector);
  const { successLinkSystem } = useAppSelector(linkSystemDocSelector);

  //context
  const { globalState, dispatchGlobalState } = useGlobalContext();
  const { role } = useAuth().userData;

  /*Inicio Formulário de Documentos*/

  useEffect(() => {
    dispatch(getStationById(stationId));
  }, [dispatch, stationId]);

  useEffect(() => {
    if (deleteSuccess) {
      // Disparando o reset do slice de documentos após 3 segundos
      const timer = setTimeout(() => {
        dispatch(reset());
      }, 3000);

      // Limpando o timer se o componente for desmontado antes de 3 segundos
      return () => clearTimeout(timer);
    }
  }, [deleteSuccess, dispatch]);

  useEffect(() => {
    if (insertSuccess) {
      dispatchGlobalState({ type: 'CLOSE_UPLOAD_DOC_FORM' });
      // Disparando o reset do slice de documentos após 3 segundos
      const timer = setTimeout(() => {
        dispatch(reset());
      }, 3000);

      // Limpando o timer se o componente for desmontado antes de 3 segundos
      return () => clearTimeout(timer);
    }
  }, [insertSuccess, dispatch, dispatchGlobalState]);

  useEffect(() => {
    const objStation = {
      stationId,
      systemId,
      docTypeId,
      uploadDocumentModal: true,
      lastNameVisible: roleRegister.lastname.isVisible,
    };
    dispatch(getStationDocs(objStation));
  }, [
    stationId,
    systemId,
    docTypeId,
    dispatch,
    insertSuccess,
    deleteSuccess,
    successLinkSystem,
  ]);

  useEffect(() => {
    if (systemId !== '') {
      const obj = {
        systemId,
      };
      dispatch(getSystemById(obj));
    }
  }, [dispatch, systemId, successLinkSystem]);
  //Obtendo os tipos de documentos da estação
  //A ideoa é que venha apenas um tipo de documento

  //Vou bobter os tipod de documentos da estação aqui
  //pois em statio pae isso é chamado na abertura do componente
  useEffect(() => {
    const objStation = {
      docTypeId,
      uploadDocumentModal: true,
    };
    dispatch(getDocTypes(objStation));
  }, [dispatch, docTypeId]);

  /**
   * fim Effects que vem de ReportDocuments
   */

  return (
    <>
      <div className="absolute top-14 right-5 bottom-5 left-5 bg-white h-4/5">
        {deleteSuccess && (
          <div className="text-blue-600 text-smp">
            Documento removido com sucesso
          </div>
        )}
        {insertSuccess && (
          <div className="text-blue-600 text-smp">
            Documento inserido com sucesso
          </div>
        )}
        <div className="grid grid-cols-1 gap-2 lg:grid-cols-2 w-full h-full">
          <div className="border border-top-digital-primary-light rounded-lg overflow-auto">
            <div className="m-1 p-1">
              <div className="whitespace-nowrap font-top-digital-title font-normal text-smp">
                <StationDetails stationId={stationId} />
                {systemId && isValidObjectId(systemId) && (
                  <div className="mt-4 pt-4 border-t border-top-digital-primary-light">
                    <SystemDetails systemId={systemId} reportDocuments={true} />
                  </div>
                )}
              </div>
            </div>
          </div>

          {!globalState.openedUploadForm ? (
            <div className="border border-top-digital-primary-light rounded-lg overflow-auto">
              <div className="m-1 p-1">
                {loadingDocument || loadingDocTypes || loadingSystem ? (
                  <h2 className="md:text-base text-smp text-top-digital-content-color font-semibold font-top-digital-title mb-2">
                    Documentos. Aguarde...
                  </h2>
                ) : (
                  <div className="flex items-center justify-between w-100 md:text-base text-smp text-top-digital-content-color font-semibold font-top-digital-title mb-2">
                    <h1>Documentos</h1>
                    <button
                      className={`mb-2 mt-1 customButton ${role !== 'visualizador' ? '' : 'hidden'}`}
                      hidden={documents && documents.length > 0}
                      onClick={() => {
                        dispatchGlobalState({
                          type: 'OPEN_FORM',
                          currentForm: 'openedUploadForm',
                        });
                      }}
                    >
                      Cadastrar{' '}
                      <span className="hidden md:inline">Documento</span>
                    </button>
                  </div>
                )}

                {documents && documents.length === 0 ? (
                  <>
                    <p className="text-top-digital-content-color text-sm">
                      Você ainda não possui documentos cadastrados para estação
                      e seus respectivos sistemas.
                    </p>
                  </>
                ) : documents && documents.length === 1 ? (
                  <DocumentDetail
                    document={documents[0]}
                    systemId={systemId}
                    rowId={rowId}
                  />
                ) : (
                  <DocumentListReportDocs
                    key={`list-${rowId ? rowId : stationId}`}
                    systemId={systemId}
                    stationId={stationId}
                    docTypeId={docTypeId}
                    rowId={rowId}
                  />
                )}
              </div>
            </div>
          ) : (
            <DocumentUpload
              key={`upload-${rowId ? rowId : stationId}`}
              stationId={stationId}
              systemId={systemId}
              docTypeId={docTypeId}
              rowId={rowId}
              uploadDocumentModal={true}
              hasExpirationDate={hasExpirationDate}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default DocumentsReportDocs;
