const RecoverySucess = () => {
  return (
    <div className="flex justify-center items-start h-screen mt-10">
      <div className="w-4/5">
        <h1 className="text-center text-lg font-semibold mb-4">
          Recuperação de Senha
        </h1>
        <h2 className="text-center text-md mb-4 font-semibold">
          Senha Alterada com Sucesso!
        </h2>
        <p className="text-sm text-justify">
          <br />
          Sua senha foi alterada com sucesso! Você está sendo direcionado para a
          página de login. Caso o redirecionamento não ocorra automaticamente,
          por favor, clique{' '}
          <a
            href="/login"
            className="font-semibold hover:text-top-digital-link-hover hover:underline"
          >
            aqui
          </a>{' '}
          para acessar a página de login.
          <br />
          Se você tiver algum problema ou dúvida, entre em contato com nosso
          suporte através do email{' '}
          <label>
            <a
              href="mailto:suporte@seudominio.com.br"
              className="font-semibold hover:text-top-digital-link-hover hover:underline"
            >
              suporte@seudominio.com.br
            </a>
          </label>
          . Estamos à disposição para ajudá-lo.
        </p>
      </div>
    </div>
  );
};

export default RecoverySucess;
