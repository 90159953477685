import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunk } from '@reduxjs/toolkit';
import BoletoService from '../services/BoletoService';
import { RootState } from '../store';
import { IBoletoStates, TObjectBoleto } from '../Interfaces/IBoletoData';

const initialState: IBoletoStates = {
  boletoData: [],
  boletoError: false,
  boletoSuccess: false,
  boletoLoading: false,
  boletoMessage: '',
  boletoProcessing: false,
  downloadBoletoData: null,
  downloadBoletoError: false,
  downloadBoletoSuccess: false,
  downloadBoletoLoading: false,
  downloadBoletoMessage: '',
  lastDateCheckBoletos: '',
};

export const getAllBoletos = createAsyncThunk(
  'boleto/getAllBoletos',
  async (_, thunkAPI) => {
    const appState = thunkAPI.getState() as RootState;
    const token = appState.LoginReducer.user!;
    const res = await BoletoService.getAllBoletos(token);
    if (res.errors) {
      return thunkAPI.rejectWithValue(res.errors[0]);
    }
    return res;
  },
);

export const downloadBoleto = createAsyncThunk(
  'document/downloadBoleto',
  async (objectBoleto: TObjectBoleto, thunkAPI) => {
    const appState = thunkAPI.getState() as RootState;
    const token = appState.LoginReducer.user!;
    try {
      const res = await BoletoService.downloadBoleto(objectBoleto, token);
      if (res.errors) {
        return thunkAPI.rejectWithValue(res.errors[0]);
      }
      return res;
    } catch (error: unknown) {
      if (error instanceof Error) {
        return thunkAPI.rejectWithValue(error.message);
      }
      return thunkAPI.rejectWithValue('An unknown error occurred');
    }
  },
);

export const BoletoSlice = createSlice({
  name: 'boleto',
  initialState,
  reducers: {
    resetBoleto: (state) => {
      state.boletoError = false;
      state.boletoLoading = false;
      state.boletoSuccess = false;
      state.boletoMessage = '';
      state.boletoProcessing = false;
    },
    resetDownloadBoleto: (state) => {
      state.downloadBoletoError = false;
      state.downloadBoletoLoading = false;
      state.downloadBoletoSuccess = false;
      state.downloadBoletoMessage = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllBoletos.fulfilled, (state, action) => {
        state.boletoError = false;
        state.boletoLoading = false;
        state.boletoSuccess = true;
        state.boletoData = action.payload[0];
        state.boletoProcessing = action.payload[1];
        state.lastDateCheckBoletos = action.payload[2];
      })
      .addCase(getAllBoletos.pending, (state) => {
        state.boletoError = false;
        state.boletoLoading = true;
        state.boletoSuccess = false;
      })
      .addCase(getAllBoletos.rejected, (state, action) => {
        state.boletoError = true;
        state.boletoLoading = false;
        state.boletoSuccess = false;
        state.boletoMessage =
          typeof action.payload === 'string' ? action.payload : '';
      })
      .addCase(downloadBoleto.fulfilled, (state, action) => {
        state.downloadBoletoError = false;
        state.downloadBoletoLoading = false;
        state.downloadBoletoSuccess = true;
        state.downloadBoletoData = action.payload;
      })
      .addCase(downloadBoleto.pending, (state) => {
        state.downloadBoletoError = false;
        state.downloadBoletoLoading = true;
        state.downloadBoletoSuccess = false;
      })
      .addCase(downloadBoleto.rejected, (state, action) => {
        state.downloadBoletoError = true;
        state.downloadBoletoLoading = false;
        state.downloadBoletoSuccess = false;
        state.downloadBoletoMessage =
          typeof action.payload === 'string' ? action.payload : '';
      });
  },
});

export const { resetBoleto, resetDownloadBoleto } = BoletoSlice.actions;
export const boletoSelector = (state: RootState) => state.BoletoReducer;
export default BoletoSlice.reducer;
