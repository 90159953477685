import React from 'react';
import { FaDownload } from 'react-icons/fa';
import useAppDispatch from '../../hooks/useAppDispatch';
import { downloadFile } from '../../slices/NetworkFilesSlice';
import { NetworkFile } from '../../Interfaces/INetworkFile';

type Props = {
  doc: NetworkFile;
};

const DownloadDocument: React.FC<Props> = ({ doc }) => {
  const dispatch = useAppDispatch();

  const handleDownload = () => {
    dispatch(downloadFile(doc));
  };

  return (
    <FaDownload
      title="Download do arquivo"
      className="cursor-pointer"
      onClick={handleDownload}
    />
  );
};

export default DownloadDocument;
